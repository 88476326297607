import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { commonModule  } from 'commonModule';
import { SessionTimeoutService } from './session-timeout.service';

let http = null;
let t = null;   // pointer to the instatiated object of class Ub3httpService
let ub3consoleLog = commonModule.ub3consoleLog;

@Injectable({
    providedIn: 'root'
})
export class Ub3httpService {

    state = null;

    constructor(
                private httpc: HttpClient, 
                private cookies: CookieService,
                private sessionTimeout: SessionTimeoutService
    ){
        t = this;
        http = httpc;
        t.state = commonModule.getSharedObject('app','state');

    };
    defaults = {headers: {get: {}, put: {}, post:{}}};  // used in old angular 1 .js files

    get (path, params) { return doTheCall('GET' , path, params);};
    post(path, params) { return doTheCall('POST', path, params);};
    put (path, params) { return doTheCall('PUT' , path, params);};
}

function doTheCall(m, path, params){
    commonModule.changeCallsPending(m + ' ' + path, t.state, 1);
    var callback = null;
    var pipeParam = null;
    var errorCallback = null;
    var fin = null;
    var embedData = false;
    var csrf = null;
    var subsRetObj = null;
    var csrftoken  = (t.state.domain ==='cels') ? 'cels_csrftoken' : 'portal_csrftoken';

    if (m == 'GET' ) {
        // dont need csrftoken on GET
        let csrf = t.cookies.get(csrftoken);
        let opts = {
            headers: new HttpHeaders({'X-CSRFToken': csrf})
        };
        subsRetObj = http.get (path, params, opts).subscribe(onGood, onBad);
    }
    if (m == 'POST') {
        let csrf = t.cookies.get(csrftoken);
        let opts = {
            headers: new HttpHeaders({'X-CSRFToken': csrf})
        };
        subsRetObj = http.post(path, params, opts).subscribe(onGood, onBad);
    }
    if (m == 'PUT' ) {
        let csrf = t.cookies.get(csrftoken);
        let opts = {
            headers: new HttpHeaders({'X-CSRFToken': csrf})
        };
        subsRetObj = http.put (path, params, opts).subscribe(onGood, onBad);
    }

    function onGood(resp){
        var infoString =  m + ' ' + path + ' ' + JSON.stringify(params) ;
        t.sessionTimeout.resetTimer(path, infoString, 'API');

        var result = embedData ? {data: resp} : resp;
        if (pipeParam) pipeParam(result);
        if (callback) callback(result);
        if (fin) fin();
        commonModule.changeCallsPending('back from ' + m + ' ' + path, t.state, -1);
    }
    function onBad(error){
        var eres = {success: false, error: error};
        var result = embedData ? {data: eres} : eres;
        // with this next line,  no need to worry about .then or .catch or a 2nd parameter to subscribe
        if (pipeParam) pipeParam(result);
        if (callback) callback(result);
        if (pipeParam) pipeParam(result);
        if (fin) fin();
        commonModule.changeCallsPending('failure, back from ' + m + ' ' + path, t.state, -1);
    }

    return {
        subscribe: function(f){   // angular 2+ - uses .subscribe
            callback = f;
            return subsRetObj;
        },
        pipe: function(f){        // angular 2+ - uses .pipe optionally
            pipeParam = f;        // param for pipe is always a function
            return {
                subscribe: function(f2){
                    callback = f2;
                    return subsRetObj;
                }
            }
        },
        success: function(f){             // angular 1.5.7 uses .success - backward compatibility
            callback = f;
            return {error: function(){}} ;
        },
        then: function(f){                // angular 1.7.2 uses .then - backward compatibility
            embedData = true;
            callback = f;
            return {
                    catch: function(){
                        return {finally: setFinally};
                    },
                    finally: setFinally
            };
        }
    };

    function setFinally(f){
        fin = f;
    }
}

