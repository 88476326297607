

<div class="homepage">
    <div class="home-body">
        <div class="col-md-6 inline alcfUserSup">
            <div id="alcf-user-help" class="account" *ngIf="!user || !user.signedIn">
                <div class="account-help">
                    <div class="lgFont">New User?</div>
                    <div class="sbFont">
                        <a (click)="goto('accountRequest')" class="pointer" id="reqAcnt">
                            Request an account
                        </a> 
                        to create your username and password.</div>
                </div>
                <div class="account-help">
                    <div class="lgFont">Existing user with an active account?</div>
                    <div class="sbFont">Login with your credentials</div>
                </div>
                <div class="account-help">
                    <div class="lgFont">Previous user with an inactive account?</div>
                    <div class="sbFont">You may need to <a (click)="goto('accountReactivate')" class="pointer">reactivate your account</a></div>
                </div>
                <div class="account-help">
                    <div class="lgFont">Questions?</div>
                    <div class="sbFont">Contact <a href="mailto:{{domainGlobals.helpDeskEmail}}"
                      [textContent]="domainGlobals.helpDeskEmail"></a> M-F 9am to 5pm CT.</div>
                </div>
            </div>
            <div *ngIf="user.signedIn && user.role !== 'limited'">
                <h2 id="alcf-user-support-id">ALCF User Support</h2>
                <ul class="list-unstyled fwBold">
                    <li>ACCOUNTS RELATED HELP: <b>{{domainGlobals.helpDeskEmail}}</b></li>
                    <li>SUPPORT TICKETS: <b>{{domainGlobals.supportEmail}}</b></li>
                    <li class="list-spacing">TELEPHONE: <b>{{domainGlobals.helpDeskPhoneString}}</b></li>
                    <li>ALCF HELP DESK ADDRESS:</li>
                    <li><b>{{domainGlobals.helpDeskAddress1}}</b></li>
                    <li><b>{{domainGlobals.helpDeskAddress3}}</b></li>
                    <li><b>{{domainGlobals.helpDeskAddress4}}</b></li>
                    <li><b>{{domainGlobals.helpDeskAddress5}}</b></li>
                </ul>
            </div>
        </div>
        <div class="col-md-6 home-login inline ml20" *ngIf="!mobile">
            <div class="home-quick-links" *ngIf="user.signedIn && user.role !== 'limited'">
                <ng-container *ngFor="let m of menuItems">
                  <ul *ngIf="m && m.sub &&  m.r.indexOf(user.role) > -1" class="list-unstyled">
                    <ng-container *ngFor="let mm of m.sub">
                        <li *ngIf="mm.homepage == true && mm.r.indexOf(user.role) > -1">
                          <a    id="mm.hid"
                                (click)="onClick(mm)" 
                                style="cursor: pointer">
                            {{mm.t}}<span class="glyphicon glyphicon-chevron-right"></span>
                          </a>
                        </li>
                    </ng-container>
                  </ul>
                </ng-container>
            </div>
            <div class="home-quick-links" *ngIf="!user || !user.signedIn">
                <login></login>
            </div>
        </div>
        <div class="col-md-6 home-login inline ml20">
            <div *ngIf="user && user.signedIn && (user.role === 'limited')">
                You are in the middle of a Request.
                <br><br>
                <button (click)="continueRequest()" id="contReq" class="btn btn-success btn-md" >
                    Continue with the request
                </button>
                <br><br>
                <button (click)="cancelRequest()" id="canReq" class="btn btn-warning btn-md" >
                    Cancel the request
                </button>
            </div>
        </div>
    </div>
</div>
<div class="home-info clear">
    <div class="home-data row">
        <div class="col-md-4 inline home-cols">
            <h4>Accounts Info </h4>
            <p>To get an ALCF account, you must be a member of an active project that has been awarded an ALCF allocation through one of our allocation programs.
            </p>
            <p>If you would like to learn how to request an allocation of time on ALCF resources,
                please visit: <a href="{{AllocationRequestURL}}" target="_external_page">
                  alcf.anl.gov/support-center/get-started/request-allocation</a>
            </p>
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-4 inline">
            <h4>Opportunities</h4>
            <p>We offer a variety of videoconferences and workshops each year to provide training and information for new and existing users. For our list of training activities and events, visit: <a href="{{TrainingEventsURL}}" target="_blank">alcf.anl.gov/events</a>
            </p>
            <p>Additionally, we are always seeking talent to join our staff. For our open positions, visit: <a href="{{CareersURL}}" target="_blank">alcf.anl.gov/about/careers</a>
            </p>
        </div>

        <div class="col-md-4 inline align-top">
            <h4>ALCF News</h4>
            <div *ngFor="let item of lists.alcfNews3">
                <p>
                    <a href="{{item.link}}" target="_blank" id="alcf-news-item">
                        {{item.title}}
                    </a>
                </p>
            </div>
        </div>
        <!-- /.col-md-4 -->
    </div>
</div>
<br>

