

<input *ngIf="vhcp" type=text style="display:none" id="httpCallsPending" value="{{state.httpCallsPending}}">
<div id="systemMessage"></div>
<!-- Top Menu -->
<div id="topMenu">
  <nav class="navbar main-nav">
    <div class="main-nav-container" [ngClass]="{'topMenuMiddle':!mobile}">
      <div class="ul1">
        <ul class="navbar-nav" *ngIf="!mobile">
          <li class="nav-item main-menu"><a class="nav-link text-center" href="{{SupportCenterURL}}">
            Support Center <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item main-menu"><a class="nav-link text-center" href="{{ALCFResourcesURL}}">
            ALCF Resources</a>
          </li>
          <li class="nav-item main-menu">
            <a class="nav-link text-center" href="{{ALCFWebsiteURL}}">
            ALCF Website</a>
          </li>
        </ul>
      </div>
      <div id="nav-right" class="ul2">
        <ul *ngIf="state.user.role != 'limited'"
            class="navbar-nav">
            <li *ngIf="state.user.signedIn && state.user.firstName && state.user.lastName" 
                class="first-last-name" 
                id="nameOfUser">
                {{state.user.firstName + ' ' + state.user.lastName}}
            </li>
            <li *ngIf="state.user.signedIn"
                id="logoutButton"
                (click)="tryToSignOut()"
                class="btn btn-success btn-square login">
                LOGOUT<span class="caret"></span>
            </li>
            <li *ngIf="!state.user.signedIn && state.displayRoutedPage"
                id="loginBtn"
                (click)="initSignIn()"
                class="btn btn-success btn-square login">
                LOGIN <span class="caret"></span>
            </li>
        </ul>
        <div class="rtInline">
          <ng-container id="signOutQuestion" *ngIf="showSignOut">
              <font color="#ff0000">
                  Are you sure you want to sign out ?
              </font>
              <button
                class="btn btn-success btn-sm"
                (click)="showSignOut = false"
                id="doNotSignOut">
                No
              </button>
              <button
                class="btn btn-warning btn-sm"
                (click)="logout()"
                id="yesLogOut">
                Yes Sign Out
              </button>
          </ng-container>
        </div>
      </div>
    </div>
  </nav>
</div>
<div class="container-fluid bgColor">
  <!-- ANL Header -->
  <div class="alc-header clearFix pl300"
       *ngIf="!mobile">
    <h1>
      ALCF Account and Project Management
    </h1>
  </div>
  <!-- Nav bar -->
  <nav class="navbar app-sidebar navbar-default clearFix" [ngClass]="{ 'admin-user': currentUser.role ==='domainAdmin', 'mobile': mobile }" *ngIf="showNavMenu && menuItems">
      <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
        <div class="anlLogo" *ngIf="!adminPage">
          <img src="/frontend/assets/img/ANL-ALCF-WHITE.svg" width="225px">
        </div>
        <ul class="navbar-nav">
          <li
            *ngFor="let item of menuItems"
            class="nav-item"
            ngbDropdown
            display="static"
            placement="bottom-left">

            <a  *ngIf="item"
                class="nav-link"
                ngbDropdownToggle
                id="{{item.hid}}"
                (click)="processClick(item.f)"
                role="button"
                style="cursor: pointer; font-size: larger;">
              {{item.t}}<i class="fas fa-chevron-down"></i>
            </a>
            <div *ngIf="item && item.sub" ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
                <ng-container *ngFor="let subi of item.sub">
                    <a  *ngIf="subi"
                        id="{{subi.hid}}"
                        ngbDropdownItem
                        (click)="processClick(subi.f); isAdmPage(subi);">
                        {{subi.t}}
                    </a>
                </ng-container>
            </div>
          </li>
        </ul>
      </div>
  </nav>
  <span [hidden]="true" id="menuPaths">{{state.menuPaths}}</span>

  <home *ngIf="state.dispHome"></home>

  <!-- main app container -->
  <div [ngClass]="{'content-wrap-outlet':(!adminPage && !mobile), 'mobile': mobile}" class="clearFix">
    <div [ngClass]="{'pl300':(!adminPage && !mobile), 'pl30':adminPage}">
      <router-outlet *ngIf="state.displayRoutedPage"></router-outlet>
    </div>
  </div>
</div>

<font style="display: none; float:right;" (click)="vhcp=true" id="enableHttpCallsPending">e</font>
<font style="display: none; float:right;" id="pageName">{{pageName}}</font>

<!-- Footer -->
<footer id="footer" [ngClass]="{'mobile': mobile, 'content-wrap-outlet':!mobile}">
    <!-- <div id="smallfooter" *ngIf="!bigFooter" (mouseover)="bigFooter = true">
        <center>
            <img src="/frontend/assets/img/footer_logos.png" style="width:250px">
            <img width="100px" border=0>
            <font color="#aaaaaa">version: {{domainGlobals.version}}</font>
        </center>
    </div>
    <div *ngIf="bigFooter" (mouseleave)="bigFooter = false">-->
      <div [ngClass]="{'alcfFooter': ((!adminPage && !mobile) || state.dispHome), 'mobile': mobile, 'domFooter': (adminPage && !state.dispHome)}">
        <alcf-footer [(dmg)]="domainGlobals"></alcf-footer>
      </div>
    <!-- </div> -->
</footer>


