
// common module - contains functions that are shared by multiple modules

// ************
// ****** note,  for issue 3646.  Do not use let in this file.  Please use var instead ***********
// ************

var commonModule =
(function () {

        var publicStuff, info;
        var context = {};       // for caching object pointers
        var typingPromise = null;
        var sharedInfo = {};
        var cookies , http , httpNew , scope , timeout , win, valMod  ;      // cached services
        cookies = http = httpNew = scope = timeout = win = valMod = null;    // cached services
        var modules = null;
        var alertError = {type : 'danger'};
        var alertSuccess = {type : 'success'};

        var topState = getSharedObject('app', 'state');
        var admPages = ['userAdmin','userEdit','createOtherAccounts','createOtherAccountsForm','projectAdmin','projectEdit','aclAdmin','institutions','logsAdmin','pluginsAdmin','svcApi','identityMerge','apiDevInt'];
        var editAdmPages = ['userEdit','createOtherAccountsForm','projectEdit'];
        var editPiproxyPages =['manageProjectsEdit'];
        info = null;
        publicStuff = {
            addDisplayName            : addDisplayName,
            addSystemMessage          : addSystemMessage,
            arrayCopy                 : arrayCopy,
            arrayCopyPointers         : arrayCopyPointers,
            arrayToCommaDelimited     : arrayToCommaDelimited,
            careerLevels              : careerLevels,
            callHelpDesk              : callHelpDesk,
            clearFilterCache          : clearFilterCache,
            commaDelimitedToArray     : commaDelimitedToArray,
            compareAddresses          : compareAddresses,
            convertMenu               : convertMenu,
            convertUTCtoCDT           : convertUTCtoCDT,
            cut                       : cut,
            ctryStateEditable         : ctryStateEditable,
            changeCallsPending        : changeCallsPending,
            changePage                : changePage,
            changeOwner               : changeOwner,
            checkIfDataChanged        : checkIfDataChanged,
            deleteContext             : deleteContext,
            displayMemberStatus       : displayMemberStatus,
            doWhenAPICallsDone        : doWhenAPICallsDone,
            emptyObject               : emptyObject,
            explorerPage              : explorerPage,
            filter                    : filter,
            filterProjectName         : filterProjectName,
            findModuleByName          : findModuleByName,
            formatDeactDt             : formatDeactDt,
            formNames                 : formNames,
            formNamesCels             : formNamesCels,
            getALCFNews               : getALCFNews,
            getUsaStates              : getUsaStates,
            getCertifiedHosts         : getCertifiedHosts,
            getLocalStorage           : getLocalStorage,
            getCountries              : getCountries,
            getDebugInfo              : getDebugInfo,
            getLimitedCountries       : getLimitedCountries,
            getCountryCode            : getCountryCode,
            getInstitutionId          : getInstitutionId,
            getInstActPend            : getInstActPend,
            getScienceValues          : getScienceValues,
            getLimitedProjectsList    : getLimitedProjectsList,
            getInstitutionsList       : getInstitutionsList,
            getAuthInstitutionsList   : getAuthInstitutionsList,
            getLimitedInstitutionsList: getLimitedInstitutionsList,
            getLocaleString           : getLocaleString,
            getMenuItems              : getMenuItems,
            getProxyProjects          : getProxyProjects,
            getUserInfo               : getUserInfo,
            getUsersList              : getUsersList,
            getUsersNotSystemCreated  : getUsersNotSystemCreated,
            getSetContext             : getSetContext,
            getSharedObject           : getSharedObject,
            getUniqueEmails           : getUniqueEmails,
            getUserRole               : getUserRole,
            hasPrimary                : hasPrimary,
            init                      : init,
            isAdminPg                 : isAdminPg,
            isCollaborator            : isCollaborator,
            isEqual                   : isEqual,
            limitedLogin              : limitedLogin,
            listFilter                : listFilter,
            listSplit                 : listSplit,
            loading                   : loading,
            locateByZip               : locateByZip,
            locateByZipAup            : locateByZipAup,
            matchUsers                : matchUsers,
            matchUsersNG              : matchUsersNG,
            toggleMatchPjUsers        : toggleMatchPjUsers,
            matchPjUsers              : matchPjUsers,
            mkId                      : mkId,
            mobileTableScroll         : mobileTableScroll,
            objCopy                   : objCopy,
            objCopyGuts               : objCopyGuts,
            objCopyArray              : objCopyArray,
            onCountryChange           : onCountryChange,
            onStopCalling             : onStopCalling,
            sendForm                  : sendForm,
            publicPage                : publicPage,
            redirectForbidden         : redirectForbidden,
            removeALLsearchFilter     : removeALLsearchFilter,
            removeEmailErrClass       : removeEmailErrClass,
            removeNewDeletedObjs      : removeNewDeletedObjs,
            replaceAll                : replaceAll,
            removeDuplicates          : removeDuplicates,
            sanitizeRule1             : sanitizeRule1,
            screenSizeName            : screenSizeName,
            scrollToError             : scrollToError,
            scrollToHasError          : scrollToHasError,
            selectUsers               : selectUsers,
            setLocalStorage           : setLocalStorage,
            setDomainGlobals          : setDomainGlobals,
            setUser                   : setUser,
            signIn                    : signIn,
            signInNg                  : signInNg,
            signOut                   : signOut,
            sortArrayOnKey            : sortArrayOnKey,
            sortOnKeys                : sortOnKeys,
            strArrWDelimiters         : strArrWDelimiters,
            toCamel                   : toCamel,
            toDashCase                : toDashCase,
            toTitleCase               : toTitleCase,
            traverseMenu              : traverseMenu,
            tweakOrcid                : tweakOrcid,
            ub3consoleLog             : ub3consoleLog,
            ub3Http                   : ub3Http,
            uniqueInstitutions        : uniqueInstitutions,
            uniqueUAInstitutions      : uniqueUAInstitutions,
            unixGroupList             : unixGroupList,
            unsavedEditForm           : unsavedEditForm,
            urlSearchParamsArray      : urlSearchParamsArray,
            validateList              : validateList,
            validateListsAfterGet     : validateListsAfterGet,
            validateLL                : validateLL,
            validateLLNow             : validateLLNow,
            verifyEmailCode           : verifyEmailCode,
            verifySecurityAnswer      : verifySecurityAnswer,
            whoami                    : whoami,
        };

        // mods only gets passed from Angular 2+
        function init(c, h, hn, s, t, w, mods) {
            var ng2p = ((typeof angular) == 'undefined');
            cookies = c;
            http = h;
            httpNew = hn;
            scope = s;
            timeout = t;
            win = w;


            if (mods) {
                modules = mods;
                valMod = findModuleByName('validationModule');
            }else{
                valMod = validationModule;
            }
            //if(v) { valMod = v; }

            if (scope.cels){
                // CELS
                GC.domain = 'cels';
            }else{
                // ALCF
                GC.domain = 'alcf';
                GC.projectExplorer = false;
                GC.defaultPageOnLogin = '/' ;
            }

            if ((typeof _) == 'undefined'){
                // angular 2+
            }else{
                // angular 1
          }

            c.sname = 'cookies'  ; modules.push(c);
            h.sname = 'http'     ; modules.push(h);
            if (h === hn){
                // same object coming from angular 2+
                modules.push({sname: 'httpNew', get: hn.get, put: hn.put, post: hn.post, defaults: hn.defaults});
            }else{
                // angular 1
                hn.sname = 'httpNew'; modules.push(hn);
            }
            s.sname = 'scope'    ; modules.push(s);
            modules.push({sname: 'timeoutObj', timeout: t});
            if (!findModuleByName('dayjs')){
               dayjs.sname = 'dayjs'; modules.push(dayjs);
            }

            w.sname = 'window'   ; modules.push(w);
            GC.sname = 'GC'      ; modules.push(GC);


            for (var i in modules){
                var mod = modules[i];
                if (!mod.sname){
                    ub3consoleLog('188 Warning, module without name', mod);
                }
                // each module needs to provide a setServices function
                // parameters need to be in alphabetical order
                if (mod.setServices){
                    mod.setServices(modules);
                }
            }
        }

        function findModuleByName(modName){
            var mod, i;
            for (i in modules){
                mod = modules[i];
                if (mod.sname == modName){
                    return mod;
                }
            }
            return null;
        }

        function whoami(callback){
            http.get('/public/whoami')
                .success(function(resp){
                    if (resp){
                        resp.signedIn = true;
                        callback(resp);
                    }else{
                        callback({signedIn: false});
                    }
                })
                .error(function(data, status, headers, config) {
                    callback({signedIn: false, data:data, status: status, headers:headers, config:config});
                });
        }

        function setUser(scope, callback){
            var u={};
            if (!scope.user){
                scope.user = {};
            }
            if (!scope.flags){
                scope.flags = {};
            }
            u.role = 'public';
            u.signedIn = false ;
            http.get('/public/whoami')
                .success(function(resp){
                    if (resp){
                        u = resp;
                        u.signedIn = true ;
                        scope.flags.signInModal = false;
                        objCopyGuts(u,scope.user);

                     // SCS REMOVED 7/7
                        menuByDomain(scope);
                    } else {
                        u.signedIn = false ;
                        scope.flags.signInModal = true;
                        u.role = 'public';
                        u.firstName = 'Public';
                        u.lastName = 'User';
                        objCopyGuts(u,scope.user);
                        //scope.menuItems = defineMenu(scope, false);
                        menuByDomain(scope)

                    }
                    if (!scope.cels && (!u || !u.role || (u.role != 'limited'))){
                        // remove limited login form local storage
                        localStorage.removeItem('anl-UB3-LLF');
                    }
                    if (callback){
                        callback();
                    }
                })
                .error(function(data, status, headers, config) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.

                    //scope.menuItems = defineMenu(scope, false);
                    menuByDomain(scope);
                });

        }
        function deleteContext(pageName) {
            if(context[pageName]){
                delete context[pageName];
            }
        }
        // remove duplicate elements from an array and return unique elements
        function removeDuplicates(ar) {
            let unique = ar.reduce(function (temp, curr) {
                if (!temp.includes(curr)) {temp.push(curr);}
                return temp;
            }, []);
            return unique;
        }
        // Stringify array using delimiters
        // ex: to convert the array arr=['one', 'two', 'three'] to a string: 'one, two & three', call strArrWDelimiters(arr, ',', '&');
        function strArrWDelimiters(arr, delmtr, lastdelmtr){
            var len = arr.length;
            var ldel = ' '+lastdelmtr+' ';
            if(len === 1) return arr[0];
            if(len === 2) return (arr[0] + ldel + arr[1]);
            var str ='';
            delmtr += ' ';
            for(var i=0; i<=len-3; i++){
                str = str+arr[i]+ delmtr;
            }
            str = str + arr[len-2] + ldel + arr[len-1];
            return str;
        }

        // returns an array with no repeats, and includes the primary
        // if it is already there
        function uniqueInstitutions(ar) {
            var i, uniques, primaryFound, row;

            uniques = [];

            primaryFound = false;
            for (i = 0; i < ar.length; i++){
                row = ar[i];
                if (!exists(row.name, uniques)){
                    if(row.primaryInstitution && (!primaryFound)){
                        primaryFound = true;
                    }
                    else{
                        row.primaryInstitution = false;
                    }
                    uniques.push(ar[i]);
                }
            }
            return uniques;

            function exists(str, list){
                var i, row;
                for (i = 0; i < list.length; i++){
                    row = list[i];
                    if (row.name == str){
                        return true;
                    }
                }
                return false;
            }
        }
        // unique Update Account Institutions
        function uniqueUAInstitutions(ar) {
            var i, uniques, addIt, row, primaryFound;
            uniques = [];
            primaryFound = false;
            // now add the ones with ids or affiliation name assigned
            for (i = 0; i < ar.length; i++){
                row = ar[i];
                // only one primary should be present
                if(row.primaryInstitution && (!primaryFound)){
                    primaryFound = true;
                } else {
                    row.primaryInstitution = false;
                }
                addIt =  (row.id >= 0) || (row.affiliation);
                if (!exists(row.affiliation, uniques) && addIt){
                    uniques.push(row);
                }
            }
            // now add the Active ones with NO affiliation, to make sure the empty one is at the end
            for (i = 0; i < ar.length; i++){
                row = ar[i];
                if(!row.primaryInstitution){row.primaryInstitution = false;}
                addIt = row.status && (row.status == 'Active') && !row.affiliation;
                if (!exists(row.affiliation, uniques) && addIt){
                    uniques.push(row);
                    break;
                }
            }
            return uniques;

            function exists(str, list){
                if(!str || !list ){return}
                var hasDuplRow = list.filter(function(item){
                    return (item.affiliation && (item.affiliation.toLowerCase() === str.toLowerCase()) && (item.status !== 'Deleted'));
                }).length > 0;
                return hasDuplRow;
            }
        }

        function menuByDomain(state){
            if(!state.cels){
                state.menuItems = defineMenu(state, false);
            }
        }

        function traverseMenu(obj, processFunc){
            var i, item, item2, i2;
            for (i in obj){
                item = obj[i];
                processFunc(item);
                if (item.sub){
                    for (i2 in item.sub){
                        item2 = item.sub[i2];
                        processFunc(item2);
                    }
                }
            }
        }

        // only calls initialize function doThisOneTime() once per pageName
        // Otherwise just retrieve cached value from context.
        // Note: even though it seems like it cashes the value only once,
        //       it is the reference to those objects that gets cashed once.  The objects
        //       themselves can change dynamically (and they do) But the context storage
        //       sets back the correct pointers to those objects.
        //
        function getSetContext(scope, pageName, doThisOneTime, doThisWhenCache){

            var c;
            c = context[pageName];
            if (c){
                scope.flags       = c.flags;        // private flags for module, identified by pageName
                scope.lists       = c.lists;        // private lists for each module
                scope.form        = c.form;         // private form for module
                if (doThisWhenCache) doThisWhenCache();
            }else{
                doThisOneTime();    // asynchronous stuff inside this

                scope.flags.pageName = pageName;
                context[pageName] = {
                    form       :scope.form,
                    flags      :scope.flags,
                    lists      :scope.lists,
                };
                //ub3consoleLog('440 get-set-con after caching, all context is:', context);
            }
            checkIfSessionIsGood(scope, pageName, function(){});


            // now that the ng1 code is not here in this function, it appears that this function does nothing.
            // verify this
            //
            function checkIfSessionIsGood(scope, pageName, callback){
                //callback(); return;
                if (pageName == 'Request an Account')    {callback(); return;}
                if (pageName == 'Reactivate an Account') {callback(); return;}
                if (pageName == 'Allocation Requests')   {callback(); return;} // this is for non-signed-in users

                http.get('/public/whoami')
                    .success(function(resp){
                        if (!resp || (resp && resp.role && (resp.role == 'public'))){
                            ub3consoleLog('kick the bucket !!', {pageName:pageName, resp:resp});

                            // dont do anything here, the login component decides
                            // if it should display a login challenge
                        }
                        callback();
                    })
                    .error(function(data, status, headers, config) {
                        // cannot contact server.  let it pass. do not logout
                        callback();
                    });
            }
        }

    // Returns an empty object, if it wasn't created before.
    // Returns the same populated object, if it was created before.
    // The population is up to the individual components.
    // This function is just a venue for components to easily share data.
    //
    function getSharedObject(componentName, objectName){
        if (! sharedInfo[componentName]){
              sharedInfo[componentName] = {};
        }
        if (! sharedInfo[componentName][objectName]){
              // exception here: menuItems is not a JSON object, it is supposed to be an array
              if (objectName == 'menuItems'){
                    sharedInfo[componentName][objectName] = [];
              }else{
                    sharedInfo[componentName][objectName] = {};
              }
        }
        return sharedInfo[componentName][objectName] ;
    }


    //            - resourceOwner   // MCS specific
    // spiderman  - piproxy
    // batgirl    - piproxy
    // batman     - domainAdmin
    // user       - user
    // public     - non authenticated traffic
    // *          - All of the above
    //
    function defineMenu(scope, signedIn){
        var nameLabel, all, pub, d, pl, pd, updo, menu, ngPath;
        var pub, L, pl, pd, up, auth, mi, smi
        nameLabel = scope.user.firstName +' '+scope.user.lastName;
        all     = '*';
        pub     = ['public'];
        d       = ['domainAdmin'];
        pl      = ['public','limited'];
        pd      = ['piproxy', 'domainAdmin'];
        updo    = ['user', 'piproxy', 'domainAdmin', 'owner'];
        ngPath = "/frontend/index-ng.html?";

        menu =  [
            {label: 'Home',                          roles: all,    homepage:false, path:ngPath+'page=home'},
            {label: 'Request an Account',            roles: pl,     homepage:false, path:ngPath+'page=accountRequest'},
            {label: 'Reactivate an Account',         roles: pl,     homepage:false, path:ngPath+'page=accountReactivate'},
            {label: 'Allocation Requests',           roles: pl,     homepage:false, path:ngPath+'page=allocationRequests'},
            {label: 'ALCF Passcode Token Help',      roles: pl,                     path:'',  cryptocard:true},
            {label: 'Accounts',                      roles: updo,   homepage:false, path:ngPath,                   init:null,
            sub:[
                {label: 'Update My Account',         roles: updo,   homepage:false, path:ngPath+'page=accountUpdate'},
                ]},
            {label: 'Projects & Resources',          roles: updo,   homepage:false, path:ngPath,                   init:null,
            sub:[
                {label: "Project Management",        roles: pd,     homepage:true,  path:ngPath+'page=manageProjects'},
                {label: 'Join project',              roles: updo,   homepage:true,  path:ngPath+'page=joinProject'},
                {label: 'Request and view systems',  roles: updo,   homepage:true,  path:ngPath+'page=systemRequest'},
                {label: 'Manage UNIX Groups',        roles: updo,   homepage:true,  path:ngPath+'page=manageUnixGroups'},
                {label: 'Request an Allocation',     roles: updo,   homepage:true,  path:ngPath+'page=allocationRequests'},
                ]},
            {label: 'Administration',                roles: d,      homepage:false, path:ngPath,                   init:null,
            sub:[
                {label: 'User Administration',       roles: d,      homepage:false, path:ngPath+'page=userAdmin'},
                {label: 'Create Other Accounts',     roles: d,      homepage:false, path:ngPath+'page=createOtherAccounts'},
                {label: 'Project Administration',    roles: d,      homepage:false, path:ngPath+'page=projectAdmin'},
                {label: 'ACL Administration',        roles: d,      homepage:false, path:ngPath+'page=aclAdmin'},
                {label: "Institutions",              roles: d,      homepage:false, path:ngPath+'page=institutions'},
                {label: 'Logs',                      roles: d,      homepage:false, path:ngPath+'page=logsAdmin',init:null},
                {label: 'Plugins',                   roles: d,      homepage:false, path:ngPath+'page=pluginsAdmin',init:null},
                {label: 'Other Screens',             roles: d,      homepage:false, path:ngPath+'page=svcApi'},
                {label: "Identity Merge",            roles: d,      homepage:false, path:'#/identityMerge',     init:scope.initIdentityMerge}
                ]},
        ];

        var hn = window.location.hostname;
        var tidx = menu.length - 1; // on the top level menu, find the last index

        if ((hn == 'localhost') && (tidx >= 0)){
            // development laptop
            menu[tidx].sub.push({
                 label:'API Developer Interface',   roles: d,       homepage:false, path:ngPath+'page=apiDevInt', init:null});
            menu[tidx].sub.push({
                 label:'Validation Rules',          roles: d,       homepage:false, path:'/frontend/validationRules.html', init:null});
        }else {
            // test or production server
        }

        // for angular 1.7.2
        if (true){
          for (var i in menu){
            mi = menu[i];
            if (mi.path){
                mi.path = mi.path.replace('#', '/#!');
            }

            for (var ii in mi.sub){
                smi = mi.sub[ii];
                if (smi.path){
                    smi.path = smi.path.replace('#', '/#!');
                }
            }
          }
        }
        return menu;
    }

    function mkId(str){
        var tmp;
        tmp = replaceAll(str, '&','')
        tmp = replaceAll(tmp, ' ', '-');
        return toCamel(tmp);
    }

    function urlSearchParamsArray(){
        var url = new URL(window.location.href.replace('/#', ''));
        return new URLSearchParams(url.search);
    }

    // SCS Removed defineCelsMenu 7/18 DEFUNCT

    function formNames(){
        var f;
        f = [
            'MUAInstitutionSvc',
            'OTPTokenSvc',
            'accountAclTagSvc',
            'accountAddresses',
            'accountCloudTenantSvc',
            'accountEmails',
            'accountInfo',
            'accountInstitutions',
            'accountMailingListSvc',
            'accountNetGroupSvc',
            'accountOTPTokenSvc',
            'accountPhones',
            'accountQuestions',
            'accountReactivate',
            'accountRequest',
            'accountResources',
            'accountSshPublicKey',
            'accountSshPublicKeySvc',
            'accountSvc',
            'accountSystemSvc',
            'accountTypeSvc',
            'accountUnixgroupSvc',
            'accountUpdate',
            'aclTagSvc',
            'allocationSvc',
            'catalystSvc',
            'cloudTenantSvc',
            'countrySvc',
            'createUserSvc',
            'ddAllocationRequestSvc',
            'domainAdmin',
            'domainSvc',
            'favorSvc',
            'fieldOfStudySvc',
            'identityAddressSvc',
            'identityEmailSvc',
            'identityInstitutionSvc',
            'identityPhoneSvc',
            'identityQuestionSvc',
            'identitySvc',
            'institutionSvc',
            'institutions',
            'joinProject',
            'limitedLogin',
            'limitedLoginA',
            'limitedLoginB',
            'limitedLoginC',
            'limitedLoginD',
            'logSvc',
            'logs',
            'mailTemplateSvc',
            'mailingListSvc',
            'manageProjects',
            'manageReport',
            'manageReportGrant',
            'manageReportPublication',
            'manageReportAttachment',
            'manageUnixGroups',
            'messageFromFavor',
            'netGroupSvc',
            'netgroupSystemSvc',
            'plugin',
            'projectAccountSvc',
            'projectAclTagSvc',
            'projectAdmin',
            'projectCatalystSvc',
            'projectFieldOfStudySvc',
            'projectInstitutionSvc',
            'projectMailingListSvc',
            'projectNetgroupSvc',
            'projectProjectSvc',
            'projectRequest',
            'projectSourceOfFundingSvc',
            'projectSvc',
            'projectSystemSvc',
            'projectUnixgroupSvc',
            'reactivationRequestSvc',
            'reportSvc',
            'reportAttachmentSvc',
            'reportGrantSvc',
            'reportPublicationSvc',
            'requestSystem',
            'resourceTypeSvc',
            'saveForLaterSvc',
            'sourceOfFundingSvc',
            'sshPublicKeySvc',
            'systemAclTagSvc',
            'systemSvc',
            'unixgroupAclTagSvc',
            'unixgroupSvc',
            'userGroupSvc',
            'systemMailListSvc',
            'armProjectDetail',
            'armAllocationRequest',
            'armSaveNewRequest',
            'armUpdateRequest',
            'armChoiceModelSvc',
            'armProjectDetailSvc',
            'armAllocationRequestSvc',
            'niUserShortcutSvc',
            'niCommonShortcutSvc',
            'niRecentCommandSvc',

        ];
        return f;
    }

    function formNamesCels(){
        var f;
        f = [
            'OTPTokenSvc',
            'accountAclTagSvc',
            'accountAddresses',
            'accountCloudTenantSvc',
            'accountEmails',
            'accountInfo',
            'accountInstitutions',
            'accountMailingListSvc',
            'accountNetGroupSvc',
            'accountOTPTokenSvc',
            'accountPhones',
            'accountResources',
            'accountSshPublicKey',
            'accountSshPublicKeySvc',
            'accountSvc',
            'accountSystemSvc',
            'accountTypeSvc',
            'accountUnixgroupSvc',
            'accountUpdate',
            'aclTagSvc',
            'allocationSvc',
            'catalystSvc',
            'cloudTenantSvc',
            'countrySvc',
            'domainAdmin',
            'domainSvc',
            'favorSvc',
            'fieldOfStudySvc',
            'identityAddressSvc',
            'identityEmailSvc',
            'identityInstitutionSvc',
            'identityPhoneSvc',
            'identityQuestionSvc',
            'identitySvc',
            'institutionSvc',
            'joinProject',
            'logSvc',
            'logs',
            'mailTemplateSvc',
            'mailingListSvc',
            'manageProjects',
            'manageReport',
            'manageReportGrant',
            'manageReportPublication',
            'manageReportAttachment',
            'manageUnixGroups',
            'netGroupSvc',
            'netgroupSystemSvc',
            'plugin',
            'projectAccountSvc',
            'projectAclTagSvc',
            'projectCatalystSvc',
            'projectAdmin',
            'projectProjectSvc',
            'projectRequest',
            'projectSvc',
            'reportSvc',
            'reportAttachmentSvc',
            'reportGrantSvc',
            'reportPublicationSvc',
            'requestSystem',
            'resourceTypeSvc',
            'sshPublicKeySvc',
            'sourceOfFundingSvc',
            'systemAclTagSvc',
            'systemSvc',
            'unixgroupAclTagSvc',
            'unixgroupSvc',
            'userGroupSvc',
            'orphanedProjectSvc',
        ];
        return f;
    }

    // Contextual Messaging Info to display
    function setDomainGlobals(obj, callback){
        if (obj.domainGlobals){
            //already set, do nothing
            if (callback) callback();
            return;
        }
       // obj = http.get("/public/domainGlobals")

        http.get("/public/domainGlobals")
            .success(function(resp){
                    if(resp.success){
                        obj.domainGlobals = resp.domainGlobals;
                        if (callback){
                            callback();
                        }
                    }
            })
            .error(function(e){
                if (callback){
                    callback();
                }
            });

    }

        function commaDelimitedToArray(str) {
            if (!str || (str === '')){
                return [];
            }

            var a, i, alist, oneItem;
            a = [];
            alist = str.split(',');
            for (i in alist) {
                oneItem = alist[i].trim();
                a.push(oneItem);
            }
            return a;
        }
        function arrayToCommaDelimited(a){
            var str, i, result, oneItem;
            if ((typeof a) != 'object') {
                ub3consoleLog(837, 'arrayToCommaDelimited(): wrong parameter. Needs to be an array');
                return;
            }
            result = '' ;
            for (i in a){
                oneItem = a[i].split();
                result +=  (result == '') ? oneItem : ', ' + oneItem ;
            }
            return result;
        }

        function objCopyArray(obj){
            var i, a,v ;
            a = [];
            for (i in obj){
                v = objCopy(obj[i]);
                a.push(v);
            }
            return a;
        }

        function emptyObject(obj){
            var item;
            for (item in obj){
                delete obj[item];
            }
        }

        // returns a copy of the given object
        function objCopy(obj) {
            if (obj == null) return null;
            if (obj == undefined) return undefined;
            return JSON.parse(JSON.stringify(obj));

            var aCopy, key, val;
            aCopy = obj ? {} : null;
            for (key in obj) {
                val = obj[key];
                if (val == null){
                    aCopy[key] = null;
                }
                else if ((typeof val) == 'object') {
                    if (val instanceof Date) {
                        aCopy[key] = obj[key];
                    } else {
                        aCopy[key] = objCopy(val);        // recursive call
                    }
                } else {
                    aCopy[key] = obj[key];
                }
            }
            return aCopy;
        }

        // non recursive. copies top level, but it goes deep. Assumes that both src & tgt are dictionaries
        function objCopyGuts(src, tgt) {        // from left --> right
            var key, val, str;
            if (tgt == undefined){
                // ub3consoleLog('813 Error: cannot copy from this object into undefined', src);
                return;
            }

            for (key in src) {
                val = src[key];
                if(val === undefined){
                    tgt[key] = undefined;
                }else{
                    str = JSON.stringify(val);
                    tgt[key] = JSON.parse(str);
                }
            }
            for (key in tgt) {
                if (src && src[key] === undefined){
                    delete tgt[key];
                }
                /*
                if ((!src[key]) && (src[key] !== false)){
                    delete tgt[key];
                }
                */
            }
        }
        // non recursive. copies top level elements of array, but it goes deep.
        // Assumes that both src ind tgt are arrays an array.
        function arrayCopy(src, tgt) {  // from left --> to right
            var i;

            tgt.length = 0; // clear out the contents
            for (i in src){
                tgt.push(JSON.parse(JSON.stringify(src[i])));
            }
        }
        // non recursive. copies top level elements of array, copies original row pointers
        // Assumes that both src ind tgt are arrays of dictionaries
        function arrayCopyPointers(src, tgt) {  // from left --> to right
            var i;

            tgt.length = 0; // clear out the contents
            for (i in src){
                tgt.push({});       // pushes new empty object into array
                tgt[i] = src[i];    // make it point to original storage
            }
        }

        // recursive. compares all levels
        function isEqual(a, b, keysToIgnore) {
            // see how lodash works
            var a2, b2, i, key;
            a2 = objCopy(a);
            b2 = objCopy(b);
            ;
            del(a2, '$$hashKey');
            del(b2, '$$hashKey');
            if (keysToIgnore){
                for (i in keysToIgnore){
                    key = keysToIgnore[i];
                    del(a2, key);
                    del(b2, key);
                }
            }
            // _.isEqual is lodash function, removed
            // var same =  _.isEqual(a2, b2);
            var same = JSON.stringify(a2) === JSON.stringify(b2);
            if (!same) {
               // ub3consoleLog('859.2 a', a2);
                //ub3consoleLog('859.2 b', b2);
            }
            return same;
            // recursive, deletes key from all nested dictionaries
            function del(obj, key){
                var i, row, k;
                if (!obj) return;
                // if array, loop on each index
                if (Array.isArray(obj)){
                    // do recursion
                    for (i in obj){
                        row = obj[i];
                        del(row, key);
                    }
                    return;
                }
                // if dictionary loop on each key
                if ((typeof obj) == 'object'){
                    //if (key == 'idx') ub3consoleLog('889.0', obj);
                    if (obj[key] !== undefined){
                        //if (key == 'idx') ub3consoleLog(891.1, obj);
                        delete obj[key];
                        //if (key == 'idx') ub3consoleLog(893, obj);
                    }
                    // do recursion
                    for (k in obj){
                        del(obj[k], key);
                    }
                    return;
                }
            }
        }

    // handles keys with Arrays
    // account update / reactivate / domain admin - specific
    //
    // new parameter state has the same purpose as the previous name of 'flags'
    // But now, it has a different name on purpose.
    // for CELS: It is up to the caller
    // to make sure that state points to an independent place in the current explorer.
    // state typicaly will point to flags.currBranch.state
    // or in the case of ALCF,  state will point to $scope.flags
    //
    function validateList(formName, rowObj, rowId, state, form, fromPageLoad){

        var errors;
        if(!state.arrayFieldErrors){
            state.arrayFieldErrors = {};
        }
        if(!state.arrayFieldErrors[formName]){
            state.arrayFieldErrors[formName] = {};
        }
        errors = state.arrayFieldErrors[formName][rowId] = {};

        if(formName ==='projectInstitutions'){
            if(!rowObj || !rowObj.affiliation){
                state.okToSubmit = false;
                state.delta = true;
                return;
            }
        }

        if(formName ==='projectList' || formName ==='unixgroups'){return}

        valMod.validateNow(formName, rowObj, errors, rowId, fromPageLoad);

        if (formName == 'accountEmails'){
            if (errors.status) delete errors['status'];
        }
        if (formName == 'accountPhones'){
            if (errors.status) delete errors['status'];
        }
        if (formName == 'accountInstitutions'){
            if (errors.status) delete errors['status'];
            if (errors.ack)    delete errors['ack'];
        }
        state.missing = valMod.computeMissing(errors);

        // Exceptions below:

        // for Unix Groups
        if(state.missing == "Required: Status, Resource Type, "){
            delete state.missing;
        }
        // for projectInstitutions
        if(state.origFormName === 'projectInstitutions' &&
          (state.missing == "Required: Acknowledgement User Agreement Needed, " ||
           state.missing == "Required: Status, Acknowledgement User Agreement Needed, ")){
            delete state.missing;
        }
        // for manageUnixGroups
        if(formName ==='manageUnixGroups' && state.missing == "Required: User Name, "){
            delete state.missing;
        }

        // On Arrays, state.missing only considers one object at a time
        // so is not accurate to determine if okToSubmit on SUBMIT
        //state.okToSubmit = !state.missing;

        checkIfDataChanged(form, state);
        state.delta = !isEqual(form, state.prevSavedForm);
    }

    function checkIfDataChanged(form, state){
        var editRecordModule = findModuleByName('editRecordModule');
        if(!state.prevSavedForm){return}
        state.finalSavedForm = {};
        //state.finalSavedForm = JSON.parse(JSON.stringify(form));
        objCopyGuts(form, state.finalSavedForm);    // copy from left to right
        state.dataThatChanged = editRecordModule.getFormChanges(state.finalSavedForm, state.prevSavedForm);

        if(Object.getOwnPropertyNames(state.dataThatChanged).length === 0){
            state.okToSubmit = false;
        } else {
            state.okToSubmit = !state.missing;
        }
    }

// Only called on GET for account update, reactivate and admin edit record
    function validateListsAfterGet(flags, form){
        for(var key in form){
            if(Array.isArray(form[key])){
                var array = form[key];
                array && array.forEach(function(oneDict){
                    // call validateList, pass same params as we would on ng-change on input
                    // loop through each key array of dicts
                    // fromPageLoad always true as only called on GET
                    validateList(key, oneDict, oneDict.id, flags, form, true);
                    // user has changed nothing yet..
                     flags.delta = false;
                });

            }
        }
    }

    //
    // Generic filter for arrays
    // filter is just a string.
    // inputList is an array of objects.
    // filterFields is the list of fields to 'filter by'.
    // A logical condition of OR is applied when multiple filter fields are given.
    // It is up the the caller to ensure that the given field names in filterFields
    // actually exists in inputList
    //
    function listFilter(filter, inputList, filterFields){
        if (filter.length < 1) return inputList;

        var s, i, j, row, result, match, oneField;
        s = filter.toLowerCase();
        result = [];
        for (i in inputList){
            row = inputList[i];
            match = false;
            for (j in filterFields){
                oneField = filterFields[j];
                if (row[oneField].toLowerCase().indexOf(s) >= 0){
                    match = true;
                    break;
                }
            }
            if (match){
                result.push(row);
            }
        }
        return result;
    }

    // input parameters:
    //    ar - an array of dictionaries
    //    splitSize
    // output:  a dictionary of pages, keyed by page number
    function listSplit(ar, splitSize){
        var star, end, pageNum, pages, page ;
        if (ar.length <= splitSize) return ar;

        start = 0;
        end = start + splitSize;
        pageNum = 1;
        pages = {};
        while (start < ar.length){
            page = ar.slice(start, end);
            addIndexes(page, start);
            pages[pageNum] = page;
            start += splitSize;
            end = start + splitSize;
            if (end > ar.length) {end = ar.length;}
            pageNum ++ ;
        }
        return pages;


        function addIndexes(ar, start){
            idx = start;
            for (i in ar){
                row = ar[i];
                row.idx = idx;
                idx ++ ;
            }
        }

    }

    // settings: typically corresponds to the current branch pointer of the tree explorer
    function changePage(settings, op, num){
        var n, onePage, i, row;
        if (op == '+'){
            n = parseInt(settings.currPage) + parseInt(num);
        }else{
            // go to specific page
            n = parseInt(num);
        }
        if (!settings.pages){
            settings.pages = {};
        }
        onePage = settings.pages[n];
        if (onePage){
            settings.currPage = parseInt(n) ;
            settings.matchedList = onePage;
        }else{
            //no page to the left or right, do nothing
        }
    }

    function filterProjectName(candidate, projects){
        if (!candidate || (candidate.length < 2)) {
            setAllToDisplay();
            return false ;
        }
        var i, p, ret, match;
        if (!projects){
            setAllToDisplay();
            return false;
        }
        ret = '';
        match = false;
        setAllToDisplay();
        for (i in projects){
            p = projects[i];
            if (p.name.toLowerCase().indexOf(candidate.toLowerCase()) > -1){
                p.noShow = false;
                if (p.name.toLowerCase() == candidate.toLowerCase()){
                    match = true;
                }
            }else{
                p.noShow = true;
            }
        }

        function setAllToDisplay(){
            for (i in projects){
                p = projects[i];
                p.noShow = false;
            }
        }

        return match;
    }

    var filterDataCache = {};
    function clearFilterCache(){
        filterDataCache = {};
    }
    function filter(arName, data, colName, op, filters){
        var temp, key, original, i, d;

        // first filter event for this array, store original big table
        if (!filterDataCache[arName]){
            d = filterDataCache[arName] = [];
            for (i in data){ d.push(data[i])};
        }

        if (op == 'last'){
            for (key in filters){
                if (key != colName){
                    filters[key] = '';
                }
            }
        }
        original = filterDataCache[arName];
        temp = original.filter(function (row) {
            var v, fv, fcn;
            if (op == 'and'){
                v = true;
                for (fcn in filters){   // fcn = filter column name, fv = filter value
                    fv = filters[fcn].trim().toLowerCase();
                    if (fv && fv !== ''){
                        var celValue = (!row[fcn]) ? '' : (row[fcn]).toString().toLowerCase();
                        v = v && (celValue.indexOf(fv) >= 0);
                    }
                }
                return v;
            }
            if (op == 'or'){
                v = false;
                for (fcn in filters){   // fcn = filter column name, fv = filter value
                    fv = filters[fcn].trim().toLowerCase();
                    if (fv && fv != ''){
                        var celValue = (!row[fcn]) ? '' : (row[fcn]).toString().toLowerCase();
                        v = v || (celValue.indexOf(fv) >= 0) ;
                    }
                }
                return v;
            }
            if (op == 'last'){
                fv = filters[colName].trim().toLowerCase();
                return  (row[colName].toLowerCase().indexOf(fv) >= 0) ;
            }
        });
        data.splice(0, data.length);
        for (i in temp){
            data.push(temp[i]);
        }
    }
    function addDisplayName(ar){
        var i, row, fullName;
        for (i in ar){
            row = ar[i];
            fullName =  row.first_name + ' ' + row.last_name;
            fullName = fullName.replace(/\s+/g,' ').trim() ;
            row.dispName = fullName;
        }
    }
    // Allows letters(uppercase, lowercase) numbers - _
    //
    // Brinda and Guillermo agreed to this name. In the future if we have another set of rules we
    // are going to name it: sanitizeRule2, etc...
    //
    function sanitizeRule1(str){
        if (!str) return str;
        str = replaceAll(str,' ','');

        var allowed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        allowed += allowed + allowed.toLowerCase(allowed);
        allowed += '0123456789-_';
        for (var i = 0; i<str.length; i++){
            var c = str.charAt(i);
            if (allowed.indexOf(c) < 0){
                str = replaceAll(str,c,'');
                // break out of loop here, otherwise indexes get messed up
                return sanitizeRule1(str);
            }
        }
        return str;
    }

    function getLocaleString(str, type){
        if (!str) return str;
        str = str.replace(/[^.0-9]/g, '');
        var num = typeof(str) === 'number' ? str : Number(str);
        if(type === 'int') num = parseInt(num);
        if(type === 'float') num = parseFloat(num);
        // returns a string with a language-sensitive representation of the given number.
        num = num.toLocaleString("en-US");
        return num;
    }

    function replaceAll(str, find, replace) {
        // this used to be implemented with regex,  but got in trouble when str itself
        // contained special characters like $ or ^, which have special meaning in regex
        // this one is more reliable
        if (!str) return str;
        return str.split(find).join(replace);
    }

    function getScienceValues(callback){
        var arr = [];
        http.get('/svc/FieldOfStudy', {params: {search: '%'}})
            .success(function(resp){
                if (resp.success){
                    for (var i in resp.data){
                        var r = resp.data[i];
                        arr.push(r.name);
                    }
                    callback(arr);
                }
            })
            .error(function(d){
                callback(arr);
            });
    }

    // not used anywhere
/*
    function getProjectsList(http, lists){
        http.get("/auth/activeProjects") // params formName is currently not being used
             .success(function(resp){
                        lists.activeProjects = resp.activeProjects;
             })
            .error(function(e){
            });
    }
*/
    function getLimitedProjectsList(lists){
        http.get("/limited/activeProjects")
             .success(function(resp){
                lists.activeProjects = resp.activeProjects;
             })
            .error(function(e){
            });
    }

    function getInstitutionsList(http, lists){
        //flags.matchedName = false;
        http.get("/auth/allActiveInstitutions")
             .success(function(resp){
                       if(!scope.cels){lists.institutions = resp.institutions}
                       else {
                           lists.institutions = resp.institutions.filter((e) => {
                               return !['ANL : ANL', 'Argonne National Laboratory'].includes(e.name)
                           });
                       }
             })
             .error(function(data, status, headers, config) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
             });
    }
    function getInstActPend(lists){
        if(!scope.cels && !scope.user.signedIn) return;
        //flags.matchedName = false;
        http.get("/auth/allInstActPend")
             .success(function(resp){
                        lists.instActPend = resp.institutions;
             })
             .error(function(data, status, headers, config) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        //ub3consoleLog('should something be reportrd to the user?');
             });
    }

    function getLimitedInstitutionsList(http, lists){
        //flags.matchedName = false;
        http.get("/limited/allActiveInstitutions")
             .success(function(resp){
                        lists.institutions = resp.institutions;
             })
             .error(function(data, status, headers, config) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        //ub3consoleLog('should something be reportrd to the user?');
             });
    }

    function getAuthInstitutionsList(http, lists){
        //flags.matchedName = false;
        http.get("/auth/allActiveInstitutions")
             .success(function(resp){
                        lists.institutions = resp.institutions;
             })
             .error(function(data, status, headers, config) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        //ub3consoleLog('should something be reportrd to the user?');
             });
    }

    function unixGroupList(http, lists, alerts){
        if(!scope.user.signedIn) return;
        var p = {search : "%"};
        http.get("/svc/UnixGroup", {params : p})
            .then(function(response){
                var resp = response.data
                if(resp.success){
                    lists.allUnixGroups = resp.data;
                } else{
                    alertError.msg = resp.error;
                    alerts && alerts.push(alertError);
                }
            }).catch(function(response, status){
                alertError.msg = response.data.detail;
                alerts && alerts.push(alertError);
            });
    }
    function getProxyProjects(http, lists, callback){
        http.get('/proxy/projects')
            .then(function(res){
                var resp = (res.data) ? res.data : res;
                if(resp.success){
                    lists.proxyProjects = resp.projectList;
                    if(callback) callback(lists);
                }
            })
            .catch(function(response, status){
                if (response.data && response.data.detail){
                    lists.error = response.data.detail;
                    lists.proxyProjects = [];
                    if(callback) callback(lists);
                }
            });
    };

    function getUserInfo(http, u, callback) {
        var p = {search: 'username=' + u.userName} ;
        http.get("/svc/UserInfo", {params: p})
            .success(function (resp) {
                    if (resp.success){
                        callback(resp.data);
                    }else{
                        callback(resp.error);
                    }
            });
    }
    function getUsersList(http, lists, callback) {
        http.get("/auth/users")
            .success(function (resp) {
                    if (resp.success){
                        lists.users = resp.users;
                        if (callback) callback();
                    }
            });
    }

    function getUsersNotSystemCreated(http, lists, flags){
        http.get("/proxy/usersNotSystemCreated")
            .success(function(resp){
                if(resp.success){
                    lists.usersNotSystemCreated = resp.users;
                } else{
                    flags.submitError = resp.error;
                }
            }).error(function(data, status, headers, config){
            lists.error = data.data.detail;
        });
    }

    function matchUsers(str, allAccounts, sc){
        var userList, obj;
        onStopCalling(700, function(){
            if(allAccounts){
                userList = sc.lists.users;
            } else{
                userList = sc.lists.usersNotSystemCreated;
            }
            obj = selectUsers(str, userList);
            sc.flags.tooMany = obj.tooMany;
            sc.flags.rowsFound = obj.found;

            // get the primary email
            var i, row;
            for (i in obj.results){
                row = obj.results[i];
                if (row.emails && row.emails[0] && row.emails[0].primary) row.email = row.emails[0].email;
                if (row.emails && row.emails[1] && row.emails[1].primary) row.email = row.emails[1].email;
            }
            sc.lists.matchedUsers = obj.results;
        });
    }
    // specific for angular NG2+
    function matchUsersNG(str, allAccounts, flags, lists){
        var userList, obj;
        onStopCalling(700, function(){
            if(allAccounts){
                if (!lists.users){
                    getUsersList(http,  lists, function(){
                            userList = lists.users;
                            doit();
                    });
                }else{
                   userList = lists.users;
                   doit();
                }
            } else{
                userList = lists.usersNotSystemCreated;
                doit();
            }
            function doit(){
                obj = selectUsers(str, userList);
                flags.tooMany = obj.tooMany;
                flags.rowsFound = obj.found;
                lists.matchedUsers = obj.results;
            }
        });
    }
    function matchPjUsers(str, allAccounts, state){
        var userList, obj;
        onStopCalling(700, function(){
            if(allAccounts){
                userList = scope.lists.users;
            } else{
                userList = scope.lists.usersNotSystemCreated;
            }
            obj = selectUsers(str, userList);
            state.tooMany = obj.tooMany;
            state.rowsFound = obj.found;
            state.matchedUsers = obj.results;
        });
    }
    function toggleMatchPjUsers(state){
        scope.flags.showAddUser = !scope.flags.showAddUser;
        if (!scope.flags.showAddUser){
            state.tooMany = null;
            state.rowsFound = 0;
            state.matchedUsers = null;
            state.filterStr = null;
        }
    };

    function selectUsers(str, users, max){
        var s, results, eResults, obj, i, p, ret, u, match, show;
        var maxRows = 300;
        if (max) maxRows = max;

        obj = {tooMany: false, found: null, results: null};
        if (!str || (str.length < 2)) {
            return obj;
        }
        if (!users){
            return obj;
        }
        s = str.toLowerCase();
        results = [];

        for (i in users){
            u = users[i];
            show = false;
            if (u.userName.toLowerCase().indexOf(s) > -1){
                show = true;
            }
/*
            if (!show && u.anlUserName && u.anlUserName.toLowerCase().indexOf(s) > -1){
                show = true;
            }
*/
            if (!show && u.firstName.toLowerCase().indexOf(s) > -1){
                show = true;
            }
            if (!show && u.lastName.toLowerCase().indexOf(s) > -1){
                show = true;
            }
            if (!show && u.preferredName.toLowerCase().indexOf(s) > -1){
                show = true;
            }
/*
            if (!show){
                for (ii in u.emails){
                    e = u.emails[ii];
                    if (e.email.toLowerCase().indexOf(s) > -1){
                        show = true;
                    }
                }
            }
*/
            if (show){
                results.push(u);
            }
        }
        eResults = [];
        if (results.length > maxRows){
            // found too many,  now search by exact word
            for (i in users){
                u = users[i];
                show = false;
                if (u.userName.toLowerCase() == s){
                    show = true;
                }
                if (!show && u.firstName.toLowerCase() == s){
                    show = true;
                }
                if (!show && u.lastName.toLowerCase() == s){
                    show = true;
                }
                if (!show && u.preferredName.toLowerCase() == s){
                    show = true;
                }
                if (show){
                    eResults.push(u);
                }
            }
        }
        if (eResults.length > 0){
            results = eResults;
        }
        if (results.length < maxRows){
            obj.results = results;
        }else{
            obj.tooMany = true;
            obj.found = results.length;
            obj.results = results.slice(0,maxRows);
        }
        return obj;
    }

    // I will call callback when you stop bugging(calling) me for 'delay' milliseconds
    //
    var userIsTyping = false;
    function onStopCalling(delay, callback){

        if (!timeout.cancel){
            // in ng2+ . ... make it compatible with old ng1 code
            timeout.cancel = clearTimeout;
        }
        timeout.cancel(typingPromise);
        if (!userIsTyping){
            // this is the first keystroke from the user (or from cypress) for this input field
            userIsTyping = true;
            changeCallsPending('user is typing', topState, 1);        // for cypress
        }
        typingPromise = timeout(function(){
            userIsTyping = false;
            if (scope.httpCallsPending == 0){
                ub3consoleLog('callsPending confused !!!!  about to call', callback);
            }
            changeCallsPending('user done typing', topState, -1);        // for cypress
            callback();
        }, delay) ;
    }

    // this function will call callback when httpCallsPending becomes 0
    //
    function doWhenAPICallsDone(callback, param){
        var pending;
        if (scope){
            pending = scope.httpCallsPending;
            if ((typeof pending) == 'undefined'){
                // try inside state, this must be the app.component.ts scope
                if (scope.state){
                    pending = scope.state.httpCallsPending;
                }
            }
        }else{
            // scope is not ready, fake a value
            pending = 1;
        }

        if (pending == 0){
            if (param){
                callback(param);
            }else{
                callback();
            }
            return;
        }
        timeout(function(){ doWhenAPICallsDone(callback, param);}, 300);
    }

    function getCountries(lists){
        http.get("/auth/countries")
            .success(function(resp){
                if (resp.success){
                    lists.countries = resp.countries;
                }else{
                    // try the limited ones
                    getLimitedCountries(lists);
                }
            });
    }

    function getALCFNews(lists){

        http.get("public/news")
        .success(function(resp){
            if (resp.success){
                var x2js = new X2JS();
                var cp = resp.news.replaceAll('&nbsp;',' ');
                var jsonData = x2js.xml_str2json(cp);
                lists.alcfNews = jsonData && jsonData.rss.channel.item;
                if (lists.alcfNews){
                    lists.alcfNews3 = lists.alcfNews.slice(0,3);
                }
            }
        });
    }

    function publicPage(pub){
        if (typeof angular === 'undefined') return;   // for angular9

        var targetElement = angular.element(document.querySelector('#body'));
        if (pub){
            targetElement.addClass('public-page');
            // in case user was on survey previous, clear out to show all hidden elements/
            if(!scope.isReactivateSurvey){targetElement.removeClass('doe-data-survey');}
        }else{
            targetElement.removeClass('public-page');
        }
    }

    function explorerPage(explorer){
        if (typeof angular === 'undefined') return;   // for angular9

        var targetElement = angular.element(document.querySelector('#body'));
        if(explorer){
            targetElement.addClass('explorer-page');
        } else{
            targetElement.removeClass('explorer-page');
        }
    }

    // defunct
    function isCollaborator(username){
        var isCollaboratorUsername;
        isCollaboratorUsername = username.indexOf("ac.") === 0;
        return isCollaboratorUsername;
    }

    function hasPrimary(arr){
        var hasPrimary;
        arr.forEach(function(b){
            if(b.primary){hasPrimary = true}
        });
        return hasPrimary;
    }

    function changeOwner(owner, users){
        if(!owner){return}
        if(owner){
            // clicked to remove user as owner
            // check how many owners there are
            var numberOfOwners = 0;
            users.forEach(function(item, index){
                if(users[index].owner){
                    numberOfOwners++
                }
            });
            if(numberOfOwners >= 2){
                // ok to remove owner
                return true
            } else{
                // nope, must be at least one owner..
                return false
            }
        }
    }

    function callHelpDesk(type){
         scope.flags.helpForUserName = false;
         scope.flags.helpForPassWord = false;
        if(type ==='password'){
            scope.flags.helpForPassWord = true;

        } else if (type==='username') {
            scope.flags.helpForUserName = true;
        }
    }
    function formatDeactDt(obj){
        if(obj.accountType === 'Permanent' && obj.deactivation_date) {
          //need to format the deactivation date
          obj.deactivation_date = new Date(obj.deactivation_date).toISOString().substring(0,10);
        }
      }

    function convertUTCtoCDT(date){
        if(!date){return 'N/A';} else{
            var checkDate = dayjs(date, 'YYYY-MM-DD HH:mm:ss');
            var isValidDate = checkDate.isValid();
            if(isValidDate){
                // display medium date .format('MMM D, YYYY'), returns 'Dec 27, 2018'
                // to see converted date, test with hours .format("YYYY-MM-DD HH:mm");
                var cdt = dayjs.utc(date).tz('America/Chicago').format('MMM D, YYYY');
                return cdt;

            } else{
                // this isn't a valid date but a string, just show it...
                //example "Favor record missing"
                return date;
            }
        }

    }

    function getCertifiedHosts(flags, sharedLists, callback) {
        http.get("/admin/certifiedHosts")
            .success(function (resp) {
                    if (resp.success){
                        sharedLists.certifiedHosts = sortArrayOnKey(resp.hosts, 'firstName');
                    }else{
                        flags.readError = resp.error;
                    }
                    if (callback){callback();}
            })
            .error(function(data){
                    if (callback){callback();}
            });
    }

    function getLimitedCountries(lists){
        http.get("/limited/countries")
            .success(function(resp){
                lists.countries = resp.countries;
            });
    }

    function getCountryCode(country, lists){
        var codes = lists && lists.countries;
        if(typeof codes !== "undefined"){
            for(var i = 0 ; i < codes.length ; i++){
                for(var item in codes[i]){
                    if(codes[i][item] === country){
                        //var code =  '(' + codes[i].dialCode + ')';
                        return codes[i].dialCode;
                    }
                }
            }
        }
    }
    function onCountryChange(row, lists, callback){
        var dc = getCountryCode(row.selectedCountry, lists);
        if (!dc || (/\d/.test(row.selectedCountry))) {
            row.selectedCountry = '';
            row.phoneCountry = '';
        } else {
            row.phoneCountry = row.selectedCountry;
            row.selectedCountry = dc;
        }
        if(callback) callback();
    }

    function ctryStateEditable(state){
        return (state !== 'United States');
    }

    function getInstitutionId(name, lists){
        var names;

        if(scope.cels){
            names = lists.institutions;
            if(typeof names !== "undefined"){
                for(var i = 0 ; i < names.length ; i++){
                    for(var item in names[i]){
                        if(names[i][item] === name){
                            return names[i].id;
                        }
                    }
                }
            }
            return null;
        }

        // this is for all others non-cels
        names = lists.instActPend;
        if ( !names) return null ;  // something is not ready

        for (i in names){
            item = names[i];
            if (item.name == name){
                return item.id;
            }
        }
        // not found
        return null;
    }

    function getUniqueEmails(emlArray){
        var i, row;
        if (!emlArray) return emlArray;
        for (i = 0; i < emlArray.length; i++){
            row = emlArray[i];
            //check for undeleted duplicate emails
            var dupEmail = emlArray.filter(function(item){
                return ((item.id !== row.id) && (row.email && item.email && item.email.toLowerCase() === row.email.toLowerCase()) && (item.status !== 'Deleted'));
            });

            if(dupEmail.length > 0){
            // delete the current one if it is not primary.
            // if current one is primary, delete the duplicate one
            var arr = (row.primary) ? dupEmail[0] : row;
                var idx = emlArray.findIndex(function(item){
                return item.id ===  arr.id;
                });
                emlArray[idx].status = "Deleted";
            }
        }
        return emlArray;
    }

    function signInNg(userName, password, loginScope, callback){
        var p = {
            userName : userName,
            password : password
        };

        http.post("/public/loginUser", p)
            .success(function(resp){
                if(resp.success){
                    setUser(loginScope, function(){
                        if (!loginScope.state) loginScope.state = {};
                        objCopyGuts(loginScope.user,  loginScope.state.user);
                        valMod.init(formNames());
                    });
                } else{
                    loginScope.accessDenied = true;
                    loginScope.errorMsg = resp.error;
                }
                if (callback) callback(resp.success);
            })
            .error(function(data){
                loginScope.accessDenied = true;
                if (callback) callback(false);
            });
    }

    function signIn(userName, password){
        var p = {
            userName : userName,
            password : password
        };


        // If address bar contains any of these relative addresses,  block it.  Go to default place
        var exceptions = ['#!/accountReactivate', '#!/dataSurvey', '#!/accountRequest'];


        http.post("/public/loginUser", p)
            .success(function(resp){
                if(resp.success){
                    if (exceptions.indexOf(location.hash) >= 0){
                        if(!scope.cels){
                            win.location.assign(GC.defaultPageOnLogin);
                        } else {
                            win.location.assign(GC.defaultPageOnLogin);
                            win.location.reload();
                        }
                    } else{
                        win.location.assign(GC.defaultPageOnLogin);
                        win.location.reload();
                    }
                    setUser(scope);
                    if (scope.modalInstance){
                        scope.modalInstance.close();
                    }
                } else{
                    scope.accessDenied = true;
                    scope.errorMsg = resp.error;
                }
            })
            .error(function(data){
                    scope.accessDenied = true;  // comment this out to get automated test 1525 to fail
            });
    }
    // router gets sent from ng2+
    function signOut(delay, s, stay, router){
        if (s) scope = s;
        if (delay){
            timeout(function(){ signOut(null, s, stay, router); }, delay);
            return;
        }

        var logoutApi = '';

        setUser(scope, function(){
                if (scope && scope.user && scope.user.role && (scope.user.role != 'public')){
                    tryToSignOut();
                }else{
                    terminateSession(scope, stay, router);
                }
        });


        function tryToSignOut(){

            if(scope.user.role == 'limited'){
                logoutApi = '/limited/logoutUser';
            } else{
                if(!scope.isDuoLogin){
                    logoutApi = '/auth/logoutUser';
                } else{
                    logoutApi = '/auth/duoLogout';
                }

            }
            http.post(logoutApi)
                .success(function(resp){
                    if(scope.isDuoLogin){
                        window.open(resp.url,"_self");
                        //openWindowWithPost(resp.url);
                    } else {
                        terminateSession(scope, stay, router);
                    }
                })
                .error(function(data){
                    // sign out in front end regardless of what the backend responds
                    terminateSession(scope, stay, router);
                });
        }
    };

    function openWindowWithPost(duoURL) {
        var f = document.createElement('form');
        f.action= duoURL;
        f.method='POST';
        f.target='_self';

        var i=document.createElement('input');
        i.type='hidden';
        i.name='fragment';
        i.value='<!DOCTYPE html>'+document.documentElement.outerHTML;
        f.appendChild(i);

        document.body.appendChild(f);
        f.submit();

    }

    // router is sent to this function from Angular 2+
    function terminateSession(scope, stay, router){
        scope.showSignOut = false;
        var redirectUrl = '/';

        if (!stay){
            if (router){
                scope.user.signedIn = false;
                scope.user.role = 'public';
                scope.dispLogin = true;
                var appFunctions = getSharedObject('app', 'functions');
                appFunctions.setUser();
                router.navigateByUrl('/home');      // ng2+
            }else{
                win.location.assign(redirectUrl);   // ng1
            }
        }
        scope.user = {role: 'public', signedIn: false};
    }

    // option to use on api responses that contain 403 errors..
    // may not be needed..
    function redirectForbidden(scope, resp){
        if(resp == '403'){
            modalInstance = $uibModal.open({
                template : '<login-modal></login-modal>',
                size : size,
                scope : modalScope
            });
        }
    }

    /* ------------ LIMITED LOGIN FUNCTIONS ------------*/
   // This function creates a session, calls whoami(by calling setUser),
    // and also triggers the backend to send an email code to the user
    //
    // /public/loginLimitedUser     returns   a few diferent values as output
    // returns  {success: true/false,  action: <string>, securityQuestion: <string>, error: <string>}
    // There is a copy of this function in accountRequestModule.js which
    // is used by Account Update, Account Request, DataSurvey.
    // This one is used by Account Reactivate
    function limitedLogin(form, scope, callback){
        var gresp, cform;
        var LLState = {};

        scope.flags.err1 = null ;
        scope.flags.submitError = null ;
        cform = {
            email       : form.email,
            firstName   : form.firstName,
            lastName    : form.lastName,
            purpose     : (scope.flags.reactivateAccount ? 'REACTIVATE' : (scope.flags.allocReq ? 'DDALLOC_REQUEST' : 'REQUEST'))
        };
        LLState.purpose = cform.purpose;

        http.post("/public/loginLimitedUser", cform)
        .success(function(hresp){
                var err;
                //hresp.success = false; hresp.error = {message : 'Blargh !!!'}; //enable this to test error case
                gresp = hresp;
                if (hresp.success) {
                    LLState.nextAction = hresp.action;
                    if(!scope.flags.allocReq){
                        scope.flags.disableHref = (hresp.action ==='reactivateEnterEmailCode') ? 'Request an Account' : 'Reactivate an Account';
                    }

                    setUser(scope, doMore);
                }else{
                    err = hresp.error;
                    scope.flags.err1 = hresp.error;
                    if(!scope.flags.allocReq && hresp.error === 'Your name, email address, and username are already in our Account and Project Management system. Please use Reactivate an Account to re-establish your account'){
                        scope.flags.redirectToReactivate = true;
                    }
                    scope.flags.submitError = hresp.error.message;
                    LLState.error = true;

                    callback({error: err, beError: "Can't create limited login session. " + hresp.error.message});
                }
        })
        .error(function(e){
            //ub3consoleLog('should something be reported to user?');
        });

        function doMore(){
                    if(!scope.flags.allocReq){
                        scope.user.action = gresp.action ;
                        scope.user.securityQuestion = gresp.securityQuestion ;
                    }

                    http.post("/limited/sendEmailCode")
                        .success(function(eresp){
                            //eresp.success = false; eresp.error = {message : 'Blargh EM !!!'}; //enable this to test error case
                            if (eresp.success) {
                                scope.flags.askForCode = true;
                                scope.flags.codeLength = eresp.codeLength ;
                                scope.flags.emailTimeSent = new Date();
                                LLState.askForCode = true;
                                LLState.codeLength = eresp.codeLength;
                            }else{
                                scope.flags.submitError = eresp.error.message;
                                LLState.error = true;
                                LLState.beError = "Can't send email. " + eresp.error.message;
                            }
                            callback(LLState);
                        })
                        .error(function(serr){
                            //ub3consoleLog('should something be reported to user?');
                        });
        }
    }
    function setLocalStorage(name, val){
        var s;
        s = (val && val != '') ? JSON.stringify(val) : '' ;
        localStorage.setItem(name, s);
    }
    function getLocalStorage(name){
        var c = localStorage.getItem(name);
        var obj = (c && c != '') ? JSON.parse(c) : null;
        return obj;
    }

    function tweakOrcid(str, fldNameEdited, prevSavedForm){
        var soft, rv, result, section, i, lastDigit, userIsAdmin, special, href;

        userIsAdmin = (scope && scope.user && (scope.user.role == 'domainAdmin'));
        href = win.location.href;
        special = (userIsAdmin && (href.indexOf('/userEdit') >= 0));
        if (special) return tweakOrcidSpecial(str, fldNameEdited, prevSavedForm);

        soft = true;

        if (soft){
            rv = str.toUpperCase().replace(/[^0-9X]/g, '');
        }else{
            rv = str.replace(/[^0-9]/g, '');
            rv = rv.slice(0,15);
            if (rv.length >= 15){
                lastDigit = getCheckSum(rv);
                rv = rv.slice(0,15) + lastDigit;
            }
        }

        // now insert - every 4 chars
        result = '';
        for (i=0 ; i <= (soft ? 16 : 12); i += 4){
            section = rv.slice(i, i+4);
            if (section != ''){
                result +=  '(' + section + ')';
            }
        }
        result = replaceAll(result, ')(', '-');
        result = replaceAll(result, '(', '');
        result = replaceAll(result, ')', '');
        return result;
    }
    // this function is only called when page is 'userEdit' and  the role is 'domainAdmin'
    function tweakOrcidSpecial(str, fldNameEdited, prevSavedForm){
        var soft, rv, result, section, i, lastDigit;
        soft = true;
        if(str === prevSavedForm.orcid) return str;
        if(fldNameEdited === 'orcId'){
                rv = str.toUpperCase().replace(/[^0-9X]/g, '');
        } else{
                rv = str.toUpperCase().replaceAll('-','');
        }

        // now insert - every 4 chars
        result = '';
        for (i=0 ; i <= 16; i += 4){
            section = rv.slice(i, i+4);
            if (section != ''){
                result +=  '(' + section + ')';
            }
        }
        result = replaceAll(result, ')(', '-');
        result = replaceAll(result, '(', '');
        result = replaceAll(result, ')', '');
        return result;
    }
    // this checksum algorithm: comes from the page:
    // https://support.orcid.org/hc/en-us/articles/360006897674-Structure-of-the-ORCID-Identifier#:~:text=Format%20of%20the%20ORCID%20iD&text=ORCID%20iDs%20always%20require%20all,encoded%20in%20the%20ORCID%20iD
    function getCheckSum(str) {
        var total, i, digit, remainder, result;
        total = 0;
        for (i = 0; i <= 14; i++) {
            digit = str[i];
            total = (total + parseInt(digit)) * 2;
        }
        remainder = total % 11;
        result = (12 - remainder) % 11;
        return result == 10 ? "X" : result+'';
    }

    function compareAddresses(form) {
        if(form && form.accountAddresses && form.accountAddresses[0] && form.accountAddresses[1]){
          var add = form.accountAddresses;
          return isShipAddSameAsWorkAdd(add[0], add[1]);
        }
        function isShipAddSameAsWorkAdd(add1, add2){
          if(add1 && add2){
            var props1 = Object.getOwnPropertyNames(add1);
            var props2 = Object.getOwnPropertyNames(add2);
            if (props1.length != props2.length) {
              return false;
            }
            for (var i = 0; i < props1.length; i++) {
              var prop = props1[i];
              if(prop !== 'id' && prop !== 'label'){
                if (add1[prop] !== add2[prop]) {
                  return false;
                }
              }
            }
            return true;
          }
          return false;
        }
    }

    function verifyEmailCode(scope, code, action, callback){
        var flags = scope.flags;
        code = code ? code.trim() : code ;
        var p = {emailCode: code};
        if (code && (flags.codeLength != code.length)){
            flags.askForCodeError = 'Please copy & paste the exact code from the email that we sent you.' ;
            if (callback) callback();
            return;
        }
        flags.confirmingCode = true;
        flags.emailCodeVerified = false;
        flags.askForCodeError = null;
        flags.readError = null;

        http.post("/limited/verifyEmailCode", p)
            .success(function(resp){
                var done = true;
                if (resp.success){
                    flags.emailCodeVerified = true;
                    flags.askForCode = false;
                    // determine which fields are required
                    if (action=='requestEnterEmailCodeEnterQA')  {
                                flags.LLStage = 'C';
                                scope.stage = 4;
                    }
                    if (action=='requestEnterAnswer'){
                                flags.LLStage = 'D';
                                scope.stage = 5;
                    }
                    if (action=='requestEnterEmailCode'){
                                // this one cannot happen if email code
                                // if verified successfully
                                flags.LLStage = 'D';
                                scope.stage = 2;
                    }
                    if (action=='reactivateEnterAnswer'){
                                flags.LLStage = 'D';
                                scope.stage = 5;
                    }
                    if (action=='reactivateEnterEmailCode'){
                                flags.LLStage = 'D';
                                scope.stage = 2;
                    }
                    scope.flags.level = scope.stage;

                    // determine if we need to challenge user for the answer
                    if ( action.endsWith('EnterAnswer') ||
                        (action=='reactivateEnterEmailCode') ||
                        (action=='requestEnterEmailCode')){

                        ub3consoleLog(2159, 'confused');
                        done = false;
                        http.get('/limited/securityQuestion')
                            .success(function(resp2){
                                if (resp2.success){
                                    scope.user.securityQuestion = resp2.question;
                                }else{
                                    flags.readError = resp2.error;
                                }
                                if (callback) callback();
                            });
                    }
                }else{
                    flags.askForCodeError = resp.error ;
                }
                flags.confirmingCode = false;
                if (callback && done){
                    callback();
                }
            })
            .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        msg.data.detail = 'Your session has expired. Please select "EMAIL ACCESS CODE" again.'
                    }
                    flags.submitError = msg.data.detail;
                    flags.askForCodeError = msg.data.detail;
                }
                if (callback){
                    callback();
                }
            });
    }

    // same comment as for above function
    function validateLL(form, flags, timeout, callback){
        onStopCalling(700, function(){
            validateLLNow(form,flags);
            if (callback) callback();
        });
    }
    // same comment as for above function
    function validateLLNow(form, flags){

        flags.fieldErrors = {};
        if (form.userName){
            form.userName = form.userName.toLowerCase();
        }
        valMod.validate('limitedLogin'+flags.LLStage, form, flags.fieldErrors);

        /*  this section commented out because of issue #506, uncomment if users change their mind
        if (flags.LLStage == 'C'){
            sa  = form.securityAnswer ;
            csa = form.confirmSecurityAnswer ;
            if (sa && (!csa || (csa == '') || (csa != sa))){
                flags.fieldErrors['confirmSecurityAnswer'] = {
                    required:true,
                    label: 'confirm security answer',
                    error: 'retype security answer correctly'
                };
            }
        }
        */
        // moreLL is true only on account request
        if (!flags.moreLL){
            if (flags.fieldErrors.nmi) delete flags.fieldErrors.nmi;
            if (flags.fieldErrors.middleName) delete flags.fieldErrors.middleName;
            if (flags.fieldErrors.userName) delete flags.fieldErrors.userName;
        }
        flags.missing = valMod.computeMissing(flags.fieldErrors) ;
        if(flags.reactivateAccount && flags.missing === "Required: Desired User Name, "){
            delete flags.missing;
        }
        flags.okToSaveQA = !flags.missing;
        flags.okToSubmit = !flags.missing;
        if (flags.missing){
            flags.Aerror = flags.missing;
        }else{
            // clear out error message displayed to user
            flags.Aerror = '';
        }
    }

    // same comment as for above function
    function verifySecurityAnswer(scope, answer, callback){
        var p = {
            securityQuestion: scope.user.securityQuestion,
            securityAnswer  : answer
        };
        scope.flags.verifyingAnswer = true;
        scope.flags.answerVerified = false;
        scope.flags.wrongAnswer = null;
        http.post("/limited/verifySecurityAnswer", p)
            .success(function(resp){
                if (resp.success){
                    scope.flags.answerVerified = true;
                    scope.flags.level = 5;
                    if(scope.flags.reactivateAccount){
                        reactAnswerVerified(cookies, scope);
                    }
                    if ((scope.user.action == 'requestEnterAnswer') || (scope.user.action =='requestEnterEmailCode')){
                        // ***** redirect here to accountRequest form
                        getFormH();
                    }
                    if (callback){
                        callback();
                    }
                }else{
                    scope.flags.wrongAnswer = resp.error ;
                }
                scope.flags.verifyingAnswer = false;
            })
            .error(function(msg){
                if (msg.data && msg.data.detail){
                    if (msg.status == 403){
                        var limitedName = !scope.flags.reactivateAccount ? 'request' : 'reactivate';
                        msg.data.detail = 'Your session has expired. Please select "Cancel account ' + limitedName + ', and try again.'
                    }
                    scope.flags.readError = msg.data.detail;
                }
                if (callback){
                    callback();
                }
            });
    }

    // same comment as for above function
    function reactAnswerVerified(cookies, scope){
        var cookieObj = getLocalStorage('anl-UB3-LLF');

        cookieObj.answerVerified = true;
        setLocalStorage('anl-UB3-LLF', cookieObj);

        if(scope.isReactivateSurvey){
            scope.reactValidate();
        }
    }


    // takes an array of dictionaries
    // produces an array of dictionaries
    function sortArrayOnKey(ar, keyName, reverse){
        var i,row, keyVal, dict, result, sortedDict;
        dict = {};
        for (i in ar){
            row = ar[i];
            keyVal = row[keyName];
            dict[keyVal+''+i] = row;
        }
        sortedDict = sortOnKeys(dict, reverse);
        result = [];
        for (i in sortedDict){
            keyVal = sortedDict[i];
            result.push(keyVal);
        }
        return result;
    }
    function sortOnKeys(dict, reverse) {

        var sorted, key, tempDict, i;
        sorted = [];
        for(key in dict) {
            sorted[sorted.length] = key;
        }
        sorted.sort();
        if (reverse){
            sorted = sorted.reverse();
        }

        tempDict = {};
        for(i = 0; i < sorted.length; i++) {
            tempDict[sorted[i]] = dict[sorted[i]];
        }
        return tempDict;
    }

    function locateByZip(country, zip, callback) {
        var z5, z54, api;

        if (country != 'United States')
            return;

        if (!zip) return;
        if (zip.length < 5) return;

        z5 = /^\d{5}$/.test(zip);
        z54 = /^\d{5}(-\d{4})?(?!-)$/.test(zip);
        if ((!z5) && (!z54)) {
            return; // only do US zip codes
        }
        if (scope.user.role == 'limited'){
            api = '/limited/locateByZip';
        }else{
            api = '/auth/locateByZip';
        }
        http.get(api+'?zip='+zip.slice(0,5))
            .success(function(resp){
                if (resp.success){
                    callback(resp.state, resp.city);
                }else{
                    //ub3consoleLog('should something be reported to user?');
                }
            })
    }

    function locateByZipAup(formName, obj, row, form){
            locateByZip('United States', obj.zip, function(state, city){
            form[formName][row]['state'] = state;
            form[formName][row]['city'] = city;
        });
    }

    function cut(str, delim, fieldNum){
        var s = str.split(delim);
        if (fieldNum < s.length){
            return s[fieldNum];
        }else{
            return null;
        }
    }


// Don't comment out
// should be used where form has IDs such as account request..
    function scrollToError(flags, location, anchorScroll, scope){
        if(scope.mobile){
            anchorScroll.yOffset = 80;
        }

//Reversed object - scrolls bottom up, thus scrolls to first from top
        var fieldErrors = reverseObject(flags.fieldErrors);
        if(fieldErrors !== null && typeof fieldErrors === 'object'){
            Object.keys(fieldErrors).forEach(function(key){
                for(var item in fieldErrors[key]){
                    if(item === 'optional'){
                        delete fieldErrors[key];
                    }

                }
                for(var item in fieldErrors[key]){
                    if(item === 'required' || item === 'error'){
                        var old = location.hash();
                        location.hash(key);
                        flags.scrollError = true;
                        anchorScroll();
                        location.hash(old);
                    }
                }
            });
        }
    }

    function scrollToHasError(scope, callback) {
        var errorElement, i, val ;
        errorElement = document.querySelectorAll('.has-error');
        if(errorElement.length > 0){
            for (i in errorElement){
                val = errorElement[i];
            //angular.forEach(errorElement,function(val,key){
                if (val.offsetParent){
                    val.offsetParent.classList.add("error-section");
                }
            };
            errorElement[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            scope.flags.errorStatus = true;
        } else {
            scope.flags.errorStatus = false;
        }
        if(callback){callback();}
    }

    //Note: Properties order in objects is not guaranteed in JavaScript
    function reverseObject(object){
        var newObject = {};
        var keys = [];
        for(var key in object){
            keys.push(key);
        }
        for(var i = keys.length - 1 ; i >= 0 ; i--){
            var value = object[keys[i]];
            newObject[keys[i]] = value;
        }
        return newObject;
    }


    // scroll big tables on mobile, left/right arrows
    function mobileTableScroll(scope, direction, event){
        var element = event.target.parentNode.nextElementSibling;
        var width = scope.width - 50 / 2;
        if(direction === 'left'){
            element.scrollLeft -= width;
        } else{
            element.scrollLeft += width;
        }
    }

    // use these names to conditionally target specific screen sizes
    function screenSizeName(scope, old, newv){
        scope.mobile = false;
        scope.desktop = false;
        scope.tablet = false;
        scope.largeScreen = false;
        scope.xlScreen = false;
        scope.domainAdminFixedNav = false;
        if(newv && scope.width < 768){
            scope.mobile = true;
        } else if(newv >= 768 && newv <= 991){
            scope.tablet = true;
        } else if(newv >= 992 && newv <= 1199){
            scope.desktop = true;
        } else if(newv >= 1200 && newv <= 1999){
            // admin screen
            scope.largeScreen = true;
        } else if(newv >= 2000){scope.xlScreen = true;
        }
        if(newv >= 1620){scope.domainAdminFixedNav = true;
        }
    }

    function removeEmailErrClass(objId, objName){
        var idStr = [];
        // Even after deleting an invalid entry, <warning-a> element still contains the class 'has-error'.
        if(objName === 'email'){ idStr[0] = "warnEmail";}
        if(objName === 'phoneNumber'){ idStr[0] = "warnPhone";}
        if(objName === 'affiliation'){ idStr[0] = "warnInstn";}
        if(objName === 'securityQuestion'){ idStr[0] = "warnQuestion";idStr[1] = "warnAnswer";}
        for(var i=0; i<idStr.length; i++){
            var warnNode = document.getElementById(idStr[i]+objId);
            if( warnNode &&
                warnNode.hasChildNodes() &&
                warnNode.childElementCount === 1 &&
                warnNode.firstElementChild.classList.contains('has-error')
              ){
                warnNode.firstElementChild.classList.remove('has-error');
            }
        }
    }

    function removeNewDeletedObjs(fm, arrFldErr){
        var formNames, formName, i;
        formNames = [
            'accountEmails',
            'accountPhones',
            'accountAddresses',
            'accountInstitutions',
            'accountQuestions'
        ];
        for (i in formNames){
            formName = formNames[i];
            fm[formName] = fm[formName] && fm[formName].filter(function(row){
                var newDeletedRow = (row.id < 0 && row.status && row.status === 'Deleted');
                if(newDeletedRow){
                    delete arrFldErr[formName][row.id];
                }
                return (!newDeletedRow);
            });
        }
    }
    // remove 'ALL' search filter from api response
    function removeALLsearchFilter(resp){
        var respData = resp.data;
        var searchInfoList = respData.search_info_list.filter(function(item){
            return item.filter_name !== 'ALL';
        });
        if(searchInfoList.length > 0){
          delete respData.search_info_list;
          respData.search_info_list = searchInfoList;
        }
        return resp;
    }


    /// fixme janet, list of values for career levels from the backend
    function careerLevels(lists){
        lists.careerLevels = [
            {name : "Faculty"},
            {name : "Graduate Student"},
            {name : "Other"},
            {name : "Postdoctoral Research Associate"},
            {name : "Retired/Self Employed"},
            {name : "Undergraduate Student"},
            {name : "Research Scientist"},
            {name : "Professional Staff"},
        ]
    }

    function displayMemberStatus(userObj, acctStatus){
        var memberStatus = '';
        if(!userObj || (scope.cels && userObj.memberStatus === 'Deleted')){
            if(acctStatus === 'Active'){
                return;
            } else {
                memberStatus = (!scope.cels && acctStatus === 'Requested') ? 'Account not yet created' : 'Requires reactivation';
            }
        } else{
            if(userObj.memberStatus === 'Approved-pending MUA/Ack'){
                memberStatus = 'Approval pending';
            }else{
                if(userObj.memberStatus === 'Active'){
                    memberStatus = 'Already a member';
                }else{
                    memberStatus = userObj.memberStatus ;
                }
            }
        }
        return memberStatus;
    }

    function toDashCase(s){
        if (!s) return s;
        return replaceAll(s, ' ','-').toLowerCase();
    }

    function toCamel(s){
        var result, words, i, word;
        words = s.split('-');
        result = '';
        for(i in words){
            word = words[i];
            result = result + ((i == 0) ? word.toLowerCase() : toTitleCase(word));
        }
        return result;
    }

    function toTitleCase(str){
        return str.replace(
            /\w\S*/g,
            function(txt){
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    function loading(lbl, inc){
        var i;
        //ub3consoleLog(2062, scope);
        if (!scope.flags) { scope.flags = {};}

        if (typeof scope.flags.loading == 'undefined'){ // CELS things:
            scope.flags.loading = 0;
        }
        scope.flags.loading += inc;

        if (typeof scope.flags.apisLoading == 'undefined'){ // CELS things:
            scope.flags.apisLoading = [];
        }
        if (inc == 1){
            scope.flags.apisLoading.push(lbl);
        }
        if (inc == -1){
            i = scope.flags.apisLoading.indexOf(lbl);
            if (i >= 0){
                scope.flags.apisLoading.splice(i, 1);
            }
        }
    }

    function getDebugInfo(n){
        var s;
        //if (!scope.flags || !scope.flags.currBranch || !scope.flags.currBranch.form){
        //    return null;
        //}
        s = n + ' ' + JSON.stringify(scope.domainAdminDashboardAlerts);

        //s = 'Catalyst: ' + JSON.stringify(scope.flags.currBranch.form.catalyst);
        //s += ' form: '   + JSON.stringify(scope.flags.currBranch.form);
        return s;
    }
    function sendForm(path, params, target) {
        var localTest = (path.indexOf('anl.gov') < 0);
        /*
        if (localTest){
            path += "?";
            for (var key in params) {
                path += (key + '=' + encodeURIComponent(params[key]) + '&');
            }
            path = decodeURIComponent(path.slice(0, -1));
            if(!target) {target='_self';}
            window.open(path, target);  // why is this needed?
        }else{
        */
            var form = document.createElement('form');
            form.setAttribute('method', 'POST');
            form.setAttribute('action', path);

            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    var hiddenField = document.createElement('input');
                    hiddenField.setAttribute('type', 'hidden');
                    hiddenField.setAttribute('name', key);
                    hiddenField.setAttribute('value', params[key]);
                    form.appendChild(hiddenField);
                }
            }
            if (path.indexOf('/test/') >= 0){
                var csrf = cookies.get('portal_csrftoken');
                var h = document.createElement('input');
                h.setAttribute('type', 'hidden');
                h.setAttribute('name', 'csrfmiddlewaretoken');
                h.setAttribute('value', csrf);
                form.appendChild(h);
            }
            document.body.appendChild(form);

            form.submit();
        /*
        }
        */
    }

    function changeCallsPending(why, status, inc){

        if (why.includes('cors-anywhere.herokuapp.com')) return;

        status.httpCallsPending += inc;
        addToHistoryQueue(why, status.httpCallsPending, inc);
    }
    var history = null;
    function addToHistoryQueue(why, count, inc){
        if (!history){
            history = new Queue();
        }
        if (history.length() >= 100){
            history.dequeue();
        }
        var t = new Date().getTime();
        history.enqueue({time: t, why: why, count: count, inc: inc });
    }
    //
    // http is the original http service (from angular 1.7.2)
    //
    var ub3HttpObj;
    function ub3Http(http, status){
        status.httpCallsPending = 0;
        var stuff = {'content-type': 'application/json'} ;
        ub3HttpObj = {
            get:    function(path, params){
                                            if (!params) params = {params: null};
                                            return action(http.get   , 'get',    path, params);
                    },
            post:   function(path, params){ return action(http.post  , 'post',   path, params);},
            put:    function(path, params){ return action(http.put   , 'put',    path, params);},
            delete: function(path, params){ return action(http.delete, 'delete', path, params);},
            defaults: {headers: {get: null, post: stuff, put: stuff, delete: stuff}},
        }

        function action(request, reqName, path, params){
            var R, theSuccessFunction, theErrorFunction;

            http.defaults.headers[reqName] = ub3HttpObj.defaults.headers[reqName];

            R = {};
            R.then = function(f){
                RR = {};
                theSuccessFunction = f;

                RR.error = function(f){
                    theErrorFunction = f;
                };
                RR.catch = RR.error ;
                return RR;
            };
            R.success = R.then;         // back compatibility with older angular 1.5 ub3 .js files
            R.subscribe = R.then ;      // forward compatibility with angular 9+ ub3 .js .ts files

            theSuccessFunction = theErrorFunction = null;
            changeCallsPending(reqName +' '+ path, status, 1);        // for cypress
            request(path, params)               // <-- do the asynchronous call to the backend here
            .then(function(goodResp){
                theSuccessFunction(goodResp.data);
                changeCallsPending('back from ' + reqName +' '+ path, status, -1);        // for cypress
            })
            .catch(function(badResp){
                scope.currentLoginlocation = location.hash;
                if (theErrorFunction){
                        theErrorFunction(badResp);
                }else{
                        theSuccessFunction({success: false, error: badResp});
                }
                changeCallsPending('back from ' + reqName +' '+ path, status, -1);        // for cypress

            });
            return R;
        }

        return ub3HttpObj;
    }

    function getUsaStates(){
        return [
            {abr : "AL", name : "AL ALABAMA"}
            , {abr : "AK", name : "AK ALASKA"}
            , {abr : "AZ", name : "AZ ARIZONA"}
            , {abr : "AR", name : "AR ARKANSAS"}
            , {abr : "CA", name : "CA CALIFORNIA"}
            , {abr : "CO", name : "CO COLORADO"}
            , {abr : "CT", name : "CT CONNECTICUT"}
            , {abr : "DE", name : "DE DELAWARE"}
            , {abr : "FL", name : "FL FLORIDA"}
            , {abr : "GA", name : "GA GEORGIA"}
            , {abr : "HI", name : "HI HAWAII"}
            , {abr : "ID", name : "ID IDAHO"}
            , {abr : "IL", name : "IL ILLINOIS"}
            , {abr : "IN", name : "IN INDIANA"}
            , {abr : "IA", name : "IA IOWA"}
            , {abr : "KS", name : "KS KANSAS"}
            , {abr : "KY", name : "KY KENTUCKY"}
            , {abr : "LA", name : "LA LOUISIANA"}
            , {abr : "ME", name : "ME MAINE"}
            , {abr : "MD", name : "MD MARYLAND"}
            , {abr : "MA", name : "MA MASSACHUSETTS"}
            , {abr : "MI", name : "MI MICHIGAN"}
            , {abr : "MN", name : "MN MINNESOTA"}
            , {abr : "MS", name : "MS MISSISSIPPI"}
            , {abr : "MO", name : "MO MISSOURI"}
            , {abr : "MT", name : "MT MONTANA"}
            , {abr : "NE", name : "NE NEBRASKA"}
            , {abr : "NV", name : "NV NEVADA"}
            , {abr : "NH", name : "NH NEW HAMPSHIRE"}
            , {abr : "NJ", name : "NJ NEW JERSEY"}
            , {abr : "NM", name : "NM NEW MEXICO"}
            , {abr : "NY", name : "NY NEW YORK"}
            , {abr : "NC", name : "NC NORTH CAROLINA"}
            , {abr : "ND", name : "ND NORTH DAKOTA"}
            , {abr : "OH", name : "OH OHIO"}
            , {abr : "OK", name : "OK OKLAHOMA"}
            , {abr : "OR", name : "OR OREGON"}
            , {abr : "PA", name : "PA PENNSYLVANIA"}
            , {abr : "RI", name : "RI RHODE ISLAND"}
            , {abr : "SC", name : "SC SOUTH CAROLINA"}
            , {abr : "SD", name : "SD SOUTH DAKOTA"}
            , {abr : "TN", name : "TN TENNESSEE"}
            , {abr : "TX", name : "TX TEXAS"}
            , {abr : "UT", name : "UT UTAH"}
            , {abr : "VT", name : "VT VERMONT"}
            , {abr : "VA", name : "VA VIRGINIA"}
            , {abr : "WA", name : "WA WASHINGTON"}
            , {abr : "DC", name : "DC WASHINGTON, D.C."}
            , {abr : "WV", name : "WV WEST VIRGINIA"}
            , {abr : "WI", name : "WI WISCONSIN"}
            , {abr : "WY", name : "WY WYOMING"}
        ];
    }

    //-------------------------------------------------------
    //--------- QUEUE definition ----------------------------
    function Queue() { this.elements = []; }
    Queue.prototype.enqueue = function(e) { this.elements.push(e); };
    Queue.prototype.dequeue = function()  { return this.elements.shift(); };
    Queue.prototype.length  = function()  { return this.elements.length; }
    Queue.prototype.list    = function()  { return this.elements; }
    //--------- end of: QUEUE definition ----------------------------
    //-------------------------------------------------------

    function unsavedEditForm(){
        var complain, cb;
        complain = (scope.flags.delta || scope.flags.pjaOkToSave);
        complain = complain || ((cb = scope.flags.currBranch) && cb.state && (cb.state.delta || cb.state.okToSave)) ;
        if(complain){
           scope.alertUnsavedChanges();
        }
        return complain;
    }
    function getUserRole (callback) {
        http.get('/public/whoami')
            .success(function(resp){
                if(resp){
                    callback(resp.role);
                }
            })
    }

    function ub3consoleLog(a,b) {
      var h = window.location.hostname;
      if (h != 'localhost') return; // function should not do anything in production

      if (!b && (b != false)){
          console.log('x ' + a, b); // do not comment out this line.  It would defeat the purpose of this function
          return;
      }
      var x1 = JSON.stringify(b, replacer);
      var x2 = JSON.parse(x1);
      console.log(a, x2);       // do not comment out this line.  It would defeat the purpose of this function

      function replacer(k,v){
          return (typeof v == 'undefined') ? 'undefined' : v;
      }
    }

    // get the menu items depending on the access rights of the logged in user.
    function getMenuItems(scope){
        var availMenu = [];
        var menuObj = JSON.parse(scope.menuPaths);
        menuObj && Object.keys(menuObj).forEach(function(key){
            var k = menuObj[key];
            if(k && typeof k === 'string' && k.startsWith('r ')){
                var item = k.substring(2);
                if(item.length > 0) availMenu.push(item);
            }
        });
        // if you're a domain admin user, we need the child menu items of Administraion page also in the availMenu array.
        if(availMenu.length > 0 && scope.user){
            if(scope.user.role === 'domainAdmin'){
                // If you write two or more arrays prefixed with the spread operator (...) inside the array literal, JavaScript will create an array with all these arrays merged.
                availMenu = [...availMenu, ...editAdmPages, ...editPiproxyPages];
            }
            if(scope.user.role === 'piproxy'){
                availMenu = [...availMenu, ...editPiproxyPages];
            }
        }
        return availMenu;
    }


    // Angular 2 + . Specific functions ---------------------------------------------

    // convert Angular.js menu structure to Angular menu structure
    function convertMenu(items, user, state){
        // from this:   {label : 'label',      roles : d, homepage : false,   path : '#/domainAdmin', init : scope.initDomainAdmin}
        // to this:     {    t : 'label', io: 'o', r : d,                         f:'r1 accountReactivate'}
        // t = title,
        // io = if this menu item should show up when signed in (i), or singed out (o)
        // r = the roles that can access this menu item
        // f = the parameter to be processed by callFunc above
        var obj, route, menu;

        obj = JSON.parse(JSON.stringify(items));
        //ub3consoleLog(148, obj);
        traverseMenu(obj, function(item){
            item.t = item.label;
            item.hid = mkId(item.label);    // fix for Cypress

            if (item.roles == '*'){
                    item.io = '*';
            }else{
            if (item.roles.length == 1) {
                    item.io = (item.roles[0] == 'public')
                            ? 'o'
                            : 'i';
            }else{  // more than one role
                    if (item.roles.indexOf('public') >= 0){
                            item.io = '*';
                    }else{
                            item.io = 'i';
                    }
            }
            }

            item.r = item.roles;
            if (item.path.startsWith('/frontend/index-ng.html?')){   // for Angular
                route = item.path.replace('/frontend/index-ng.html?', '');
                route = route.replace('page=','');
                item.f = 'r ' + route;
            }else{
                if (item.cryptocard){
                    item.f = 're https://docs.alcf.anl.gov/account-project-management/accounts-and-access/alcf-passcode-tokens/';
                }else{                                              // for Angular 1
                    route = item.path.replace('/#!/','');
                    if (route == '/#!') route = '/';
                    item.f = (route != '') ? 'r1 ' + route  : '' ;
                }
            }
            delete item['label'];
            delete item['roles'];
            delete item['path'];
            delete item['init'];
        });
        menu = trimMenu(obj, user);
        //ub3consoleLog(188, menu);
        obj = {ng: 10};
        traverseMenu(menu, function(item){
            obj[toDashCase(item.t)] = item.f;
        });
        state.menuPaths = JSON.stringify(obj); // so that Cypress is able to know how to navigate the menu
        return menu;
    }


    // this function is not used, it is here for illustration purposes
    // so we can see how the menu definition should look after we
    // are done porting every screen to angular 10
    // This function will start getting used immediately after 3.2.7.2 release
    function defineMenuALCF(user){

        var d       = ['domainAdmin'];
        var pl      = ['public','limited'];
        var pd      = ['piproxy', 'domainAdmin'];
        var updo    = ['user', 'piproxy', 'domainAdmin', 'owner'];
        var cryptoLink = 'https://docs.alcf.anl.gov/account-project-management/accounts-and-access/alcf-passcode-tokens/';

        var menuItems =[
            {t:'Home'                         ,io: '*', r: '*' , f:'r home'},
            {t:'Request an Account'           ,io: 'o', r: pl  , f:'r accountRequest'},
            {t:'Reactivate an Account'        ,io: 'o', r: pl  , f:'r accountReactivate'},
            {t:'Allocation Request'           ,io: 'o', r: pl  , f:'r  allocationRequest'},
            {t:'ALCF Passcode Token Help'     ,io: 'o', r: pl  , f:'re ' + cryptoLink},         // re goes to an external address
            {t:'Account Management'           ,io: 'i', r: updo, f:''                   , sub:[
            {t:    'Update My Account'        ,io: 'i', r: updo, f:'r accountUpdate'}        ]},
            {t:'Project & Resource Managament',io: 'i', r: updo, f:'',                    sub:[
            {t:    'Project Management'       ,io: 'i', r: pd  , f:'r manageProjects'},
            {t:    'Join project'             ,io: 'i', r: updo, f:'r joinProject'},
            {t:    'Request and view systems' ,io: 'i', r: updo, f:'r1 manageResources'},       // r1 goes to angular 1
            {t:    'Manage UNIX Groups'       ,io: 'i', r: updo, f:'r manageUnixGroups'},
            {t:    'Request an Allocation'    ,io: 'i', r: updo, f:'r allocationRequest'}    ]},
            {t:'Administration'               ,io: 'i', r: d   , f:'',                    sub:[
            {t:    'User Administration'      ,io: 'i', r: d   , f:'r domainAdmin'},
            {t:    'Create Other Accounts'    ,io: 'i', r: d   , f:'r createOtherAccounts'},
            {t:    'Project Administration'   ,io: 'i', r: d   , f:'r projectsAdmin'},
            {t:    'Identity Merge'           ,io: 'i', r: d   , f:'r identityMerge'},
            {t:    'Institutions'             ,io: 'i', r: d   , f:'r institutions'},
            {t:    'Logs'                     ,io: 'i', r: d   , f:'r logsAdmin'},
            {t:    'Plugins'                  ,io: 'i', r: d   , f:'r pluginsAdmin'},
            {t:    'API Developer Interface'  ,io: 'i', r: d   , f:'r1 apiDevInt'},             // r1 goes to angular 1
            {t:    'Other Screens'            ,io: 'i', r: d   , f:'r svcApi'}                ]},
        ];
        return trimMenu(menuItems, user);
    }

    // takes out menu items that don't apply because of the specific user role
    function trimMenu(items, user){
        var item, i, obj ;
        obj = JSON.parse(JSON.stringify(items));

        traverseMenu(obj, function(item){
            item.disp = false;
            if (item.t.includes('%name%')){
                item.t = user.givenName;
            }
        });

        // now mark the good ones with display
        traverseMenu(obj, function(item){
            if (user.signedIn){
                if ((item.r=='*' || (item.r.indexOf(user.role) >= 0)) && (item.io=='i' || item.io=='*')){
                    item.disp = true;
                }
            }else{
                if ((item.r=='*' || (item.r.indexOf(user.role) >= 0)) && (item.io=='o' || item.io=='*')){
                    item.disp = true;
                }
            }
        });

        // now remove the ones to not be displayed
        i = obj.length;
        while (i--) {
            item = obj[i];
            if (!item.disp) {
                obj.splice(i, 1);
            }
        }

        return obj;
    }

    function addSystemMessage(systemMessage){
        if(systemMessage){
            document.getElementById("systemMessage").innerHTML = systemMessage;
        }
    }
    function isAdminPg(){
        var currPg = window.location.hash.replace('#','').split('?')[0].substring(1);
        return (admPages.includes(currPg));
    }
    // END OF: Angular 2 + . Specific functions ---------------------------------------------



    // ----------- public stuff gets returned -----------
    return publicStuff;

})();

// Globals Constants.
// Available to all modules without having to say commonModule.GC
//
var GC = {

    // branch types
    USER          : 'user',
    USERS         : 'users',
    SYSTEM        : 'system',
    SYSTEMS       : 'systems',
    NETGROUP      : 'netGroup',
    NETGROUPS     : 'netGroups',
    UNIXGROUP     : 'unixGroup',
    UNIXGROUPS    : 'unixGroups',
    ALL           : '?search=%25',
    DELETE_STATUS : 'Deleted',
    ACTIVE_STATUS : 'Active',

    // for use with the Tree Explorers. Keyed by branch type
    tree: {
        user:       {name: 'User',         api: '/svc/Account',   bridgeApi: '/svc/AccountNetGroup', parentType: 'users'},
        users:      {name: 'Users',        api: '/svc/Account',   bridgeApi: '/svc/AccountNetGroup'},
        system:     {name: 'System',       api: '/svc/System',    bridgeApi: '/svc/NetGroupSystem',  parentType: 'systems'},
        systems:    {name: 'Systems',      api: '/svc/System',    bridgeApi: '/svc/NetGroupSystem'},
        netGroup:   {name: 'Net Group',    api: '/svc/NetGroup',  bridgeApi: '/svc/ProjectNetGroup', parentType: 'netGroups'},
        netGroups:  {name: 'Net Groups',   api: '/svc/NetGroup',  bridgeApi: '/svc/ProjectNetGroup'},
        unixGroup:  {name: 'Unix Group',   api: '/svc/UnixGroup', bridgeApi: '/svc/ProjectUnixGroup',parentType: 'unixGroups'},
        unixGroups: {name: 'Unix Groups',  api: '/svc/UnixGroup', bridgeApi: '/svc/ProjectUnixGroup'},

        // Sheeja uses this
    },
};

module.exports = {commonModule, GC};
/*if ( (typeof module) != 'undefined'){
    // we are in angular 2+
    var c = "module.exports = {commonModule, GC}" ;
    eval(c);
}*/
