
<div class="homepage">
    <div class="home-body">
        <div class="home-login" >
            <div class="login-modal">
                <div class="modal-header" [ngClass]="{'login':!state.user.signedIn}">
                    <h3 class="modal-title" id="modal-title">
                        Account Log In
                    </h3>
                </div>


                <div class="modal-body modal-sign-in">
                    <div *ngIf="!state.user.signedIn">

                        <div class="form-group floating-label-wrapper">
                            <label for="username"></label>
                            <input style="visibility: hidden; position: absolute; left:-99999px" type="username" id="username" name="usernamehidden">
                            <input type="text" class="form-control inBox"
                                name="username" id="mUserName"
                                placeholder=" "
                                [(ngModel)]="form.userName"
                                autocomplete="nope"
                                (change)="flags.callHelpDesk = false;"
                                (keyup)="signInOnEnter($event, form.userName, form.secretName)">
                            <label for="mUserName" class="inputLbl">Username</label>
                        </div>
                        <warning fid="userName"></warning>
                        <div class="helpdesk-link"><a (click)="flags.callHelpDesk = true;flags.helpReqd = 'username'">Forgot username?</a></div>
                        <div class="form-group floating-label-wrapper">
                            <label for="password"></label>
                            <input style="visibility: hidden; position: absolute; left:-99999px" type="password" name="fakepasswordfieldtoturnoffautocomplete">
                            <input type="password" class="form-control inBox"
                                id="mSecretName"
                                placeholder=" "
                                [(ngModel)]="form.secretName"
                                autocomplete="nope"
                                (change)="flags.callHelpDesk = false;"
                                (keyup)="signInOnEnter($event, form.userName, form.secretName)">
                            <label id="lbl1" for="mSecretName" class="inputLbl">CRYPTOCard Passcode</label>
                        </div>
                        <warning fid="password"></warning>
                        <div class="helpdesk-link"><a (click)="flags.callHelpDesk = true;flags.helpReqd = 'password'">Token issues?</a></div>
                    </div>
                </div>

                <!-- <div class="g-recaptcha" data-sitekey="6LeXlVUUAAAAAOl_2Qfwg6dO6GILfMAmV2VqBK4y"></div>-->

                <div class="modal-footer">
                    <div>
                        <button id="signInButton" *ngIf="state.user.signedIn" type="button" class="btn btn-default" (click)="cancel()" >
                            {{state.user.signedIn?'Close':'NO'}}
                        </button>

                        <button *ngIf="!state.user.signedIn" type=submit class="btn btn-success btn-lg"
                                id="loginButton"
                                (click)="signInOnEnter({which: 13}, form.userName, form.secretName)">
                            LOGIN
                        </button>

                    </div>
                </div>
                <div *ngIf="accessDenied || flags.callHelpDesk" class="modal-body helpDeskInfo">
                    <div *ngIf="accessDenied">
                        <div class="mb10">
                            <font color=red id="popAuthFailedError">
                                Authentication failed. Please try again.
                            </font>
                        </div>
                        <div>
                            If you continue to experience problems logging in, please contact <a href="mailto:{{domainGlobals.helpDeskEmail}}" [textContent]="domainGlobals.helpDeskEmail" class="helpLink"></a>
                        </div>
                    </div>
                    <div *ngIf="flags.callHelpDesk && !accessDenied">
                        <div *ngIf="flags.helpReqd === 'username'">
                            <div class="mb10">
                                <font color=red id="forgotUnameHelp">
                                    Forgot username?
                                </font>
                            </div>
                            <div class="mb10">Please contact us at <a href="mailto:{{domainGlobals.helpDeskEmail}}" [textContent]="domainGlobals.helpDeskEmail" class="helpLink"></a></div>
                            <div>If you are a returning user, please do not create a new account.</div>
                        </div>
                        <div *ngIf="flags.helpReqd === 'password'">
                            <div class="mb10">
                                <font color=red id="tokenHelp">
                                    Token issues?
                                </font>
                            </div>
                            <div class="mb10">If you can log in to ALCF systems but cannot log in to your account, please retry your passcode.</div>
                            <div class="mb10">Please see <a href="{{ALCFPasscodeTokensURL}}"  class="helpLink"> token help </a> to troubleshoot token issues.</div>
                            <div>Otherwise contact <a href="mailto:{{domainGlobals.helpDeskEmail}}" [textContent]="domainGlobals.helpDeskEmail" class="helpLink"></a>.</div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
