
import { Component, OnInit, Input } from '@angular/core';
import {CommonService } from '@common/services';

// This component implements
//  sorting
//  filter search
//  paging

var tableCache = {};    // dictionary indexed by table name , stores pages and settings, but not the original array
var tableFeatures = {}; // dictionary of pointers to all column tableFeatures in all tables
var comMod = null;
var clog = null;

@Component({
  selector   : 'ub3tbl',
  templateUrl: './ub3tbl.component.html',
  styleUrls  : ['./ub3tbl.component.css']
})
export class Ub3tblComponent implements OnInit {

  @Input() name: string;    // table name, so it can be used in the cache
  @Input() opt: string;     // what feature to add to the table
  @Input() titles: any;     // an array with description of id & labels
                            //      titles also gets overloaded with two more values:
                            //      dir & filter
  @Input() rows: any;       // allways points to original in parent page
  @Input() rowsOut: any;    // this is the dynamic table, changes depending on page or filter
  @Input() title: string;
  @Input() onDonePaging: any;       // a function to be called after the pages contents are split
  @Input() outTrigger: any;         // a function to be called after this component does filter or page split operations
  @Input() maxPerPage: number;      // for the paging footer
  showFilter = false;
  filtDisplay = 'show';
  filter = '';
  pages = [];
  currentPage = 0;
  numCols = 3;
  totRows = 0;
  rangeLabel:string = '';
  totFilteredRows = 0;
  filtered = false;
  pgStart = 0;
  pgEnd = 0;
  filteredLen = 0;
  hto = null;   // header title object.  When instatiating a paging object,  hto points to the titles header object

  // rowsOut will be replaced inside this component.  
  // Angular takes care of the repainting of the screen
  // the original contents of rows will be stored in a cache, which resides inside this component

  constructor(private commonService: CommonService) { 
        comMod = this.commonService;
        clog = comMod.ub3consoleLog;
  }



  ngOnInit(): void {
      var tc = null;
      var t = null;

      if (! tableFeatures[this.name]){
        tableFeatures[this.name] = {};
      }
      t = tableFeatures[this.name][this.opt] = this;  // set pointer to instantiation of this class

      if (t.opt && (t.opt == 'paging')){
        // there is no rows object pointer.  Must be the paging object
        // find the one which has the rows pointer
        traverseFeatures(t.name, 'titles', function(f){
            t.rows     = f.rows;    // now the paging object has a rows pointer
            t.rowsOut  = f.rowsOut;    // now the paging object has a rows pointer
            t.hto = f;   // point to header titles object
        });
      }
      t.totRows = t.rows ? t.rows.length : 0;
      
      if (! getFromCache(t.name)){
        saveRowsToCache(t.rows, t.name);
      }
      

      if (t.opt && (t.opt == 'titles')){
        var tbl, frn, top,numCols, i, filtRow;

        tbl  = document.getElementById(t.name);
        if (!tbl){
            clog('you need to add id="' +t.name+ '" to the parent table');
            return;
        }
        tbl.style.visibility = 'hidden';
        frn = t.name + '-1st-row';   // first row name, a dom id
        filtRow = t.name + '-filter-row';
        top = t.name + '-topselector';
        numCols = t.titles.length;

        //set up sort options.  Default is true
        var oneColTitle = null;
        
        for (i in t.titles){
            oneColTitle = t.titles[i] ;
            
            if (oneColTitle.sort == false){
                // leave it as is
            }else{
                oneColTitle['sort'] = true;    // default to true
            }
        }
        //set up filter options.  Default is true
        for (i in t.titles){
            if (t.titles[i].doFilter != false){
                t.titles[i].doFilter = true;
            }
        }

        moveTrObject();
        function moveTrObject(){
            var row1 = document.getElementById(frn);
            var filterRow = document.getElementById(filtRow);
            var rowtop = document.getElementById(top);
            var parent = row1 ? row1.parentNode : null;
            var specialTd   = document.getElementById('make-this-td-wide');

            if (parent && filterRow && rowtop && specialTd){

                // run code below after parent is available
                parent.removeChild(row1);   // remove the <tr> from its <ub3tbl> parent
                parent.removeChild(filterRow);
                parent.removeChild(rowtop);
                
                tbl.prepend(filterRow);
                tbl.prepend(row1);    // now add that <tr> directly to the table
                tbl.prepend(rowtop); 
                tbl.style.visibility = 'visible';
                specialTd.setAttribute("colSpan", numCols + '');
            }else{
                setTimeout(moveTrObject, 100);
            }
        }
      }

      if (t.opt && (t.opt == 'paging')){
        tc = tableCache[t.name];
        tc.maxPerPage = Number(t.maxPerPage);
      }

  }
  // a call to this gets triggered for each column header(and footer) in the table
  // when anything changes in the @Input parameters.
  // 
  ngOnChanges(changes){
    var prev, curr, t;
    t = this;
    if (!changes.rows) return;        // ignore all changes except the rows

    comMod.onStopCalling(700, function(){

        prev = changes.rows.previousValue;
        curr = changes.rows.currentValue;
        if (prev != curr){
            t.totRows = t.rows.length;
            // go and set totRows in the paging objects too
            traverseFeatures(t.name, 'paging', function(f){
                f.totRows = t.rows.length;
            });
            saveRowsToCache(t.rows, t.name);
            splitPages(t.name, t.rows); // renumber and calculate pages when search string changes
            if (t.outTrigger) {
                var orig = getFromCache(t.name);   // original array
                t.outTrigger(orig.length, orig.length);
            }
            t.goToPage(0);
            if (t.onDonePaging) t.onDonePaging();
        }
    });
  }


  sortToggle(id){
    var t, i, colInfo, obj;
    t = this;

    colInfo = findColInfo(t.titles, id);

    //save the sort direction from this column
    var csort = colInfo.dir;

    // find all other sorting settings for this table and turn the sorting to none
    resetSorts(t.titles, id);

    // first apply current filter, if any
    var colToFilter = id; // default to id column, even if blank filter in this column
    for (i in t.titles){
        obj = t.titles[i];
        if (obj.filter){
            colToFilter = obj.id;
        }
    }
    if (colToFilter){ // there is a filter that needs to be applied
      t.applyFilterNow(colToFilter);
    }

    // csort has the original the previous direction
    // do the toggle now
    colInfo.dir = ((csort == 'none') || (csort == 'down')) ? 'up' : 'down';

    // now do the sorting on the current table
    var sortedArray = t.commonService.sortArrayOnKey(t.rows, id, colInfo.dir == 'down');
    if (sortedArray.length == t.rows.length){

      comMod.arrayCopyPointers(sortedArray, t.rowsOut);    // from left to right

      splitPages(t.name, t.rowsOut);
      t.goToPage(0);
    }
  }

  applyFilter(id){
      var t = this;
      comMod.onStopCalling(700, function(){
        t.applyFilterNow(id);

        // also redo the paging settings
        splitPages(t.name, t.rowsOut); 
        t.goToPage(0, true);
      });
  }
  applyFilterNow(id){
      var t = this;
      var i, result, row, orig, str, filter, colInfo;

      colInfo = findColInfo(t.titles, id);
      filter = colInfo.filter;
      //orig = getFromCache(t.name);   // original array
      orig = t.rows;   // original array

      if (!filter || (filter == '')){   // user just erased the filter string, or there was never a filter
        //restore the array to its original state
        comMod.arrayCopyPointers(orig, t.rowsOut);
        t.totFilteredRows = 0;
        if (t.outTrigger) t.outTrigger(orig.length, t.rowsOut.length);
        return;
      }

      resetFilters(t.titles, id);    // erase all other filters
      resetSorts(t.titles, 'none');     // also erase all sorting options

      // apply filter string to the original array
      result = []; var count = 0;
      for (i in orig){
        row = orig[i];
        str = JSON.stringify(row[id]);
        if (str.toLowerCase().indexOf(filter.toLowerCase()) >= 0){
            result.push({});
            result[count] = row;    // only copy pointer, retain original object
            count ++;
        }
      }
      var temp = t.rowsOut;
      comMod.arrayCopyPointers(result, t.rowsOut);
      t.totFilteredRows = t.rowsOut.length;
      if (t.outTrigger) t.outTrigger(orig.length, t.rowsOut.length);
  }
  goToPage(i, filtered){
    if (this.opt == 'paging'){
        this.goToPageR(i, filtered);  // call the one from this instantiation
    }else{
        var obj = null;
        traverseFeatures(this.name, 'paging', function(f){
            obj = f;
        });
        if (obj){
            obj.goToPageR(i, filtered);   // call the one from a different instantiation
        }
    }
  }
  goToPageR(i, filtered){ // the real one
    var a = this;
    var tobj = a.hto;    // a is pointer to paging object, tobj is pointer to the titles-header object
    var idx = Number(i);
    var tc = tableCache[this.name];
    
    if (!tc.pages || (tc.pages.length <= 1)){
        traverseFeatures(this.name, 'paging', function(f){
            f.currentPage = 0;
            f.pages = null;
        });
        
        // always replace rowsOut, except when there was a filter, in that case rowsOut has already been set
        if (!tobj.totFilteredRows && !filtered){
            comMod.arrayCopyPointers(tobj.rows, tobj.rowsOut);

            //testArrays('288 rows vs rowsOut', tobj.rows, tobj.rowsOut);
        }
        //testArrays('290 rows vs rowsOut', tobj.rows, tobj.rowsOut);
        return;
    }
    //testArrays('293 rows vs rowsOut', tobj.rows, tobj.rowsOut);
    comMod.arrayCopyPointers(tc.pages[idx], tobj.rowsOut);
    //testArrays('295 rows vs rowsOut', tobj.rows, tobj.rowsOut);

    tc.currentPage = idx;
    //set the page number into the correct objects
    // note that there might be two or more rows in the table with paging controls
    // for example, one at top and one at bottom
    traverseFeatures(this.name, 'paging', function(f){
        f.currentPage = idx;
    });

    a.totFilteredRows = tobj.totFilteredRows;
    a.filtered = (a.totFilteredRows && (a.totFilteredRows < a.totRows))
    a.pgStart = (a.currentPage * a.maxPerPage)+1 ;
    a.pgEnd  =  (a.currentPage * a.maxPerPage) + a.rowsOut.length;

  }
  showOrHideFilters(){
    this.showFilter = !this.showFilter;
    this.filtDisplay = (this.showFilter) ? 'hide' : 'show';
  }
}

// ----------------------------------------------------------------------------------
// functions below are helper functions.  
// They are outside of the class above because they are not called directly from the template

function testArrays(label, a,b){
       // run test to see if the pointers actually retain the original objects
       if (a[0] && a[0].affiliations && b[0] && b[0].affiliations){
           b[0].affiliations[0].extraJunk = 'crap';
           var same = (a[0].affiliations[0].extraJunk == 'crap');
       } 
}

function findColInfo(titlesObj, id){
    var i;
    for (i in titlesObj){
        if (titlesObj[i].id == id) return titlesObj[i];
    }
    return null;
}    

function resetSorts(colsInfo, id){
    var i, obj;
    for (i in colsInfo){
        obj = colsInfo[i];
        if (id != obj.id){
            obj.dir = 'none';
        }
    }
}
function resetFilters(colsInfo, id){
    var i, obj;
    for (i in colsInfo){
        obj = colsInfo[i];
        if (id != obj.id){
            obj.filter = '';
        }
    }
}

// find all feature objects of type kind for this table 
// Note that this is accessing other instantiations of this class
function traverseFeatures(name, kind, cb){
    var featName, obj;
    for (featName in tableFeatures[name]){
        obj = tableFeatures[name][featName];
        if (obj.opt == kind ){
            cb(obj);
        }
    }
}

function getFromCache(name){
    if (!tableCache[name]) return null;
    return tableCache[name].arr;
}

function saveRowsToCache(tbl, name){
    if (!tableCache[name]) tableCache[name] = {};
    var tc = tableCache[name];
    tc.arr = tbl;   // copy the pointer to the array only
    //comMod.arrayCopy(tbl, tableCache[name].arr);
}

// this function does not modify rows. It modifies the pages portion of the cache
function splitPages(name, rows){
    var tc = tableCache[name];
    if (!tc.maxPerPage) return;
    if (rows && (rows.length > tc.maxPerPage)){
        
        var chunks = sliceIntoChunks(rows, tc.maxPerPage);
        traverseFeatures(name, 'paging', function(f){
            f.pages = tc.pages = chunks;
        });
    }else{
        tc.pages = null;
    }
}
// this function came from : https://stackabuse.com/how-to-split-an-array-into-even-chunks-in-javascript/
function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}
