
var domainAdminModule =
    (function(){
        "use strict";
        var publicStuff;
        var alertError = {type : 'danger'};
        var alertSuccess = {type : 'success'};
        var alerts;
        var cookies , http , httpLegacy , scope , timeout , win;
        cookies = http = httpLegacy = scope = timeout = win = null;  // cached services
        var jsServices = {};
        var comMod = null;
        var valMod = null;
        var clog = null;
        var edRecMod = null;
        var otherAccMod = null;
        var originals = {};     // keeps track of previous values in arrays

        publicStuff = {
            init : init,
            addOtherAccount : addOtherAccount,
            addFilterName : addFilterName,
            adminSearchUser : adminSearchUser,
            adminSearchUserNow : adminSearchUserNow,
            editOtherAccount : editOtherAccount,
            editUnixSystem : editUnixSystem,
            favorSync : favorSync,
            filterByUserName : filterByUserName,
            getApiLists : getApiLists,
            get4reqTypes : get4reqTypes,
            getPjMemReqs: getPjMemReqs,
            getRequests : getRequests,
            getSystemsList : getSystemsList,
            getUserInfo : getUserInfo,
            membershipReminder : membershipReminder,
            projectOption : projectOption,
            requestAction : requestAction,
            refreshUserAdminListData: refreshUserAdminListData,
            saveRecord : saveRecord,
            selectOption : selectOption,
            sendMailToAdmin : sendMailToAdmin,
            setServices : setServices,
            setDisplayName: setDisplayName,
            systemRequests : systemRequests,
            userHasRequest : userHasRequest,
            updProjectAccountBridgeMemberStatus: updProjectAccountBridgeMemberStatus,
            validate : validate,
        };

        function setServices(services){
            var i, serv;
            for (i in services){
              serv = services[i];
              jsServices[serv.sname] = serv;
            }    
            cookies = jsServices.cookies;
            http    = jsServices.httpNew;
            httpLegacy = jsServices.http;
            scope   = jsServices.scope;
            timeout = jsServices.timeoutObj.timeout;
            win     = jsServices.window;

            comMod = jsServices.commonModule;
            valMod = jsServices.validationModule;
            edRecMod = jsServices.editRecordModule;
            otherAccMod = jsServices.otherAccountsModule;
            clog = comMod.ub3consoleLog;
        }

        function init(s, pageName, q){
            scope = s;
            comMod.publicPage(false);
            alerts = [];
            if(!scope.flags){
                scope.flags = {};
            }
            if(!scope.lists){
                scope.lists = {};
            }
            scope.lists.userRecords = [];

            scope.flags = {
                hideHomePage : true,
                domainAdmin : true,
                adminPageLevel : 4,
                level : 4,
                prevSavedForm : null,
                fieldErrors : {},
                delta : false,
                submit : null,
                altEmail : null,
                showSubmit : null,
                matchedName : null,
                index : null,
                requestType : null,
                hideFormSection : null,
                createOtherAccount : false,
                missing : null,
                viewDetails : null,
                changedIdentity : false,
                newServiceAcct : false,
                admin_testing : false,
                //fullWidth : true,
                other_account_testing : false,
                hideDeleteQA : false,
                bootstrapAlert : 'getRequestsError',
                editRecord : false
            };
            scope.adminAlerts = [];

            if(!scope.form){
                scope.form = {};
            }
            if(!scope.sharedLists){
                scope.sharedLists = {};
            }
            getApiLists(http, scope.lists);
            getRequests(scope.lists, q, alerts, function(){
                scope.acReqCount = scope.lists.accountRequests.length;
                systemRequests(scope.lists, alerts);
            });

            if(!scope.lists.countries){
                comMod.getCountries(scope.lists);
            }

            if(!scope.lists.systems){
                getSystemsList(http, scope.flags, scope.lists, alerts);
            }

            scope.flags.displayedPageName = pageName;
            scope.flags.showDomainAdminDashboard = true;

            var hash = window.location.hash;
            if (hash.indexOf('?edit=') >= 0){
                // we just got redirected from angular 2+, 
                // to edit a specific User Account
                var usrName = hash.split('?')[1].split('=')[1];
                scope.flags.bootstrapAlert = null;
                scope.adminEditRecord(usrName);
            }
        }

        function validate(http, timeout, form, name, flags, alerts, scope){
            flags.fieldErrors = {};
            flags.submit = false;
            flags.delta = false;
            flags.okToSubmit = false;

            valMod.validate('domainAdmin', form, flags.fieldErrors);
            if(!flags.fieldErrors.searchName){
                flags.searchName = true;

            } else{
                flags.searchName = false;
            }

            if(name === 'email'){
                var e = flags.fieldErrors.email;
                flags.emailMatched = false;
                var validEmail = valMod.validEmailFormat(form.email);
                if(!validEmail){
                    flags.fieldErrors.email = {};
                    flags.fieldErrors.email.error = "Enter a valid Email format";
                    if(form.accountCategory !== 'System') {
                        form.firstName = form.lastName = form.preferredName = null;
                    }
                }
                if(validEmail){
                    // we have a good email format
                    comMod.onStopCalling(700, function(){
                        var p = {email : form.email};
                        http.get('/admin/searchEmail', {params : p})
                            .then(function(response){
                                var resp = response.data;
                                var errorMsg = 'Email Match Not Found';
                                flags.changedIdentity = false;
                                if(resp.success){
                                    if(resp.match && form.accountCategory !== 'System'){
                                        form.firstName = resp.data.firstName;
                                        form.lastName = resp.data.lastName;
                                        form.identity_id = resp.data.identity_id;
                                        form.preferredName = resp.data.preferredName;
                                        flags.emailMatched = resp.match;
                                        alertSuccess.msg = 'Email Match Found';
                                        alerts.push(alertSuccess);
                                        // call validate again as we now have required form fields from email identity
                                        timeout(function(){
                                            // scope.validateAccountUpdate();
                                            edRecMod.validateAccountUpdate(form, flags, true);
                                        }, 700);
                                    } else if(!resp.match && form.accountCategory === 'System'){
                                        alertSuccess.msg = 'Email is valid to create system account';
                                        alerts.push(alertSuccess);

                                    } else{

                                        if(resp.match && form.accountCategory === 'System'){
                                            errorMsg = 'Account exists already with this Email';
                                        }

                                        flags.emailMatched = resp.match;
                                        // no match for email, clear previous user
                                        form.firstName = form.lastName = form.preferredName = null;
                                        alertError.msg = errorMsg;
                                        alerts.push(alertError);

                                    }

                                }
                            }).catch(function(response, status){
                            alertError.msg = response.data.detail;
                            alerts.push(alertError);
                        });
                    });
                }
            } else{
                flags.missing = valMod.computeMissing(flags.fieldErrors);
                flags.okToSubmit = !flags.missing;
                // now see if there are any changes to be saved
                flags.delta = false;
            }
        }

        function getPjMemReqs(cb){
            http.get("/admin/projectMembershipRequests").then(function(resp){
                // loop and find the desired row
                if (!resp.data) {cb(null); return;}
                if (!resp.data.projectMembershipRequests) {cb(null); return;}

                cb(resp.data.projectMembershipRequests);
            });
        }

        function get4reqTypes(lists, q, alerts, cb){
            lists.loadingUserRequests = true;
            var allUserRequests = ['projectMembership', 'account', 'reactivation'];
            var promises = allUserRequests.map(function(apiName){
                return http.get("/admin/" + apiName + "Requests");
            });
            return q.all(promises)
                .then(function(data){
                    data.forEach(function(response){
                        var resp = response.data;
                        if(resp.success){
                            Object.keys(resp).forEach(function(key){
                                lists[key] = resp[key];
                                addFilterName(lists[key]);
                            });
                        } else{
                            alertError.msg = resp.error + ' on api ' + response.config.url;
                            alerts.push(alertError);
                        }
                    });
                    lists.domainAdminData = true;
                }).catch(function(response, status){
                    lists.domainAdminData = false;
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                }).finally(function(){
                    set_ms_state_change(lists.accountRequests);
                    set_ms_state_change(lists.reactivationRequests);
                    setDisplayName(lists.accountRequests);
                    setDisplayName(lists.projectMembershipRequests);
                    setDisplayName(lists.reactivationRequests);
                    allUserRequests.map(function(apiName){
                        var apiReq = apiName+'Requests';
                        var tabName = getTableNameFromList(apiName);
                        originals[tabName] = comMod.objCopy(lists[apiReq]);
                    });
                    if(cb){
                        cb();
                    }
                    lists.loadingUserRequests = false;
                });

        }
        function getRequests(lists, q, alerts, callback){
            get4reqTypes(lists, q, alerts, function(){
                if (callback){
                        if(scope.form.searchName){
                            // this means User Records table is displayed on screen
                            // call search api again with same form value to refresh that table data
                            scope.adminSearchUser(scope.form.searchName);
                        }
                        callback();
                }
            });
        }

        function getTableNameFromList(apiName){
            if(apiName === 'account')           {return 'admAcctRequest';}
            if(apiName === 'projectMembership') {return 'admProjectMembershipRequest';}
            if(apiName === 'reactivation')      {return 'admReactRequest';}
        }
        function set_ms_state_change(ar){   // for Angular 2+
            var i, row;
            for (i in ar){
                row = ar[i];
                row.ms_state_change = 'Requested';
            }
        }
        function setDisplayName(ar){        // for Angular 2+
            var i, row, fullName, tpf;
            for (i in ar){
                row = ar[i];
                fullName =  row.firstName + ' ' + (row.middleName ? row.middleName : '') + ' ' + row.lastName;
                fullName = fullName.replace(/\s+/g,' ').trim() ;
                tpf = row.preferredName.replace(/\s+/g,' ').trim() ;
                row.dispName = (fullName  == tpf)
                             ? fullName
                             : fullName + '<br>(' + tpf + ')';
            }
        }

        function addFilterName(arr){
            Object.keys(arr).forEach(function(k){
                arr[k].filterName = arr[k].preferredName.concat(arr[k].firstName, arr[k].middleName, arr[k].lastName);
                if(Array.isArray(arr[k].institutions)){
                    var inst = '';
                    arr[k].institutions.forEach(function(dict){
                        inst += inst.concat(dict.name + ',');
                    });
                    arr[k].filterAffiliation = inst;
                }
                if(Array.isArray(arr[k].projectShortNameList)){
                    var sn = '';
                    arr[k].projectShortNameList.forEach(function(name){sn += name + ',';});
                    arr[k].filterProjectShortNameList = sn;
                }

            });
        }

        function systemRequests(lists, alerts){
            var p = {search : "member_status=requested deleted=False"};
            http.get("/svc/AccountSystem", {params : p})
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        lists.systemRequests = resp.data;
                        originals['admSystemRequest'] = comMod.objCopy(lists.systemRequests);
                    } else{
                        alertError.msg = resp.error;
                        alerts.push(alertError);
                    }
                }).catch(function(response, status){
              
                alertError.msg = response.data.detail;
                alerts.push(alertError);
            });
        }

        function showFavorRecords(http, form, lists, alerts, anlPersonId){
            var p = {search : "anl_person_id=" + anlPersonId};

            http.get("/svc/Favor", {params : p})
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        lists.userFavorRecords = resp.data;
                    } else{
                        alertError.msg = resp.error;
                        alerts.push(alertError);
                    }
                }).catch(function(response, status){
                alertError.msg = response.data.detail;
                alerts.push(alertError);
            }).finally(function(){
            });
        }

        // can’t choose the systems in project admin screen anymore, so adding here
        function getSystemsList(http, flags, lists, alerts){
            var p = {selectable : false};
            http.get("/admin/systems", {params : p})
                .then(function(response){
                    var resp = response.data
                    if(resp.success){
                        lists.systems = resp.systems;
                    } else{
                        alertError.msg = resp.error;
                        alerts.push(alertError);
                    }
                }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
        }

        function adminSearchUser(http, timeout, flags, form, lists, searchName, alerts){
            comMod.onStopCalling(700, function(){

                // first clear out previous results
                lists.userRecords = [];
                // then, wait a little bit, so that angular loop has time to clear out the display
                // this is so that ub3date is given the chance to call its constructor and init functions
                timeout(function(){
                    adminSearchUserNow(http, flags, lists, searchName, alerts);
                }, 200);
            });
        }
        function adminSearchUserNow(http, flags, lists, searchName, alerts){

                lists.userRecords = [];
                if(!searchName || searchName.length < 2){
                    flags.searchCompleted = false;
                    flags.tooBig = false;
                    lists.userRecords = [];
                    return;
                }
                flags.showSubmit = false;
                http.get("/admin/searchUser", {params : {searchName : searchName}})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            flags.matchedName = resp.match;
                            flags.searchCompleted = resp.success;
                            var arrayOfAccounts = resp.userRecords;
                            lists.userRecords = arrayOfAccounts;
                            flags.refreshSearch = searchName;

                            formatDeactDt(lists.userRecords, function(){
                                setHasRequestFlags(lists.userRecords);
                                setDisplayName(lists.userRecords);      // for Angular 2+
                                // save original search results, to better know if submit/save button is to be displayed
                                originals['admEditRecord'] = comMod.objCopy(lists.userRecords);
                            })
                        } else{
                            alertError.msg = resp.error;
                            alerts.push(alertError);
                        }

                    }).catch(function(response, status){
                        alertError.msg = response.data.detail;
                        alerts.push(alertError);
                    }).finally(function(){
                    });
        }
        function formatDeactDt(usrRecArr, callback) {
            usrRecArr.forEach(function(item){
                comMod.formatDeactDt(item);
            });
            callback();
        }
        function setPrevForm(form, prev){
            comMod.objCopyGuts(form, prev);
        }

        // removed extra parameter scope to fix issue 2994
        function getUserInfo(http, flags, form, lists, name, scope, cb){
            flags.delta = false;
            flags.emailMatched = false;
            flags.apiType = 'admin/';
            flags.userName = name;
            flags.fieldErrors = {};
            flags.arrayFieldErrors = {};
            flags.account_cat_selected = true;
            flags.hideDeleteQA = false;
            lists.userRecords = [];
            scope.domainAdminDashboardAlerts = [];
            isPendingInstitution(lists);
            //temp until we have api
            lists.unixShell = [
                {name : "/bin/tcsh"},
                {name : "/bin/bash"},
                {name : "/bin/zsh"},
                {name : "/bin/false"},
                {name : "/sbin/nologin"}
            ];

            flags.getApiNames = ['account', 'accountAddresses', 'accountInstitutions', 'accountPhones', 'accountQuestions', 'accountEmails', 'accountProjects', 'accountResources'];

            // how populated is form at this point ? mostly empty fields
            edRecMod.getRecord(http, flags, lists, name, function(response){
                var resp = response.success;
                if(resp){
                    flags.prevSavedForm = {};
                    comMod.objCopyGuts(response, form);

                    // is projectList in form at this point?  yes
                    sortAccountQuestions(form);
                    // For Service and Test Accounts
                    if(form.accountCategory !== "User"){
                        var i = form.accountEmails.findIndex(function(item){
                            return item.primary === true;
                        });
                        if(i >= 0){
                            form.email = form.accountEmails[i].email;
                        }
                    }
                    setPrevForm(form, flags.prevSavedForm);
                    if(form.accountCategory === "Service" && (!form.serviceEmail || form.serviceEmail === undefined)){
                        otherAccMod.mailingList(http, lists, alerts, function(){
                            otherAccMod.accountServiceEmail(http, lists, flags, form, alerts, function(){
                                delete form.personId;
                                setPrevForm(form, flags.prevSavedForm);
                                validateEditedRecords(http, flags, form, lists, alerts);
                                if (cb) cb();
                            });
                        });
                    } else {
                        validateEditedRecords(http, flags, form, lists, alerts);
                        if (cb) cb();
                    }
                    flags.disableInput = false;
                } else{
                    flags.bootstrapAlert = 'editRecordError'
                    alertError.msg = response.error;
                    scope.domainAdminDashboardAlerts.push(alertError);
                    if (cb) cb();
                };
            });
        }
        function sortAccountQuestions(form){
            form.accountQuestions = comMod.sortArrayOnKey(form.accountQuestions, 'id', false);
        }

        function validateEditedRecords(http, flags, form, lists, alerts){
            if(Object.getOwnPropertyNames(flags.prevSavedForm).length > 0){
                // made copy of data from apis, lets open form page
                flags.editRecord = true;
                //check for missing data
                edRecMod.validateAccountUpdate(form, flags, true);
                // validateLists for each dict
                comMod.validateListsAfterGet(flags, form);
                //Will now display "Required Field" on page for any missing required items.

                // Favor is display only, no need to validate or edit..
                showFavorRecords(http, form, lists, alerts, form.personId);
            }
        }

        function favorSync(http, form, flags, lists, q, timeout, scope) {
            lists.loadingUserRequests = true;
            var p = {search : 'username=' + flags.userName};
            http.get('/svc/FAVORSync', {params : p})
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        alertSuccess.msg = response.data.data[0].message;
                        scope.adminAlerts.push(alertSuccess);
                        flags.saveOption = 'continue';
                        refreshPageData(http, form, flags, lists, q, alerts);
                    } else{
                        alertError.msg = response.data.error;
                        scope.adminAlerts.push(alertError);
                        scope.domainAdminDashboardAlerts.push(alertError);
                        flags.bootstrapAlert = 'editRecordError';
                    }
                }).catch(function(response, status){
                alertError.msg = response.data.detail;
                alerts.push(alertError);
                flags.bootstrapAlert = 'editRecordError';
            }).finally(function(){
                lists.loadingUserRequests = false;
            });

        }

        function saveRecord(form, flags, lists, sharedLists, q, scope){
            //check what apis to call based on what changed
            var postMsg = '', putMsg = '';
            scope.adminAlerts = [];
            scope.domainAdminDashboardAlerts = [];
            flags.showDomainAdminDashboard = true;
            edRecMod.apisToCall(flags, form);
            if(flags.missing){
                delete flags.missing
            }
            //keys with List
            if(flags.saveOption === 'cancel'){
                flags.editRecord = false;
                flags.account_cat_selected = false;
                flags.createOtherAccount = false;
                flags.newServiceAcct = false;
                // lets get latest user requests for dashboard
                refreshPageData(http, form, flags, lists, q, alerts);
                return;
            }

            if(flags.saveOption === 'LDAPSync'){
                var p = {search : 'user=' + flags.userName};
                http.get('/svc/LDAPSync', {params : p})
                    .then(function(response){
                        var resp = response.data;
                        if(resp.success){
                            alertSuccess.msg = "Successfully LDAP Sync";
                            scope.adminAlerts.push(alertSuccess);
                        } else{
                            alertError.msg = response.data.error;
                            scope.adminAlerts.push(alertError);
                            flags.bootstrapAlert = 'editRecordError';
                        }
                    }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    scope.adminAlerts.push(alertError);
                    flags.bootstrapAlert = 'editRecordError';
                });
                return;
            }

            if(flags.putsToCall.length){
                // disable changing form until apis complete
                flags.disableInput = true;
                edRecMod.putRecord(http, form, flags, lists, cookies, function(response){
                    var resp = response.success;
                    if(resp){
                        // account api response always last for unknown reason, so did it this way so 'Account Info' always listed first in Message
                        if(flags.account){
                            putMsg = 'Account Info, ';
                        }
                        alertSuccess.msg = 'Successfully Updated ' + putMsg + flags.putsToCall;
                        scope.adminAlerts.push(alertSuccess);
                    } else {
                        alertError.msg = response.error;
                        flags.disableInput = false;
                        scope.adminAlerts.push(alertError);
                    }
                    if(scope.adminAlerts.length > 0){
                        refreshPageData(http, form, flags, lists, q);
                    }
                });
                cleanUpName(flags.putsToCall);
            }
            if(flags.postsToCall.length){
                edRecMod.postRecord(http, form, flags, lists, cookies, function(response){
                    var resp = response.success;
                    if(resp){
                        alertSuccess.msg = 'Successfully Added ' + flags.postsToCall;
                        scope.adminAlerts.push(alertSuccess);
                        refreshPageData(http, form, flags, lists, q);
                    } else{
                        alertError.msg = response.error;
                        scope.adminAlerts.push(alertError);
                    }

                });
                cleanUpName(flags.postsToCall);
            }

            // Done sending any KEYS with LIST
            // account not List so handled separate put function
            if(flags.account && flags.account.id){
                if(flags.account.accountType === 'Annual'){
                    if(!form.deactivation_date){
                        alertError.msg = 'Please Select Deactivation Date';
                        scope.adminAlerts.push(alertError);
                        return;
                    } else{
                        flags.account.deactivation_date = form.deactivation_date;
                    }

                } else if(flags.account.accountType === 'Permanent'){
                    flags.account.deactivation_date = null;
                }
                edRecMod.putAccount(http, form, flags, lists, cookies, function(response){
                    var resp = response.success;
                    if(resp){
                        // check if certFavorHost was changed
                        if(flags.account.certFavorHost === false || flags.account.certFavorHost){
                            comMod.getCertifiedHosts(flags, sharedLists);
                        }

                        if(!flags.putsToCall.length){
                            // PUT message doesn't exist, create one

                            alertSuccess.msg = 'Successfully Updated Account Info';
                            scope.adminAlerts.push(alertSuccess);
                            refreshPageData(http, form, flags, lists, q);
                        }

                        delete flags.account;

                    } else{
                        alertError.msg = 'Account ' + response.error;
                        scope.adminAlerts.push(alertError);
                        delete flags.account;
                    }
                });
            }

        }

        function cleanUpName(arrayToCleanUp){
            for(var i = 0 ; i < arrayToCleanUp.length ; i++){
                arrayToCleanUp[i] = arrayToCleanUp[i].replace('account', ' ');
            }
        }
        function navToOriginal() {
            var path = window.location.hash.replace('#','').split('?')[0];
            var prevPath = '';
            if(path === '/userEdit'){
                prevPath = 'userAdmin';
            } else {
                var urlParams = comMod.urlSearchParamsArray();
                prevPath = urlParams && urlParams.get('fromPage');
                if(prevPath === null) prevPath='createOtherAccounts';
            }
            window.location.hash = '/'+prevPath;
        }

        function refreshPageData(http, form, flags, lists, q, alerts){
            flags.bootstrapAlert = null;
            if(flags.saveOption === 'exit'){
                lists.userRecords = [];
                flags.editRecord = false;
                flags.createOtherAccount = false;
            } else if(flags.saveOption === 'continue'){
                //Refresh Data on Update Page if user clicks "Save and Continue"
                getUserInfo(http, flags, form, lists, form.userName, scope);
            }
            //Refresh Data on Main Page
            if(flags.saveOption === 'exit' || flags.saveOption === 'cancel'){
                var href = win.location.href;
                if (href.indexOf('edit=') >= 0 || href.indexOf('userName=') >= 0) {
                    navToOriginal();
                    return;
                }
                refreshUserAdminListData(http, flags, lists, q, alerts);
            }
        }

        function refreshUserAdminListData(http, flags, lists, q, alerts){
                //Refresh Data on Main Page
                if(flags.domainAdmin){
                    getApiLists(http, lists);
                    getRequests(lists, q, alerts, function(){
                        systemRequests(lists, alerts);
                    });
                } else if(flags.otherAccountAdmin){
                    //Other Account Dashboard
                    otherAccMod.getAllOtherAccounts(http, lists, alerts, function(){
                        otherAccMod.serviceAccountEmails(http, lists, alerts);
                    });
                }
        }

        function editUnixSystem(http, cookies, flags, form, lists, q, id, type, scope){
            var msgName = '';
            var obj = {resource_type : type, resource_type_id : id, status : 'Active'};
            var cdata = {form : obj};
            cdata.userName = form.userName;

            if(type === 'UnixGroup'){
                msgName = 'Unix Group';
            } else{
                msgName = 'Systems';
            }
            http.post(flags.apiType + 'accountResources', cdata)
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        alertSuccess.msg = 'Successfully added ' + msgName;
                        scope.adminAlerts.push(alertSuccess);
                        getUserInfo(http, flags, form, lists, form.userName, scope);

                    } else{
                        alertError.msg = msgName + ' ' + resp.error;
                        scope.adminAlerts.push(alertError);
                    }
                }).catch(function(response, status){
                alertError.msg = response.data.detail;
                scope.adminAlerts.push(alertError);
            });

        }

        function updProjectAccountBridgeMemberStatus(pjAccntRec, callback){
            var data;
            data = {
                id: pjAccntRec.id,
                memberStatus:  pjAccntRec.membershipStatus,
                comment: pjAccntRec.comment,
                defaultProject: pjAccntRec.defaultProject,
                projectRole: pjAccntRec.projectRole,
                projectProxy: pjAccntRec.projectProxy,
                receiveReports: pjAccntRec.receiveReports,
            };
            http.put('/admin/projectAccount', data)
                .then(function(resp){
                    if (callback) callback();
                });
        }


        function sendMailToAdmin(http, cookies, flags, form, lists, q, userName, project, scope){
            var cdata = {
                userName: userName,
                projectShortName: project.projectShortName
            };
            http.post(flags.apiType + 'mailToAdmin', cdata)
                .then(function(response){
                var resp = response.data;
                    if(resp.success){
                        alertSuccess.msg = resp.msg;
                        scope.adminAlerts.push(alertSuccess);
                    } else{
                        alertError.msg = resp.error;
                        scope.adminAlerts.push(alertError);
                    }
                    window.scrollTo(0, 0);
                }).catch(function(response, status){
                alertError.msg = response.data.detail;
                scope.adminAlerts.push(alertError);
            });
        }

        function editOtherAccount(http, cookies, flags, form, lists, cat, scope, option){
            if(scope.domainAdminDashboardAlerts){
                delete scope.domainAdminDashboardAlerts
            }
            scope.adminAlerts = [];
            var cdata = {form : {id : form.id}};
            var action = 'Updated'
            if(form.comment){
                cdata.form.comment = form.comment;
            }
            if(cat === 'Service'){
                cdata.form.serviceEmail = form.serviceEmail;
            }
            if(cat !== 'System'){
                cdata.form.identity_id = form.identity_id;
                if(form.accountType){
                    cdata.form.accountType = form.accountType;
                    if(form.accountType === 'Annual'){
                        cdata.form.deactivation_date = form.deactivation_date;
                    } else{
                        cdata.form.deactivation_date = null;
                    }

                }

            } else{
                cdata.form.userName = form.accountEmails[0].email;
                cdata.form.email = form.accountEmails[0].email;
                cdata.form.firstName = form.firstName;
                cdata.form.lastName = form.lastName;
                if(form.preferredName){
                    cdata.form.preferredName = form.preferredName;
                }

            }
            http.put('admin/update' + cat + 'Account', cdata)
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        alertSuccess.msg = 'Successfully ' + action + ' ' + cat + ' Account ' + form.userName;
                        // good to call other apis now
                        adminSaveRecord(option, scope);
                        if(option !== 'continue'){
                            otherAccMod.getAllOtherAccounts(http, lists, alerts, function(){
                                otherAccMod.serviceAccountEmails(http, lists, alerts);
                            });
                        }
                        if(cat !== 'System'){
                            flags.changedIdentity = true;
                        }
                    } else{
                        flags.error = resp.error;
                        alertError.msg = resp.error;
                        scope.adminAlerts.push(alertError);
                    }
                }).catch(function(response, status){
                alertError.msg = response.data;
                scope.adminAlerts.push(alertError);
            });
        }
        function adminSaveRecord(saveOption, scope){
            if (saveOption == 'cancel'){
                navToOriginal();
            } else {
                scope.flags.saveOption = saveOption;
                saveRecord(scope.form, scope.flags, scope.lists, scope.sharedLists, Promise, scope);
            }
        };

        function addOtherAccount(http, cookies, flags, form, lists, cat, scope){

            var cdata = {form : {}};
            scope.adminAlerts = [];
            cdata.form.userName = form.userName;
            if(form.accountType === 'Annual'){
                cdata.form.deactivation_date = form.deactivation_date;
            }
            if(form.comment){
                cdata.form.comment = form.comment;
            }
            if(cat === 'Service' || cat === 'Test'){
                cdata.form.id = form.identity_id;
                //cdata.form.accountType = form.accountType;

            }
            if(cat === 'Service'){
                cdata.form.serviceEmail = form.serviceEmail;
                cdata.form.shell = form.shell;

            } else if(cat === 'System'){
                cdata.form.firstName = form.firstName;
                cdata.form.lastName = form.lastName;
                if(form.preferredName){
                    cdata.form.preferred_name = form.preferredName;
                } else if(form.comment){
                    cdata.form.comment = form.comment;
                }
                cdata.form.email = form.email;
            }

            http.post('admin/add' + cat + 'Account', cdata)
                .then(function(response){
                    var resp = response.data;
                    var name = '';
                    if(form.userName){
                        name = form.userName;
                    } else{
                        name = form.email;
                    }
                    if(resp.success){
                        flags.emailMatched = false;
                        flags.newServiceAcct = true;
                        alertSuccess.msg = 'Successfully Added ' + cat + ' Account for ' + name;
                        scope.adminAlerts.push(alertSuccess);
                        otherAccMod.getAllOtherAccounts(http, lists, alerts, function(){
                            otherAccMod.serviceAccountEmails(http, lists, alerts, function(){
                                adminSaveRecord('cancel', scope);
                            });
                        });

                    } else{
                        alertError.msg = resp.error;
                        scope.adminAlerts.push(alertError);

                    }
                    window.scrollTo(0, 0);
                }).catch(function(response, status){
                    alertError.msg = response.detail;
                    scope.adminAlerts.push(alertError);
                });

        }

        function selectOption(fldValue, lists, flags, index, tableName, fldName, alert){
            if(!fldValue && ['comment'].indexOf(fldName) < 0){
                flags.showSubmit = false;
                flags.tableSelected = null;
                flags.index = 0;
                return;
            } else {
                flags.searchCompleted = false;
                flags.tableSelected = tableName;
                flags.showSubmit = false;
                flags.bootstrapAlert = tableName;

                if(fldName){
                    flags.showSubmit = didSomethingChange(tableName, lists, flags, fldValue, fldName, index);
                    flags.index = index;
                    alert.shift();
                }

                if(fldName === 'ms_state'){
                    flags.ms_state = fldValue;
                }
                else if(fldName === 'ack'){
                    flags.ack = fldValue;
                }
                else if(fldName === 'approver'){
                    flags.piApprover = JSON.parse(fldValue);
                }
            }
        }
        function getListFromTableName(lists, tableName){
            var tabLstObj = [
                {tab: 'admEditRecord', lstObj: lists.userRecords},
                {tab: 'admAcctRequest', lstObj: lists.accountRequests},
                {tab: 'admProjectMembershipRequest', lstObj: lists.projectMembershipRequests},
                {tab: 'admReactRequest', lstObj: lists.reactivationRequests},
                {tab: 'admSystemRequest', lstObj: lists.systemRequests}
            ];
            var idx = tabLstObj.findIndex(function(item){return (tableName === item.tab);});
            return tabLstObj[idx].lstObj;
        }
        function didSomethingChange(tableName, lists, flags, action, type, index){
            var original, current;
            current = getListFromTableName(lists, tableName);
            original = originals[tableName];
            original = getFilteredOriginals(flags.filter, original, tableName);
            if(action && type==='accountType' && tableName === 'admEditRecord'){checkDeactDate(current, original, index);}
            var val = ! comMod.isEqual(original, current);
            return val;
        }
        function getFilteredOriginals(filtObj, orig, tabName){
            var map, sectionName, dict, key;
            var appliedFilters = [];
            map = {
                'admAcctRequest'              : 'acReq',
                'admProjectMembershipRequest' : 'pjMemReq',
                'admReactRequest'             : 'reactReq',
                'admSystemRequest'            : 'sysReq'
            };
            sectionName = map[tabName];
            dict = filtObj[sectionName];
            for (key in dict){
                if (dict[key] != '') {appliedFilters.push({key:key,val:dict[key]});}
            }
            if(appliedFilters.length > 0){
                appliedFilters.forEach(function(filt){
                    orig = orig.filter(item => {
                        return(item[filt.key].toLowerCase().indexOf(filt.val.toLowerCase()) >= 0);
                    });
                });
            }
            return orig;
        }

        function checkDeactDate(arrNew, arrOld, ind){
            var item = arrNew[ind];
            var oldItem = arrOld[ind];
            if(oldItem.accountId === item.accountId){
                item.deactivation_date = (item.accountType === 'Annual') ? oldItem.deactivation_date : null;
            }
        }

        function getProxyUsername(name, lists){
            var piproxies = lists;
            if(typeof piproxies !== "undefined"){
                for(var i = 0 ; i < piproxies.length ; i++){
                    for(var item in piproxies[i]){
                        if(piproxies[i][item] === name){
                            return piproxies[i].proxyUsername;
                        }
                    }

                }

            }

        }

        function projectOption(action, lists, flags, sortedIndex, originalIndex, alerts){
            var row = null;
            flags.tableSelected = 'admProjectMembershipRequest';
            if(!action){
                flags.showSubmit = false;
                flags.tableSelected = null;
                flags.index = 0;
                return;
            } else{
                alerts.shift();
                row = lists.projectMembershipRequests[originalIndex];
                   
                if(row.approver_name){
                    flags.piApprover = {};
                    flags.piApprover.piProxy = row.approver_name;
                    flags.tableSelected = 'admProjectMembershipRequest';
                }

                //flags.hideMsg = true;
                flags.searchCompleted = false;
                flags.showSubmit = true;
                flags.index = sortedIndex;
                flags.ms_state = action;

            }
        }

        function filterByUserName(userName, flags, lists, clearFilter){
            if(clearFilter){
                flags.hideAcctRequest = false;
                flags.filterProjectMembershipRequest = false;
                lists.projectMembershipRequests.userName = '';
                lists.accountRequests.userName = '';
                lists.reactivationRequests.userName = '';

            } else{
                //flags.hideAcctRequest = true;
                flags.hideProjectMembershipRequest = false;
                flags.filterProjectMembershipRequest = true;
                lists.projectMembershipRequests.userName = userName;
                lists.accountRequests.userName = userName;
                lists.reactivationRequests.userName = userName;
            }
        }
        function setHasRequestFlags(list){
            var i, row;
            for (i in list){
                row = list[i];
                row.hasRequest = userHasRequest(row.userName, scope.lists);
            }
        }
        function userHasRequest(userName, lists){
            // check all requests for username
            if (!lists.projectMembershipRequests) return false;

            var names = lists.projectMembershipRequests.concat(
                lists.accountRequests, lists.reactivationRequests, lists.systemRequests
            );
            if(typeof names !== "undefined"){
                for(var i = 0 ; i < names.length ; i++){
                    for(var item in names[i]){
                        if(names[i][item] === userName){
                            return true
                        }
                    }
                }
            }
            return false;
        }

        function isPendingInstitution(lists){
            var pending = lists.instActPend;
            if(typeof pending !== "undefined"){
                for(var i = 0 ; i < pending.length ; i++){
                    for(var item in pending[i]){
                        if(pending[i][item] === 'Pending'){
                            var str = '**'
                            if(pending[i].name.includes(str)){
                                return;
                            } else{
                                pending[i].name = '** ' + pending[i].name;
                            }
                        }
                    }
                }
            }
        }

        function requestAction(http, cookies, flags, lists, requestType, ms_state, id, index, alerts, q){
            var position = window.pageYOffset || document.documentElement.scrollTop;

            var c , plural , name , type,  action, submitRequest, apiType, p, row, i;

            plural = requestType + 's';
            if ((typeof angular) != 'undefined'){
                // Angular 1
            }else{
                // Angular 2+
                //replace index, arrays & sorting handle index diferent
                index = -1;
                for (i in lists[plural]){
                    if (lists[plural][i].id == id){
                        index = i;
                    }
                    if (lists[plural][i].accountId == id){
                        index = i;
                    }
                }
            }
            row = lists[plural][index];     // operations happen in only one row of the array
            name = row.preferredName;
            type = '';
            action = '';
            submitRequest = {id : id};
            apiType = '';
            p = {};

            if(!ms_state && requestType === 'projectMembershipRequest'){
                return;
            }
            if(ms_state){
                submitRequest.status = ms_state;
            } else if(requestType !== 'reactivationRequest'){
                submitRequest.status = row.accountStatus;
            }
            submitRequest.comment = row.comment;
            if(row.deactivation_date !== null && row.accountType !== 'Permanent'){
                submitRequest.deactivation_date = row.deactivation_date;
            }
            if(row.deactivation_date === null && row.accountType !== 'Permanent' && plural === 'userRecords'){
                alertError.msg = 'Please Select Deactivation Date';
                alerts.push(alertError);
                return;
            } else if(row.deactivation_date !== null && row.accountType === 'Permanent'){
                submitRequest.deactivation_date = null;
            }
            if(row.affiliations && flags.ack){
                var rowAffiliations = row.affiliations.map(function(item) {
                    return {
                      id: item.id,
                      ack: item.ack
                    };
                  });
                var objectToSend = {'form': rowAffiliations};
                http.put('admin/accountInstitutions',objectToSend).then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        //clog("Ack Updated");
                    }else{
                        alertError.msg = 'User Ack not updated ' + resp.error;
                        alerts.push(alertError);
                    }
                }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });
                delete flags.ack;
            }
            if(requestType === 'reactivationRequest'){
                submitRequest.id = row.reactivationRequestId;
            } else if(requestType === 'projectMembershipRequest'){
                flags.bootstrapAlert = 'admProjectMembershipRequest';
                if(row.approver_name){
                    submitRequest.approver_name = getProxyUsername(row.approver_name, row.piproxies);
                }
                if(flags.piApprover){
                    submitRequest.approver_name = getProxyUsername(flags.piApprover.piProxy, row.piproxies);
                    delete flags.piApprover;
                }
                if((ms_state === 'Approved-pending MUA/Ack' || ms_state === 'Approved') && !submitRequest.approver_name){
                    flags.bootstrapAlert = 'admProjectMembershipRequest';
                    alertError.msg = 'Please select approver';
                    alerts.push(alertError);
                    return;
                }
            } else{
                submitRequest.accountType = row.accountType;
            }
            if(requestType === 'systemRequest'){
                apiType = '/svc/';
                // svc account, different naming convention for api..
                requestType = 'AccountSystem';
                p.search = "id=" + submitRequest.id;
                p.form = {member_status : ms_state};
                // if comment is removed, it should also be saved.
                p.form.comment = submitRequest.comment;
            } else{
                apiType = '/admin/';
                p.form = [submitRequest];
            }

            http.put(apiType + requestType, p)
                .then(function(response){
                    var resp = response.data;
                    flags.showSubmit = false;
                    if(resp.success){
                        delete flags.ms_state;
                        if(requestType === 'accountRequest'){
                            type = 'Account';
                        }
                        if(requestType === 'accountReactivation'){
                            type = 'Reactivation';
                        } else if(requestType === 'projectMembershipRequest'){
                            type = row.projectShortName + ' project membership';
                        } else if(requestType === 'userRecord'){
                            type = 'User Record';
                            adminSearchUser(http, timeout, flags, false, lists, flags.refreshSearch, alerts)
                        } else if(requestType === 'AccountSystem'){
                            type = 'System Request ';
                            name = row.username;
                        }
                        if(ms_state){
                            action = ms_state;
                        } else{
                            action = "Updated";
                        }
                        alertSuccess.msg = type + ' status for ' + name + ' has been set to ' + action;
                        alerts.push(alertSuccess);

                    } else{
                        alertError.msg = 'Membership ' + resp.error;
                        alerts.push(alertError);
                    }
                    getApiLists(http, lists);
                    getRequests(lists, q, alerts, function(){
                        systemRequests(lists, alerts);
                        timeout(function(){
                            // page has been repainted with new data, set to previous scroll position
                            window.scrollTo(0, position);
                        }, 300);
                    });

                }).catch(function(response, status){
                    alertError.msg = response.data.detail;
                    alerts.push(alertError);
                });

        }

        function getApiLists(http, lists, callback){

            if(!lists.usaStates){
                lists.usaStates = comMod.getUsaStates();
            }

            if(!lists.careerLevels){
                comMod.careerLevels(lists);
            }

            if(!lists.instActPend){
                comMod.getInstActPend(lists);
            }

            if(!lists.allUnixGroups){
                comMod.unixGroupList(http, lists, alerts);
            }
            comMod.doWhenAPICallsDone(function(){
                if(callback) callback();
            });

        }
        function membershipReminder(username, project, alerts, cb){
            if(alerts){scope.domainAdminDashboardAlerts = alerts;}

            var cdata = {userName: username, projectShortName: project};
            http.post('admin/remindPIRequest', cdata)
                .then(function(response){
                    var resp = response.data;
                    if(resp.success){
                        alertSuccess.msg = 'Successfully sent email reminder to PI';
                        scope.domainAdminDashboardAlerts.push(alertSuccess);
                    } else{
                        alertError.msg = resp.error;
                        scope.domainAdminDashboardAlerts.push(alertError);
                    }
                    if (cb) cb();
                }).catch(function(response, status){
                    alertError.msg = response.detail;
                    scope.domainAdminDashboardAlerts.push(alertError);
                    if (cb) cb();
                });
        }

// ----------- public stuff gets returned -----------
        return publicStuff;

    })();

module.exports = {domainAdminModule}

