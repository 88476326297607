import { NgModule } from '@angular/core';
import { Routes, RouterModule }         from '@angular/router';
import { AuthGuardService } from './shared/guards/auth-guard.service';

import { AccountReactivateComponent }   from './views/pages/account-reactivate/account-reactivate.component';
import { AccountRequestComponent }      from './views/pages/account-request/account-request.component';
import { AccountUpdateComponent }       from './views/pages/account-update/account-update.component';
import { AclTagsComponent }              from './views/pages/acl-tags/acl-tags.component';
import { DataSurveyComponent }          from './views/pages/data-survey/data-survey.component';
import { AllocationRequestComponent }   from './views/pages/allocation-request/allocation-request.component';
import { CreateOtherAccountsComponent } from './views/pages/create-other-accounts/create-other-accounts.component';
import { DomainAdminComponent }         from './views/pages/domain-admin';
import { HomeComponent }                from './views/pages/home/home.component';
import { InstitutionsComponent }        from './views/pages/institutions/institutions.component';
import { LoginComponent }               from './views/pages/auth/login/login.component';
import { JoinProjectComponent }         from './views/pages/join-project/join-project.component';
import { LogsComponent }                from './views/pages/logs/logs.component';
import { PluginsComponent }             from './views/pages/plugins/plugins.component';
import { ProjectAdminComponent }        from './views/pages/project-admin/project-admin.component';
import { ProjectEditComponent }         from './views/pages/project-edit/project-edit.component';
import { SvcApiComponent }              from './views/pages/svc-api/svc-api.component';
import { ApiDevIntComponent }           from './views/pages/api-dev-int/api-dev-int.component';
import { UserAdminComponent }           from './views/pages/user-admin/user-admin.component';
import { UserEditComponent }            from './views/pages/user-edit/user-edit.component';
import { CreateOtherAccountsFormComponent } from './views/pages/create-other-accounts-form/create-other-accounts-form.component';
import { ProjectManagementComponent }   from './views/pages/project-management/project-management.component';
import { ProjectManagementEditComponent }   from './views/pages/project-management-edit/project-management-edit.component';
import { SystemRequestComponent }       from './views/pages/system-request/system-request.component';
import { ManageUnixGroupsComponent }    from './views/pages/manage-unix-groups/manage-unix-groups.component';

const routes: Routes = [
    { path: 'accountReactivate' ,   component: AccountReactivateComponent },
    { path: 'accountRequest' ,      component: AccountRequestComponent },
    { path: 'accountUpdate' ,       component: AccountUpdateComponent },
    { path: 'allocationRequests' ,  component: AllocationRequestComponent },
    { path: 'aclAdmin' ,            component: AclTagsComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'createOtherAccounts',  component: CreateOtherAccountsComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'dataSurvey' ,          component: DataSurveyComponent },
    { path: 'domainAdmin' ,         component: DomainAdminComponent },
    { path: 'institutions' ,        component: InstitutionsComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'home' ,                component: HomeComponent},
    { path: 'joinProject' ,         component: JoinProjectComponent},
    // { path: 'login' ,               component: LoginComponent },
    { path: 'logsAdmin' ,           component: LogsComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'pluginsAdmin' ,        component: PluginsComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'projectAdmin' ,        component: ProjectAdminComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'projectEdit' ,         component: ProjectEditComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'manageProjects' ,      component: ProjectManagementComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'manageProjectsEdit' ,  component: ProjectManagementEditComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'manageUnixGroups' ,    component: ManageUnixGroupsComponent},
    { path: 'svcApi' ,              component: SvcApiComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'apiDevInt' ,           component: ApiDevIntComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'userAdmin' ,           component: UserAdminComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'userEdit' ,            component: UserEditComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'createOtherAccountsForm',component: CreateOtherAccountsFormComponent,
                                    canActivate : [AuthGuardService] },
    { path: 'systemRequest' ,       component: SystemRequestComponent},
    { path: '**',                   redirectTo: '/home'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})

export class appRoutingModule {
}
export const routingComponents = [
    AccountUpdateComponent,
    DomainAdminComponent,
    LoginComponent,
    AllocationRequestComponent
];