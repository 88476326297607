var manageResourcesModule =
(function(){
        "use strict";
        var publicStuff;
        var comMod, scope ;
        comMod = scope = null;
        var jsServices = {};

        publicStuff = {
            init : init,
            getSystems : getSystems,
            requestSystem : requestSystem,
            listHasActive : listHasActive,
            listHasPending : listHasPending,
            listHasSystem : listHasSystem,
            setServices   : setServices,
        };
        // http : the http service. When unit testing, it is the mock http service
        // lists: needed lists by the UI, for example: projects, institutions
        // flags: needed by the UI to know what options to show to the user
    function init(scope, http, lists, flags, pageName){
        //scope.publicPage(true);
        comMod.publicPage(true);
        getSystems(http, scope.lists, scope.flags);
        comMod.getSetContext(scope, pageName, function(){
            if(!scope.flags){
                scope.flags = {
                    displayedPageName: pageName,
                    hideHomePage: true};
            }
            if(!scope.form){
                scope.form = {};
            }

            if(!scope.lists) {
                scope.lists = {};
            }

            if(!scope.lists.allSystems){
                getSystems(http, scope.lists, scope.flags);
            }

            if(scope.authAlerts){
                scope.authAlerts = [];
            }
            
        });
    }

    function setServices(services){
        var i, serv;
        for (i in services){
          serv = services[i];
          jsServices[serv.sname] = serv;
        }    
        scope   = jsServices.scope;
        comMod  = jsServices.commonModule;
    }

    function getSystems(http, lists, flags){
        var p = {};
        http.get('/auth/systems', {params:p})
        .success(function(resp){
            if (resp.success){
                lists.allSystems = resp.systems;
            }else{

                flags.systemError = resp.error;
            }
            
        }).error(function(data, status, headers, config) {
               // called asynchronously if an error occurs
               // or server returns response with an error status.
              lists.systemFailure = data.data.detail;
        });

    };
    function listHasActive(array, scope){
        scope.activeList = array.some(function(val) {
            return val['memberStatus'] == 'Active';
        })
        return scope.activeList;
    }

    function listHasPending(array, scope){
        scope.pendingList = array.some(function(val) {
            return val['memberStatus'] == 'Requested';
        })
        return scope.pendingList;
    }

    function listHasSystem(systemsArr,scope){
        scope.systemFlag = systemsArr.some(function(currVal) {
            return currVal['memberStatus'] == null;
        });
        return scope.systemFlag;
    }

    function requestSystem(http, lists, flags, cookies, systemId, alerts) {
        var cdata = {system_id : systemId};

        http.post('/auth/requestSystem', cdata)
            .success(function(resp){
               if (resp.success){
                    var f = resp.requestSystem;
                    flags.requestSystem = resp.requestSystem;
                    alerts.push({
                                type : 'success',
                                msg : flags.requestSystem
                            });
                     getSystems(http,lists, flags);

                        } else{
                            alerts.push({
                                type : 'danger',
                                msg : resp.error
                            });
                        }  
                

            }).error(function(data, status, headers, config) {
               // called asynchronously if an error occurs
               // or server returns response with an error status.
              lists.systemFailure = data.data.detail;
        });
    }


    // ----------- public stuff gets returned -----------
    return publicStuff;

})();
        
module.exports = {manageResourcesModule}
