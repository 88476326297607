
import {Component, ViewEncapsulation, HostListener} from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
//import {AuthenticationService} from './shared/services';
import {CommonService, Ub3httpService} from '@common/services';
import {CookieService} from 'ngx-cookie-service';
import { validationModule } from 'validationModule';
import { TimeoutModalComponent} from "./shared/timeout-modal/timeout-modal.component";
import { SessionTimeoutService } from '@common/services';
import { GlobalConstants } from '@common/constants/global-constants';

let t = null;   // points to this: the instantiated object from class AppComponent
var ub3consoleLog = null;
var appFunctions = null;
var eventAdded = false;
var routeMap = {};

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    currentUser: any = {firstName: '', lastName: ''};
    loggedIn: boolean = false;
    isCollapsed: boolean = true;
    menuItems: any = [];
    state: any = {httpCallsPending: 0, menuPaths: '', dispHome: false, displayRoutedPage: false};
    domainGlobals: object = {domainTitle: ''};
    scrWidth: any;
    mobile: any;
    showSignOut: boolean = false;
    vhcp = false;
    bigFooter = false;
    showNavMenu = true;
    pageName = 'hey';
    user = null;
    adminPage: any = '';
    ALCFWebsiteURL = GlobalConstants.ALCFWebsiteURL;
    SupportCenterURL = GlobalConstants.SupportCenterURL;
    ALCFResourcesURL = GlobalConstants.ALCFResourcesURL;
    ALCFPasscodeTokensURL = GlobalConstants.ALCFPasscodeTokensURL;
    routeMap = {
        'account-reactivate'         : 'accountReactivate',
        'accountreactivate'          : 'accountReactivate',
        'accountReactivate'          : 'accountReactivate',
        'account-request'            : 'accountRequest',
        'accountrequest'             : 'accountRequest',
        'accountRequest'             : 'accountRequest',
        'account-update'             : 'accountUpdate',
        'accountupdate'              : 'accountUpdate',
        'accountUpdate'              : 'accountUpdate',
        'acl-admin'                  : 'aclAdmin',
        'acladmin'                   : 'aclAdmin',
        'aclAdmin'                   : 'aclAdmin',
        'allocation-request'         : 'allocationRequests',
        'allocationrequest'          : 'allocationRequests',
        'allocationRequests'         : 'allocationRequests',
        'apiDevInt'                  : 'apiDevInt',
        'create-other-accounts-form' : 'createOtherAccountsForm',
        'createotheraccountsform'    : 'createOtherAccountsForm',
        'createOtherAccountsForm'    : 'createOtherAccountsForm',
        'create-other-accounts'      : 'createOtherAccounts',
        'createotheraccounts'        : 'createOtherAccounts',
        'createOtherAccounts'        : 'createOtherAccounts',
        'data-survey'                : 'dataSurvey',
        'datasurvey'                 : 'dataSurvey',
        'dataSurvey'                 : 'dataSurvey',
        'domain-admin'               : 'userAdmin',
        'domainadmin'                : 'userAdmin',
        'domainAdmin'                : 'userAdmin',
        'home'                       : 'home',
        'institutions'               : 'institutions',
        'join-project'               : 'joinProject',
        'joinproject'                : 'joinProject',
        'joinProject'                : 'joinProject',
        'logs-admin'                 : 'logsAdmin',
        'logsadmin'                  : 'logsAdmin',
        'logsAdmin'                  : 'logsAdmin',
        'manage-projects'            : 'manageProjects',
        'manageprojects'             : 'manageProjects',
        'manageProjects'             : 'manageProjects',
        'manage-projects-edit'       : 'manageProjectsEdit',
        'manageprojectsedit'         : 'manageProjectsEdit',
        'manageProjectsEdit'         : 'manageProjectsEdit',
        'manage-unix-groups'         : 'manageUnixGroups',
        'manageunixgroups'           : 'manageUnixGroups',
        'manageUnixGroups'           : 'manageUnixGroups',
        'plugins-admin'              : 'pluginsAdmin',
        'pluginsadmin'               : 'pluginsAdmin',
        'pluginsAdmin'               : 'pluginsAdmin',
        'project-admin'              : 'projectAdmin',
        'projectadmin'               : 'projectAdmin',
        'projectAdmin'               : 'projectAdmin',
        'project-edit'               : 'projectEdit',
        'projectedit'                : 'projectEdit',
        'projectEdit'                : 'projectEdit',
        'svc-api'                    : 'svcApi',
        'svcapi'                     : 'svcApi',
        'svcApi'                     : 'svcApi',
        'system-request'             : 'systemRequest',
        'systemrequest'              : 'systemRequest',
        'systemRequest'              : 'systemRequest',
        'user-admin'                 : 'userAdmin',
        'useradmin'                  : 'userAdmin',
        'userAdmin'                  : 'userAdmin',
        'user-edit'                  : 'userEdit',
        'useredit'                   : 'userEdit',
        'userEdit'                   : 'userEdit'
    };


    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
        this.mobile = false;
        if(this.scrWidth < 768){this.mobile = true }
    }

    constructor(
        private router: Router,
       // private authenticationService: AuthenticationService,
        private commonService: CommonService,
        private http: Ub3httpService,
        public cookies: CookieService,
        public sessionTimeoutService: SessionTimeoutService,
        public modalService: NgbModal
    ){
        t = this;
        ub3consoleLog = t.commonService.ub3consoleLog;
        t.state         = t.commonService.getSharedObject('app', 'state');
        t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
        t.menuItems     = t.commonService.getSharedObject('app', 'menuItems');
        appFunctions    = t.commonService.getSharedObject('app', 'functions');
        appFunctions['processClick'] = t.processClick;
        appFunctions['isAdmPage'] = t.isAdmPage;
        appFunctions['setDispFlags'] = t.setDispFlags;
        appFunctions['setUser'] = t.setUser;
        appFunctions['logout'] = t.logout;
        t.state.domain = 'alcf';
        t.state.user = {};
        t.state.httpCallsPending = 0;
        commonService.ngOnInit();
        functionsClickableFromMenu = {
            /*
            'tryToGetOut': this.tryToGetOut,
            */
        };

        t.http.get('/public/domainGlobals', {}).subscribe(function(resp){
            t.state.cels = (resp && resp.domainGlobals && resp.domainGlobals.name == 'CELS');
            t.commonService.objCopyGuts(resp.domainGlobals, t.domainGlobals);
            t.domainGlobals && t.domainGlobals.systemMessage && t.addSystemMessage(t.domainGlobals.systemMessage);
            //t.commonService.init(cookies, t.http, t.http, t.state, setTimeout, window) ;
            validationModule.init(commonService.formNames());

            // find out if signed in. setup menu items too
            t.setUser();
        });
        this.getScreenSize();
       
        t.sessionTimeoutService.setOpenModalFunction(function(){
            t.modalService.open(TimeoutModalComponent, {size: 'lg'});
        });
        
    }

    ngOnInit() {
        if (!eventAdded){
            //this only handles the browser's back & forward arrow events
            eventAdded = true;
            window.addEventListener('popstate', function (e: any) {
                t.ngOnInit();
            });
        }
        var currentHashVal = (window.location.hash).replace(/#/g,'').replace('!','');
        currentHashVal = t.commonService.replaceAll(currentHashVal, '/', '');
        var hashValArr = currentHashVal.split('?');
        var path = hashValArr[0];
        var params = hashValArr[1];
        var modifiedHashVal = t.routeMap[path];
        var urlExists = (path === modifiedHashVal);
        if(modifiedHashVal && params){modifiedHashVal += '?'+params;}
        var newHashVal = modifiedHashVal ? modifiedHashVal : currentHashVal;
        var url = newHashVal ? '/'+newHashVal : '/home';
        var pathName = url.split('?')[0];
        t.state.page = pathName;
        // we can use the state.queryUrlParams variable if there are query parameters in the url
        t.state.queryUrlParams = params ? url : null;
        t.pageName   = pathName;
        if (pathName == '/accountRequest'){
            t.state.backFromFavor = (t.commonService.urlSearchParamsArray().get('backFromFavor') == 'true');
        }
        if (newHashVal && !urlExists){
            window.location.hash = '/'+newHashVal;
        }
        t.setDispFlags();
        t.commonService.doWhenAPICallsDone(function(){
            t.adminPage = t.commonService.isAdminPg();
        });
    }

    setUser(){
        t.commonService.doWhenAPICallsDone(function(){
            t.commonService.setUser(t.state, function(){  
                t.user = t.currentUser = t.state.user;
                t.commonService.objCopyGuts(convert19(t.state.menuItems, t.state.user), t.menuItems);
            });
        });
    }
    setDispFlags(){
        if ((!t.state) || (!t.state.user) || (!t.state.user.role)) {
            // not ready
            setTimeout(function(){t.setDispFlags();}, 300)
            return;
        }
        var signedIn = (t.state && t.state.user && t.state.user.signedIn) ;
        var role     = t.state.user.role;
        var pg = t.state.page.replace('/','');
        var nonLoggedInPgs = [
            'accountReactivate',
            'accountRequest',
            'allocationRequests',
            'dataSurvey'
        ];
        if (signedIn){
            t.state.dispHome =  (pg == 'home')
                                || (pg !== 'home'
                                && nonLoggedInPgs.indexOf(pg) < 0
                                && role == 'limited');
        } else {
            t.state.dispHome = nonLoggedInPgs.indexOf(pg) < 0;
        }

        t.state.displayRoutedPage = !t.state.dispHome ;

        t.showNavMenu = true;
        // when to turn off menu, case by case
        if (pg == 'accountReactivate') t.showNavMenu = false;
        if (pg == 'accountRequest'   ) t.showNavMenu = false;
        if (pg == 'allocationRequests' && ((role == 'public') || (role == 'limited'))) t.showNavMenu = false;
        if (pg == 'dataSurvey'       ) t.showNavMenu = false;

    }

    processClick(str: string){
        callFunc(str);
    }
    isAdmPage(item){
        var role = item.r;
        t.adminPage = (role.length === 1 && role[0] === 'domainAdmin');
    }

    tryToSignOut(){
        t.showSignOut = true;
        setTimeout(function(){ t.showSignOut = false; }, 10000);
    }
    logout() {
        t.commonService.signOut(0, t, false, t.router);
        t.commonService.doWhenAPICallsDone(function(){
            t.adminPage = t.commonService.isAdminPg();
        });
    }
    initSignIn(){
        window.location.hash = '/home';
    }
    addSystemMessage(systemMessage){
        t.commonService.addSystemMessage(systemMessage);
    }
}
/* -------------------  END OF CLASS ---------------------------------------*/


var functionsClickableFromMenu = null;

function callFunc(str){
    var fun, functionName, routeName;
    if (str && (str.startsWith('f '))){
        functionName = str.replace('f ','').trim();
        fun = functionsClickableFromMenu[functionName];
        if (fun) fun();
    }
    if (str && (str.startsWith('r '))){     // stay in Angular
        var routeName = str.replace('r ','').trim();
        if (routeName == '') return;

        // weird case here for project admin:
        if (routeName.indexOf('&') >= 0){
            routeName = t.commonService.replaceAll(routeName, '&', '?');
        }
        t.state.page = routeName;
        t.setDispFlags();
        t.router.navigateByUrl(routeName);
    }
    if (str && (str.startsWith('r1 '))){     // redirect to Angular.js
        var routeName = str.replace('r1 ','').trim();
        if (routeName != '/'){
            routeName = "/#!/" + routeName;
        }
        window.location.href = routeName;
    }
    if (str && (str.startsWith('re '))){     // redirect to external address
        var routeName = str.replace('re ','').trim();
        window.location.href = routeName;
    }
}

function convertFromOldUrl(str){
        var page = t.commonService.cut(str, '/', 4);
        if (match(page, 'allocation request' )) return 'allocationRequests';
        if (match(page, 'allocation requests')) return 'allocationRequests';
        if (match(page, 'data survey'        )) return 'dataSurvey';
        if (match(page, 'account request'    )) return 'accountRequest';
        if (match(page, 'account reactivate' )) return 'accountReactivate';
        if (match(page, 'account update'     )) return 'accountUpdate';
        if (match(page, 'manage project'     )) return 'manageProjects';
        if (match(page, 'manage projects'    )) return 'manageProjects';
        if (match(page, 'project admin'      )) return 'projectAdmin';
        if (match(page, 'institutions'       )) return 'institutions';
        if (match(page, 'plugins admin'      )) return 'pluginsAdmin';
        if (match(page, 'logs admin'         )) return 'logsAdmin';
        if (match(page, 'domain admin'       )) return 'userAdmin';
        if (match(page, 'svc api'            )) return 'svcApi';

        function match(str, withSpaces){
            var dash  = t.commonService.toDashCase(withSpaces);
            var camel = t.commonService.toCamel(dash);
            var lower = camel.toLowerCase();
            //log(210, {str:str, dash:dash, camel:camel, lower:lower});
            return ((str == dash) || (str == camel) || (str == lower)) ;
        }
}

// convert Angular.js menu structure to Angular menu structure
//
function convert19(items, user){  
    // from this:   {label : 'label',      roles : d, homepage : false,   path : '#/domainAdmin', init : scope.initDomainAdmin}
    // to this:     {    t : 'label', io: 'o', r : d,                         f:'r1 accountReactivate'}
    // t = title,  
    // io = if this menu item should show up when signed in (i), or singed out (o)
    // r = the roles that can access this menu item
    // f = the parameter to be processed by callFunc above
    var obj, route, menu;

    obj = JSON.parse(JSON.stringify(items));
    traverseMenu(obj, function(item){
        item.t = item.label;
        item.hid = t.commonService.mkId(item.label);    // fix for Cypress

        if (item.roles == '*'){
                item.io = '*';
        }else{
           if (item.roles.length == 1) {
                item.io = (item.roles[0] == 'public')
                        ? 'o'
                        : 'i';
           }else{  // more than one role
                if (item.roles.indexOf('public') >= 0){
                        item.io = '*';
                }else{
                        item.io = 'i';
                }
           }
        }

        item.r = item.roles;
        if (item.path.startsWith('/frontend/index-ng.html?')){   // for Angular
            route = item.path.replace('/frontend/index-ng.html?', '');
            route = route.replace('page=','');
            item.f = 'r ' + route;
        }else{
            if (item.cryptocard){
                item.f = 're '+t.ALCFPasscodeTokensURL;
            }else{                                              // for Angular 1
                route = item.path.replace('/#!/','');
                if (route == '/#!') route = '/';
                item.f = (route != '') ? 'r1 ' + route  : '' ;
            }
        }
        delete item['label'];
        delete item['roles'];
        delete item['path'];
        delete item['init'];
    });
    menu = trimMenu(obj, user);
    obj = {ng: 10};
    traverseMenu(menu, function(item){
        obj[t.commonService.toDashCase(item.t)] = item.f;
    });
    t.state.menuPaths = JSON.stringify(obj); // so that Cypress is able to know how to navigate the menu
    return menu;
}


// this function is not used, it is here for illustration purposes
// so we can see how the menu definition should look after we 
// are done porting every screen to angular 9
function defineMenuALCF(user){

    let d       = ['domainAdmin'];
    let pl      = ['public','limited'];
    let pd      = ['piproxy', 'domainAdmin'];
    let updo    = ['user', 'piproxy', 'domainAdmin', 'owner'];
    var cryptoLink = t.ALCFPasscodeTokensURL;

    let menuItems =[
        {t:'Home'                         ,io: '*', r: '*' , f:'r1 '},
        {t:'Request an Account'           ,io: 'o', r: pl  , f:'r1 accountRequest'},
        {t:'Reactivate an Account'        ,io: 'o', r: pl  , f:'r1 accountReactivate'},
        {t:'Allocation Request'           ,io: 'o', r: pl  , f:'r  allocationRequest'},
        {t:'ALCF Passcode Token Help'     ,io: 'o', r: pl  , f:'re ' + cryptoLink},
        {t:'Account Management'           ,io: 'i', r: updo, f:''                   , sub:[
          {t: 'Update My Account'         ,io: 'i', r: updo, f:'r1 accountUpdate'}        ]},
        {t:'Project & Resource Managament',io: 'i', r: updo, f:'',                    sub:[
          {t:'Project Management'         ,io: 'i', r: pd  , f:'r1 manageProjects'},
          {t:'Join project'               ,io: 'i', r: updo, f:'r1 joinProject'},
          {t:'Request and view systems'   ,io: 'i', r: updo, f:'r1 manageResources'},
          {t:'Manage UNIX Groups'         ,io: 'i', r: updo, f:'r1 manageUnixGroups'},
          {t:'Request an Allocation'      ,io: 'i', r: updo, f:'r  allocationRequest'}    ]},
        {t:'Administration'               ,io: 'i', r: d  ,  f:'',                    sub:[
          {t:'User Administration'        ,io: 'i', r: d   , f:'r1 domainAdmin'},
          {t:'Create Other Accounts'      ,io: 'i', r: d   , f:'r1 createOtherAccounts'},
          {t:'Project Administration'     ,io: 'i', r: d   , f:'r1 projectsAdmin'},
          {t:'ACL Administration'         ,io: 'i', r: d   , f:'r1 aclAdmin'},
          {t:'Institutions'               ,io: 'i', r: d   , f:'r1 institutions'},
          {t:'Logs'                       ,io: 'i', r: d   , f:'r1 logsAdmin'},
          {t:'Plugins'                    ,io: 'i', r: d   , f:'r1 pluginsAdmin'},
          {t:'Identity Merge'             ,io: 'i', r: d   , f:'r1 identityMerge'},
          {t:'API Developer Interface'    ,io: 'i', r: d   , f:'r1 apiDevInt'},
          {t:'Other Screens'              ,io: 'i', r: d   , f:'r svcApi'}                ]},
    ];
    return trimMenu(menuItems, user);
}

function trimMenu(items, user){
    var item, i, obj, j, topItem, subItem ;
    obj = JSON.parse(JSON.stringify(items));

    traverseMenu(obj, function(item){
        item.disp = false;
        if (item.t.includes('%name%')){
            item.t = user.givenName;
        }
    });

    // now mark the good ones with display
    traverseMenu(obj, function(item){
        if (user.signedIn){
            if ((item.r=='*' || (item.r.indexOf(user.role) >= 0)) && (item.io=='i' || item.io=='*')){
                item.disp = true;
            }
        }else{
            if ((item.r=='*' || (item.r.indexOf(user.role) >= 0)) && (item.io=='o' || item.io=='*')){
                item.disp = true;
            }
        }
    });

    // now remove the ones to not be displayed
    i = obj.length;
    while (i--) {

        // first try and remove the children that are to not be displayed
        topItem = obj[i];
        if (topItem.sub && (topItem.sub.length > 0)){
            j = topItem.sub.length;
            while (j--) {
                    subItem = topItem.sub[j];
                    if (!subItem.disp) { 
                        // ub3consoleLog('433 trying to remove', {j:j, subItem:subItem});
                        topItem.sub.splice(j, 1);
                    } 
            }
        }

        // now see if this top menu item needs to be removed
        item = obj[i];
        if (!item.disp) { 
            obj.splice(i, 1);
        } 
    }

    return obj;
}

function traverseMenu(obj, processFunc){
    var i, item, item2, i2;
    for (i in obj){
        item = obj[i];
        processFunc(item);
        if (item.sub){
            for (i2 in item.sub){
                item2 = item.sub[i2];
                processFunc(item2);
            }
        }
    }
}

