 

 <div id="dataSurveyClosureMsg" class="closure-msg" *ngIf="!enableDataSurvey">
    The FY {{fiscalYear}} 'DOE/SC user projects database - Annual Data Call' is now closed.
    Please email support@alcf.anl.gov with questions or concerns.
 </div>

 <div id="doeDataSurvey" *ngIf="enableDataSurvey"
       class="reactivate-main-page doe-data-survey" 
       ng-init="initAccountReactivateForm('Reactivate an Account', true)">

    <div *ngIf="flags.reactivateAccount && !flags.reactivateSubmitted">
       <div class="reversed-white">
          <h1>DOE Data Survey</h1>
       </div>
       
       <limited-login 
              *ngIf="(flags.level < 6) && (!doneWithLimitedLogin)" 
              [(flags)]="flags" 
              [(user)]="user" 
              [after]="afterLimitedLogin">
       </limited-login>
    </div>
 
    <div class="update-account" *ngIf="doneWithLimitedLogin && !flags.reactivateSubmitted">
        <account-edit  
            option="dataSurvey" 
            [userName]="userName" 
            [(user)]="user">
        </account-edit>
    </div>

    <ng-container id="doe-data-survey-complete" *ngIf="flags.reactivateSubmitted" >
        <div class="reversed-white"><h1>DOE Data Survey Submitted</h1></div>
        <div class="round-corner request-complete">
            <strong ng-if="flags.needNewAccount !== true">Thank you for submitting DOE Data Survey</strong>
        <br><br>
            <span>Your ALCF account survey has been received. </span>
            <br><br>
            If you have any questions or concerns, please contact the accounts team at  
            <a href="mailto:accounts@alcf.anl.gov"> accounts@alcf.anl.gov. </a>  

            <br><br>
            Thanks!
            <div class="sign-out">
                <button class="btn btn-success btn-lg"
                        id="go-to-home-page"
                        (click)="goHome()">
                    HOME
                </button>
            </div>
        </div>
        <br>
    </ng-container>
 
    <div>
       <div class="alert alert-warning" *ngIf="flags.err1 && flags.reactivateAccount && flags.reactivateSubmitted">
          <a href="" ng-click="flags.error = !flags.error" class="close">&times;</a>
          <strong ng-bind="flags.error"></strong>
          <div>
              Please email 
              <span ng-bind="lists.domainGlobals.helpDeskEmail"></span> 
              or call 
              <span ng-bind="lists.domainGlobals.helpDeskTitle"></span> 
              at 
              <span ng-bind="lists.domainGlobals.helpDeskPhoneString"></span>
          </div>
       </div>
    </div>
 </div>
 
