import { Router, CanActivate, CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import {CommonService} from '@common/services';

let t = null;

@Injectable({providedIn: 'root'})
class AuthGuard {
  state: any = {};
  constructor(private router: Router,
              private commonService: CommonService
  ) {
    t = this;
    t.state = t.commonService.getSharedObject('app', 'state');
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      var currPg = state.url.replace('/','').split('?')[0];
      return t.checkAccessRights(currPg);
  }
  checkAccessRights(currPg){
    if(t.state.user.signedIn) {
      var menus = t.state && t.state.menuPaths && t.commonService.getMenuItems(t.state);
      if(menus && menus.includes(currPg)) {
        return true;
      } else {
        t.router.navigateByUrl('/home');
        return false;
      }
    } else {
      return true;
    }
  }
}

export const AuthGuardService: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuard).canActivate(route, state);
}
