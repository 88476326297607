
<ng-container *ngIf="opt && (opt == 'titles')">  <!-- table header row -->
    <tr id="{{name}}-topselector">               <!-- show/hide column row -->
        <td id="make-this-td-wide" align="left">
            <button
                class="btn btn-primary btn-xs pointer"
                id="{{name}}-{{filtDisplay}}-filter"
                (click)="showOrHideFilters()">
                {{filtDisplay}} column filters
            </button>
        </td>
    </tr>
    <tr id="{{name}}-1st-row" [ngClass]="{'btmBrd': showFilter}">                      <!-- row with the actual titles -->
        <th *ngFor="let td of titles" class="titleTh">
            <span id="{{td.id}}" (click)="sortToggle(td.id)" class="title">
                {{td.label}}
                <span *ngIf="td.sort" style="display: inline-block;">
                    <span class="fa fa-solid fa-sort" *ngIf="!td.dir || (td.dir == 'none')"></span>
                    <span class="fa fa-chevron-down"  *ngIf="td.dir == 'up'"></span>
                    <span class="fa fa-chevron-up"    *ngIf="td.dir == 'down'"></span>
                </span>
            </span>
        </th>
    </tr>
    <tr id="{{name}}-filter-row" [ngClass]="{'topBrd': showFilter, 'hidden': !showFilter}">
        <th *ngFor="let td of titles" class="titleTh">
            <span *ngIf="showFilter && td.doFilter" style="white-space: nowrap;">
                <input 
                    type="text" 
                    [(ngModel)]="td.filter" 
                    id="{{name}}-{{td.id}}-filter" 
                    (ngModelChange)="applyFilter(td.id)"
                    class="filtBox">
            </span>
        </th>
    </tr>
</ng-container>

<!-- table footer for paging -->
<ng-container *ngIf="opt && (opt == 'paging')" align="left">
    <ng-container *ngIf="maxPerPage && pages && (pages.length > 1)">
        <span *ngIf="currentPage == 0">
            <button 
                id="{{name}}-goFL"
                class="btn btn-xs btn-primary pagingBtn">
                <i class="fa fa-angle-double-left pointer lgFont ml0"></i>
            </button>
            <button 
                id="{{name}}-go1L"
                class="btn btn-xs btn-primary pagingBtn">
                <i class="fa fa-chevron-left pointer ml0"></i>
            </button>
        </span>
        <span *ngIf="currentPage > 0">
            <button 
                id="{{name}}-goFL"
                (click)="goToPage(0)"
                class="btn btn-xs btn-primary pagingBtn">
                <i class="fa fa-angle-double-left pointer lgFont ml0"></i>
            </button>
            <button 
                id="{{name}}-go1L"
                (click)="goToPage(currentPage - 1)"
                class="btn btn-xs btn-primary pagingBtn">
                <i class="fa fa-chevron-left pointer ml0"></i>
            </button>
        </span>
        <span *ngFor="let p of pages; let i = index;">
            <button 
                *ngIf="currentPage == i"
                id="{{name}}-pg-{{i+1}}"
                class="btn btn-success btn-xs pagingBtn pointer">
                {{i + 1}}
            </button>
            <button 
                *ngIf="currentPage != i"
                id="{{name}}-pg-{{i+1}}"
                class="btn btn-primary btn-xs pagingBtn pointer"
                (click)="goToPage(i)">
                {{i + 1}}
            </button>
        </span>
        <span *ngIf="currentPage >= (pages.length - 1)">
            <button 
                id="{{name}}-go1R"
                class="btn btn-xs btn-primary pagingBtn">
                <i class="fa fa-chevron-right pointer ml0"></i>
            </button>
            <button 
                id="{{name}}-goFR"
                class="btn btn-xs btn-primary pagingBtn">
                <i class="fa fa-angle-double-right pointer lgFont ml0"></i>
            </button>
        </span>
        <span *ngIf="currentPage < (pages.length - 1)">
            <button 
                id="{{name}}-go1R"
                (click)="goToPage(currentPage + 1)"
                class="btn btn-xs btn-primary pagingBtn">
                <i class="fa fa-chevron-right pointer ml0"></i>
            </button>
            <button 
                id="{{name}}-goFR"
                (click)="goToPage(pages.length - 1)"
                class="btn btn-xs btn-primary pagingBtn">
                <i class="fa fa-angle-double-right pointer lgFont ml0"></i>
            </button>
        </span>
        <span class="rowsPerPg">
            <ng-container *ngIf="!filtered">
                {{pgStart}} - {{pgEnd}} of {{totRows}}
            </ng-container>
            <ng-container *ngIf="filtered">
                {{pgStart}} - {{pgEnd}} of {{totFilteredRows}} filtered from {{totRows}}
            </ng-container>
        </span>
    </ng-container>
</ng-container>
