import { Component, OnInit } from '@angular/core';
import {CommonService, Ub3httpService} from '@common/services';
import {Router} from '@angular/router';
import { GlobalConstants } from '@common/constants/global-constants';

var t = null;
let http = null;
var ub3consoleLog = null;
var appFunctions;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  state = null;
  domainGlobals = null;
  dispLogin = false;
  flags = {
    callHelpDesk : false,
    helpReqd: 'none'
  };
  form = {};
  lists = {};
  accessDenied = false;
  publicPage(b) { };
  ALCFPasscodeTokensURL = GlobalConstants.ALCFPasscodeTokensURL;

  constructor(
                private commonService: CommonService,
                private router: Router,
                public h: Ub3httpService
  ){ 
    t = this ;
    http = h;
    ub3consoleLog = t.commonService.ub3consoleLog;
    t.state         = t.commonService.getSharedObject('app', 'state');
    t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
    appFunctions    = t.commonService.getSharedObject('app', 'functions');
  }

  ngOnInit(): void {
    document.body.classList.add('public-page');
    t.commonService.doWhenAPICallsDone(function(){
        var aPublicPage = 
          (t.state.page == '/allocationRequests') ||
          (t.state.page == '/accountReactivate') ||
          (t.state.page == '/dataSurvey') ||
          (t.state.page == '/accountRequest') ;

        t.dispLogin = t.state && (!t.state.user || !t.state.user.signedIn) && (!aPublicPage) ;

    });
  }

  signInOnEnter(kEvent, uname, pwd){
    if (kEvent.which != 13){
        t.accessDenied = false;
        t.flags.callHelpDesk = false;
        return;
    }
    var ready = ((uname != undefined) && (uname != null) && (uname != ''));
    ready = (ready && (pwd != undefined) && (pwd != null) && (pwd != ''));
    if (!ready){
        t.accessDenied = true;
        t.flags.callHelpDesk = true;
        return;
    }
    var menus = [];
    t.commonService.signInNg(uname, pwd, t, function(success){
        if (success) {
          t.router.navigateByUrl('/home');
          if (t.state.page == '/null') t.state.page = '/home';
          t.state.displayRoutedPage = true;
          t.state.dispHome = !t.state.displayRoutedPage;
          t.state.dispUrl =  t.state.page;
          // checking for query parameters in the URL at the time of login
          if(t.state.queryUrlParams){
            t.state.dispUrl = t.state.queryUrlParams;
          }
          t.state.callHelpDesk = false;
          t.flags.helpReqd = 'none';
          //window.location.reload();   // do not do reload(), its the lazy way. It causes problems with Cypress 12 and above
          // the only thing missing was to setup the menu items,  this call below does that
          appFunctions.setUser();
          t.commonService.doWhenAPICallsDone(function(){
            if(t.state.user.signedIn){
              menus = t.state && t.state.menuPaths && t.commonService.getMenuItems(t.state);
              var currPg = t.state.dispUrl.split('?')[0].substring(1);
              var hasAccess = (menus.includes(currPg));
              t.state.dispUrl = hasAccess ? t.state.dispUrl : '/home';
            }
            if(currPg === 'manageProjectsEdit') {
              t.managePjPg(t.state.dispUrl);
            } else {
              window.location.hash = t.state.dispUrl;
            }
          });
        } else {
          t.state.callHelpDesk = true;
        }
    });
  }
  managePjPg(pjEditUrl) {
    var pjNameInUrl = pjEditUrl.split('?name=')[1];
    // If there's no project name in the URL, go to project list page
    var pjListUrl = '/manageProjects';
    if(pjNameInUrl === undefined || pjNameInUrl === null){
      window.location.hash = pjListUrl;
    } else {
      // check if you are a PI for the project in the URL
      t.commonService.getProxyProjects(http, t.lists,function(lists){
        var hasPj = lists.proxyProjects && lists.proxyProjects.findIndex(function(pj){
                      return pj.projectShortName === pjNameInUrl;
                    })>=0;
        // If the project exists , go to the edit page
        // if the project doesn't belong to the PI, go to the project management page
        var url = (hasPj) ? pjEditUrl : pjListUrl;
        window.location.hash = url;
      });
    }
  }

}
