

<div class="manage-resources" >
    <div class="reversed-white">
       <h1>{{flags.displayedPageName}}</h1>
       <p>Request to use additional systems.</p>
    </div>
    <div class="ub3-card">
       <div class="card-body">
          <div class="alert alert-danger" *ngIf="lists.systemFailure">{{lists.systemFailure}}</div>
          <div *ngIf="flags.systemError" class="alert alert-info" dismiss-on-timeout="5000"><b>{{flags.systemError}}</b></div>
          <div *ngIf="lists.allSystems">
             <table id="pjTable" class="table update-account">
                <thead class="bg1">
                   <th colspan="12">
                      <h4 class="table-heading">Available Resources</h4>
                   </th>
                </thead>
                <tbody>
 
                   <tr *ngIf="listHasActive"  class="whiteBg">
                      <td colspan="12"><b>Current: </b>
                         <ng-container *ngFor="let active of lists.allSystems" >
                            <span 
                                *ngIf="active.memberStatus == 'Active'" 
                                id="{{active.name}}">
                               {{active.name}}{{!$last ? ',' : ''}}
                            </span>                        
                         </ng-container>
                      </td>
                   </tr>
                   <tr *ngIf="listHasPending">
                      <td colspan="12"><b>Pending Approval: </b>
                         <ng-container  *ngFor="let pending of lists.allSystems" >
                            <span *ngIf="pending.memberStatus == 'Requested'">
                                {{pending.name}}{{!$last ? ',' : ''}}
                            </span>                     
                         </ng-container>
                      </td>
                   </tr>
                   <tr colspan="12" *ngIf="listHasSystem">
                      <th colspan="4">
                         Name
                      </th>
                      <th colspan="4">
                         Description
                      </th>
                      <th colspan="4"></th>
                   </tr>
                   <ng-container *ngFor="let s of lists.allSystems">
                      <ng-container colspan="12" *ngIf="s.memberStatus === null">
                        <tr  class="whiteBg">
                            <td colspan="4">{{s.name}}</td>
                            <td colspan="4">{{s.description}}</td>
                            <td colspan="4">
                                <button class="btn btn-success btn-sm" (click)="requestSystem(s.id)" id="request_{{s.id}}"> 
                                    REQUEST
                                </button>
                            </td>
                        </tr>
                      </ng-container>
                   </ng-container>
                </tbody>
             </table>     
          </div>
          <div *ngIf="!flags.isYourGroupOpen">
            <ng-container *ngFor="let alert of authAlerts">
                <font *ngIf="alert.type == 'success'" color=green>
                    {{alert.msg}}
                </font>
                <font *ngIf="alert.type == 'danger'" color=red>
                    {{alert.msg}}
                </font>
                <br>
            </ng-container>
          </div>
       </div>
    </div>
 </div>
 
