

<div class="account-request">

    <!-- LIMITED LOGIN SECTION -->
    <div id="limited-login-section"
         *ngIf="flags.needNewAccount && !flags.accountRequestDone">

        <div class="reversed-white">
            <h1 id="accReqMsg">Apply for an ALCF account</h1>
            <p *ngIf="flags.level < 3" class="accReqMsg">
                Let's start off with the basics. 
                Enter your essential information and we'll send you an access 
                code to confirm your email address
            </p>
        </div>
        <limited-login 
            *ngIf="flags.level < 6"
            [(flags)]="flags"
            [(user)]="user"
            [after]="afterLimitedLogin"
            >
        </limited-login>
    </div>
    
    <table class="next-step" *ngIf="flags.level >= 6"><tr><td align=left>

        <!-- NAME AND EMAIL SECTION, below is project selection -->
        <div id="email-and-name-section"
             class="card ub3-card"
             *ngIf="(flags.level >= 6)">

            <div class="card-body" >

                <h2>Name and Email</h2>

                <div class="form-group floating-label-wrapper clear">
                    <input type="text"
                        class="form-control inBox w450"
                        id="email"
                        placeholder=" "
                        [(ngModel)]="form.email"
                        ngDefaultControl
                        [disabled]="true">
                    <label for="email" class="inputLbl">Email</label>
                    <warning fid="email"></warning>
                </div>

                <div class="form-group floating-label-wrapper">
                    <input type="text"
                        class="form-control inBox w450"
                        id="altEmail"
                        placeholder=" "
                        [(ngModel)]="form.altEmail"
                        (change)="validateDelayed()"
                        ngDefaultControl>
                    <label for="altEmail" class="inputLbl">Alternate Email (optional)</label>
                    <warning fid="altEmail"></warning>
                </div>

                <div class="form-group floating-label-wrapper">
                    <input type="text"
                        class="form-control inBox w450"
                        id="firstName"
                        placeholder=" "
                        [(ngModel)]="form.firstName"
                        (change)="validateDelayed()">
                    <label for="firstName" class="inputLbl">Legal First/Given Name</label>
                    <warning fid="firstName"></warning>
                </div>

                <div class="form-group floating-label-wrapper">
                    <input type="text"
                            class="form-control inBox w450"
                            id="middleName"
                            placeholder=" "
                            [(ngModel)]="form.middleName"
                            (change)="validateDelayed()"
                            maxlength="40" >
                    <label for="middleName" class="inputLbl">Middle Name</label>
                    <span class="cBox">
                        <input type="checkbox"
                            [(ngModel)]="form.nmi"
                            id="nmi"
                            class="align-checkbox"
                            (ngModelChange)="validateNow(form)">
                        <span class="align-text">No Middle Name</span>
                    </span>
                    <warning fid="middleName"></warning>
                </div>

                <div class="form-group floating-label-wrapper">
                    <input type="text"
                        class="form-control inBox w450"
                        id="lastName"
                        placeholder=" "
                        [(ngModel)]="form.lastName"
                        (change)="validateDelayed()">
                    <label for="lastName" class="inputLbl">Legal Last/Family Name (surname)</label>
                    <warning fid="lastName"></warning>
                </div>

                <div class="form-group floating-label-wrapper">
                    <input type="text"
                        class="form-control inBox w450"
                        id="preferredName"
                        placeholder=" "
                        [(ngModel)]="form.preferredName"
                        (change)="validateDelayed()"
                        (blur)="blurPrefName()">
                    <label for="preferredName" class="inputLbl">Preferred Full Name</label>
                    <warning fid="preferredName"></warning>
                </div>
            </div>
        </div>

        <!-- NEXT STEP SECTION -->
        <div class="sub-section" *ngIf="flags.level >= 5">
            <h1>Next Steps</h1>
            <p>Enter the desired username, find the name of your project, enter your work and shipping address, and enter the name of your employer.</p>
        </div>

        <div class="ub3-card" *ngIf="flags.level >= 6">
            <div class="card-body">
                <h2>Username</h2>
                <div class="form-group floating-label-wrapper">
                    <input  type="text" name="userName"
                        class="form-control inBox w450"
                        id="userNameLoginPage"
                        placeholder=" "
                        [(ngModel)]="form.userName"
                        (keyup)="validateDelayed('userName')">
                    <label for="userNameLoginPage" class="inputLbl">Desired Username</label>
                    <warning fid="userName"></warning>
                    <span class="uname_guidelines">
                        Please be aware that personally identifiable information (such as SSN's, birthdates, Passport numbers, etc.) included in usernames will be viewable by others.
                    </span>
                </div>
                <h2>Name of your ALCF Project(s)</h2>

                <!-- FIND PROJECT SECTION -->
                <div>
                    <select-projects
                                    [(form)]="form"
                                    field="projectShortNameList"
                                    [projects]="lists.activeProjects"
                                    [max]=5
                                    [after]="afterPjSelect">
                    </select-projects>

                </div>
            </div>
        </div>
        <div class="card ub3-card" *ngIf="flags.level >= 7">
            <div class="card-body">

                <!-- BIG SECTION WITH MOST OF THE FIELDS -->
                <span class="mt20">

                    <!-- PHONE NUMBERS -->
                    <ng-container id="phone-numbers">

                        <h2>Phone Number</h2>
                        <table>
                            <tr>
                                <td>
                                    <div class="form-group floating-label-wrapper">
                                        <input
                                                type="text"
                                                id="phoneCountry"
                                                autocomplete="nope"
                                                [(ngModel)]="form.phoneCountry"
                                                (change)="validateDelayed()"
                                                (blur)="validateNow()"
                                                (focus)="onFocus($event)"
                                                ngDefaultControl
                                                [ngbTypeahead]="searchCountry"
                                                [editable]="false"
                                                class="form-control inBox w450"
                                                placeholder=" ">
                                        <label for="phoneCountry" class="inputLbl">Country Name</label>
                                        <warning fid="phoneCountry"></warning>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group floating-label-wrapper">
                                        <input
                                                type="text"
                                                [disabled]="true"
                                                id="phoneDialCode"
                                                autocomplete="nope"
                                                style="width:110px"
                                                class="form-control inBox"
                                                [(ngModel)]="flags.phoneDialCode"
                                                ngDefaultControl
                                                placeholder=" ">
                                        <label for="phoneDialCode" class="inputLbl">Code</label>
                                        <warning fid="phoneDialCode"></warning>
                                    </div>
                                </td>
                                <td class="number phone-number">
                                    <div class="form-group floating-label-wrapper">
                                        <input
                                            type="text"
                                            id="phoneNumber"
                                            style="width:200px"
                                            [(ngModel)]="form.phoneNumber"
                                            (change)="validateDelayed()"
                                            class="form-control inBox"
                                            placeholder=" ">
                                        <label for="phoneNumber" class="inputLbl">Number</label>
                                        <warning fid="phoneNumber"></warning>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group floating-label-wrapper">
                                        <input  type="text"
                                                id="phoneExtension"
                                                style="width:80px"
                                                [(ngModel)]="form.phoneExtension"
                                                (change)="validateDelayed()"
                                                class="form-control inBox"
                                                placeholder=" "
                                                >
                                        <label for="phoneExtension" class="inputLbl">Ext</label>
                                        <warning fid="phoneExtension"></warning>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group floating-label-wrapper">
                                        <select [(ngModel)]="form.phoneLabel"
                                                id="phoneLabel"
                                                (change)="validateNow()"
                                                class="form-control"
                                                style="width:100px">
                                            <option value="" [disabled]="true">Type</option>
                                            <option value='Mobile'>Mobile</option>
                                            <option value='Work'>Work</option>
                                            <option value='Home'>Home</option>
                                        </select>
                                        <warning fid="phoneLabel"></warning>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </ng-container>

                    <!-- WORK ADDRESS -->
                    <ng-container id="work-adress">
                        <h2 class="clear">Work address</h2>
                        <div class="form-group floating-label-wrapper clear">
                            <input type="text"
                                class="form-control inBox w450"
                                id="workCountry"
                                autocomplete="nope"
                                [(ngModel)]="form.workCountry"
                                (ngModelChange)="validateDelayed()"
                                (focus)="zipHint = true; onFocus($event, form.workCountry)"
                                (blur)="zipHint=false"
                                placeholder=" "
                                [ngbTypeahead]="searchCountry"
                                [editable]="false">
                            <label for="workCountry" class="inputLbl">Country</label>
                            <warning fid="workCountry"></warning>
                        </div>

                        <div *ngIf="form.workCountry=='United States'" class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="workZip"
                                autocomplete="nope"
                                [disabled]="!form.workCountry"
                                placeholder=" "
                                [(ngModel)]="form.workZip"
                                (change)="arqLocateByZip('work'); validateDelayed();">
                            <label for="workZip" class="inputLbl">Zip Code</label>
                            <warning fid="workZip"></warning>
                        </div>

                        <div class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="workStreet1"
                                [disabled]="!form.workCountry"
                                placeholder=" "
                                [(ngModel)]="form.workStreet1"
                                (change)="validateDelayed()">
                            <label for="workStreet1" class="inputLbl">Address Line 1</label>
                            <warning fid="workStreet1"></warning>
                        </div>

                        <div class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="workStreet2"
                                [disabled]="!form.workCountry"
                                placeholder=" "
                                [(ngModel)]="form.workStreet2"
                                (change)="validateDelayed()">
                            <label for="workStreet2" class="inputLbl">Address Line 2 (optional)</label>
                            <warning fid="workStreet2"></warning>
                        </div>

                        <div class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="workStreet3"
                                [disabled]="!form.workCountry"
                                placeholder=" "
                                [(ngModel)]="form.workStreet3"
                                (change)="validateDelayed()">
                            <label for="workStreet3" class="inputLbl">Address Line 3 (optional)</label>
                            <warning fid="workStreet3"></warning>
                        </div>

                        <div *ngIf="form.workCountry=='United States'" class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="workCity"
                                autocomplete="nope"
                                [disabled]="!form.workCountry"
                                placeholder=" "
                                [(ngModel)]="form.workCity"
                                (change)="validateDelayed()">
                            <label for="workCity" class="inputLbl">City</label>
                            <warning fid="workCity"></warning>
                        </div>

                        <div *ngIf="form.workCountry!='United States'" class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="workCity"
                                autocomplete="nope"
                                [disabled]="!form.workCountry"
                                placeholder=" "
                                [(ngModel)]="form.workCity"
                                (change)="validateDelayed()">
                            <label for="workCity" class="inputLbl">City/Town/Locality</label>
                            <warning fid="workCity"></warning>
                        </div>

                        <div *ngIf="form.workCountry=='United States'" class="form-group floating-label-wrapper">
                            <input          type="text" 
                                            id="workState"
                                            autocomplete="nope"
                                            style="width:450px"
                                            [disabled]="!form.workCountry"
                                            [(ngModel)]="form.workState" 
                                            (ngModelChange)="validateDelayed()"
                                            (blur)="zipHint = false; validateNow();"
                                            placeholder=" "
                                            [ngbTypeahead]="searchState"
                                            class="form-control inBox w450"
                                            (focus)="zipHint = true" 
                                            >
                            <label for="workState" class="inputLbl">State</label>
                            <warning fid="workState"></warning>
                        </div>

                        <div *ngIf="form.workCountry!='United States'" class="form-group floating-label-wrapper">
                            <input type="text"
                                id="workState"
                                autocomplete="nope"
                                [disabled]="!form.workCountry"
                                [(ngModel)]="form.workState"
                                (change)="validateDelayed()"
                                (blur)="zipHint = false; validateNow();"
                                placeholder=" "
                                class="form-control inBox w450"
                                (focus)="zipHint = true">
                            <label for="workState" class="inputLbl">State/Province Region</label>
                            <warning fid="workState"></warning>
                        </div>
                        <div *ngIf="form.workCountry!='United States'" class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="workZip"
                                autocomplete="nope"
                                [disabled]="!form.workCountry"
                                placeholder=" "
                                [(ngModel)]="form.workZip"
                                (change)="validateDelayed()">
                            <label for="workZip" class="inputLbl">Zip/Postal Code</label>
                            <warning fid="workZip"></warning>
                        </div>
                    </ng-container>

                    <!-- SHIPPING ADDRESS -->
                    <ng-container id="shipping-address">
                        <h2>Shipping address</h2>
                        <span>
                            <input  type=checkbox 
                                    id="sameAsWork" 
                                    [(ngModel)]="form.copy_address" 
                                    (change)="validateNow()">
                            <span class="font18">Same as work address</span>
                        </span> 

                        <div class="form-group floating-label-wrapper">
                            <input  type="text" alt=""
                                    id="shippingCountry"
                                    placeholder=" "
                                    class="form-control inBox w450"
                                    [(ngModel)]="form.shippingCountry"
                                    (ngModelChange)="validateDelayed()"
                                    [editable]="false"
                                    (focus)="onFocus($event, form.shippingCountry);zipHint = true;"
                                    [disabled]="form.copy_address"
                                    [ngbTypeahead]="searchCountry"
                                    (blur)="zipHint = false">
                            <label for="shippingCountry" class="inputLbl">Country name</label>
                            <warning fid="shippingCountry"></warning>
                        </div>

                        <div *ngIf="form.shippingCountry=='United States'" class="form-group floating-label-wrapper">
                            <input type="text" class="form-control inBox w450"
                                id="shippingZip"
                                [disabled]="form.copy_address || !form.shippingCountry"
                                placeholder=" "
                                [(ngModel)]="form.shippingZip"
                                (change)="arqLocateByZip('shipping'); validateDelayed();">
                            <label for="shippingZip" class="inputLbl">Zip Code</label>
                            <warning fid="shippingZip"></warning>
                        </div>

                        <div class="form-group floating-label-wrapper">
                            <input
                                id="shippingStreet1"
                                type="text"
                                placeholder=" "
                                class="form-control inBox w450"
                                [disabled]="form.copy_address || !form.shippingCountry"
                                [(ngModel)]="form.shippingStreet1"
                                (change)="validateDelayed()">
                            <label for="shippingStreet1" class="inputLbl">Address Line 1</label>
                            <warning fid="shippingStreet1"></warning>
                        </div>

                        <div class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="shippingStreet2"
                                [disabled]="form.copy_address || !form.shippingCountry"
                                placeholder=" "
                                [(ngModel)]="form.shippingStreet2"
                                (change)="validateDelayed()">
                            <label for="shippingStreet2" class="inputLbl">Address Line 2 (optional)</label>
                            <warning fid="shippingStreet2"></warning>
                        </div>

                        <div class="form-group floating-label-wrapper">
                            <input type="text"
                                class="form-control inBox w450"
                                id="shippingStreet3"
                                [disabled]="form.copy_address || !form.shippingCountry"
                                placeholder=" "
                                [(ngModel)]="form.shippingStreet3"
                                (change)="validateDelayed()">
                            <label for="shippingStreet3" class="inputLbl">Address Line 3 (optional)</label>
                            <warning fid="shippingStreet3"></warning>
                        </div>

                        <div *ngIf="form.shippingCountry=='United States'"  class="form-group floating-label-wrapper">
                            <input type="text" class="form-control inBox w450"
                                id="shippingCity"
                                autocomplete="nope"
                                [disabled]="form.copy_address || !form.shippingCountry"
                                placeholder=" "
                                [(ngModel)]="form.shippingCity"
                                (change)="validateDelayed()">
                            <label for="shippingCity" class="inputLbl">City</label>
                            <warning fid="shippingCity"></warning>
                        </div>
                        <div *ngIf="form.shippingCountry!='United States'"  class="form-group floating-label-wrapper">
                            <input type="text" class="form-control inBox w450"
                                id="shippingCity"
                                autocomplete="nope"
                                [disabled]="form.copy_address || !form.shippingCountry"
                                placeholder=" "
                                [(ngModel)]="form.shippingCity"
                                (change)="validateDelayed()">
                            <label for="shippingCity" class="inputLbl">City/Town/Locality</label>
                            <warning fid="shippingCity"></warning>
                        </div>

                        <div *ngIf="form.shippingCountry=='United States'" class="form-group floating-label-wrapper">
                                <input     type="text"
                                            id="shippingState" 
                                            autocomplete="nope"
                                            style="width:450px"
                                            [disabled]="form.copy_address || !form.shippingCountry"
                                            [(ngModel)]="form.shippingState" 
                                            (ngModelChange)="validateDelayed()"
                                            (blur)="zipHint = false; validateNow();"
                                            [ngbTypeahead]="searchState"
                                            placeholder=" " 
                                            class="form-control inBox w450"
                                            (focus)="zipHint = true" 
                                            >
                                <label for="shippingState" class="inputLbl">State</label>

                                <warning fid="shippingState"></warning>
                        </div>
                        <div *ngIf="form.shippingCountry!='United States'" class="form-group floating-label-wrapper">
                            <input type="text"
                                id="shippingState"
                                autocomplete="nope"
                                [disabled]="form.copy_address || !form.shippingCountry"
                                [(ngModel)]="form.shippingState"
                                (change)="validateDelayed()"
                                (blur)="zipHint = false; validateNow();"
                                placeholder=" "
                                class="form-control inBox w450"
                                (focus)="zipHint = true">
                            <label for="shippingState" class="inputLbl">State</label>
                            <warning fid="shippingState"></warning>
                        </div>

                        <div *ngIf="form.shippingCountry!='United States'" class="form-group floating-label-wrapper">
                            <input type="text" class="form-control inBox w450"
                                id="shippingZip"
                                [disabled]="form.copy_address || !form.shippingCountry"
                                placeholder=" "
                                [(ngModel)]="form.shippingZip"
                                (change)="validateDelayed();">
                            <label for="shippingZip" class="inputLbl">Zip Code</label>
                            <warning fid="shippingZip"></warning>
                        </div>

                        <script type="text/ng-template" id="abrStateSelect.html">
                        <a>
                            <span ng-bind-html="match.model.name | uibTypeaheadHighlight:query">
                            </span>
                        </a>
                        </script>

                    </ng-container>

                    <!-- EMPLOYERS -->
                    <ng-container id="employers">

                        <h2>
                            Employer{{form.affiliationList.length > 1 ? 's' : ''}}/Affiliation{{form.affiliationList.length > 1 ? 's' : ''}}
                            <br><font size=-1>(The institution that is responsible for you and your work at the ALCF. Select 'Individual' if self-employed.)</font>
                        </h2>
                        <!-- <div class="customHr">-</div> -->
                        <div>
                            <div *ngFor="let af of form.affiliationList; let i = index">
                                <!-- <span> -->
                                    <span class="form-group floating-label-wrapper">
                                        <input
                                            [disabled]="form.affiliationList[i].inSFL"
                                            type="text"
                                            autocomplete="nope"
                                            (ngModelChange)="validateDelayed()"
                                            [(ngModel)]="form.affiliationList[i].name"
                                            id="afname{{i}}"
                                            placeholder="Employer"
                                            [ngbTypeahead]="searchInst"
                                            style="width: 65%;"
                                            class="form-control">
                                    </span>
                                <!-- </span> -->
                                <span class="delete">
                                    <span *ngIf="form.affiliationList.length > 1" title="Delete Institution" class="delete-item">
                                        <span
                                            (click)="deleteAffiliation(i); validateNow();">
                                            <i class="fas fa-trash-alt"></i>
                                        </span>
                                    </span>
                                </span>
                                <span valign="top" align="center">
                                    <input type=checkbox
                                        class="mt10"
                                        id="prim{{i}}"
                                        (ngModelChange)="onlyOneAff(i); validateNow();"
                                        [(ngModel)]="form.affiliationList[i].primaryInstitution">
                                    Primary Employer
                                </span>
                            </div>
                            <div>
                                <span colspan=3 style="display:block">
                                    <warning fid="affiliationList"></warning>
                                </span>
                            </div>
                            <div *ngIf="flags.noAffErr" 
                                 class="addAdditional" 
                                 (click)="addAffiliation()" 
                                 id="addEmployer">
                                <span class="fas fa-plus-circle"></span>
                                <span class="font18">Add additional employers</span>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container id="carrer-level-orcid-scopusid">

                        <!---------- Career Level ---------->
                        <h3>Career Level</h3>
                        <div class="form-group floating-label-wrapper">
                            <label for="employment_level"></label>
                            <select
                                    [(ngModel)]="form.employment_level"
                                    class="form-control inBox"
                                    id="employment_level"
                                    placeholder=" "
                                    (blur)="validateNow()"
                                    (change)="validateNow()">
                                <option value="" disabled></option>
                                <option *ngFor="let f of lists.careerLevels" value="{{f.name}}">{{f.name}}</option>
                            </select>
                            <warning fid="employment_level"></warning>
                        </div>
                        <!---------- ORCID ---------->
                        <h3>ORCID</h3>
                        <div class="form-group floating-label-wrapper">
                            <input type="text" class="form-control inBox"  id="orcid" orcid-scopus="'orcid'"
                                placeholder=" "
                                [(ngModel)]="form.orcid"
                                autocomplete="nope"
                                (ngModelChange)="validateDelayed();flags.orcid = true">
                            <label for="orcid" class="inputLbl">Enter your ORCID</label>
                            <warning fid="orcid"></warning>
                        </div>
                        <!---------- SCOPUSID ---------->
                        <h3>SCOPUSID</h3>
                        <div class="form-group floating-label-wrapper">
                            <input type="text" class="form-control inBox"  id="scopusId" orcid-scopus="'scopus'"
                                placeholder=" "
                                [(ngModel)]="form.scopusId"
                                autocomplete="nope"
                                (change)="validateDelayed();flags.orcid = false">
                            <label for="scopusId" class="inputLbl">Enter your SCOPUSID</label>
                            <warning fid="scopusId"></warning>
                        </div>
 
                    </ng-container>

                    <br><br>
                    <button class="btn btn-primary btn-md" 
                        (click)="saveForLater()"
                        ngbTooltip="Save the data that you entered for later while you gather important documents"
                        placement="right"
                        [disabled]="!flags.delta || (flags.level < 5)"
                        id="saveContinueBtn">
                        SAVE and CONTINUE
                    </button>
                    <span *ngIf="flags.saveForLaterSaved">
                        <img width=100px height=2px border=0>
                        <b>
                        Saved for later
                        </b>
                    </span>

                </span>
            </div>
            <div *ngIf="flags.level == 6">
                <a *ngIf="!flags.accountRequestDone" id="cancel" (click)="cancelAccountRequest()" >
                    Cancel account request
                </a>
            </div>
        </div>
        <div class="sub-section">
            <h1 *ngIf="flags.level >= 7">Last step</h1>
            <p *ngIf="flags.level >= 7">
                Select your citizenship status, choose the type of login token
                you'd like, and read and agree with our computer user terms.
            </p>
        </div>
        <div class="card ub3-card" *ngIf="flags.level >= 7">
            <div class="card-body">

                    <h3 id="arCit">Citizenship</h3>
                    <span *ngIf="!flags.favorComplete">
                        <table class="inputCheckbox">
                            <tr>
                                <td>Are you a U.S. citizen?</td>
                                <td width=15></td>
                                <td>
                                    <div><input type="radio"
                                                name="usCitizen"
                                                id="usCitizenYes"
                                                [(ngModel)]="form.usCitizen"
                                                [value]=true 
                                                (change)="validateNow()">
                                    <label for="usCitizenYes">yes</label>
                                    </div>
                                    <div><input type="radio"
                                                name="usCitizen"
                                                id="usCitizenNo"
                                                [(ngModel)]="form.usCitizen"
                                                [value]=false
                                                (change)="validateNow()">
                                    <label for="usCitizenNo">no</label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <span *ngIf="form.usCitizen == false">
                                <br>
                                <button 
                                    (click)="flags.preFavorNotice=true; goToTopOfCitizenArea();"
                                    class="btn btn-primary btn-md"
                                    id="enterForeignNational"
                                    (disabled)="flags.preFavorNotice">
                                    Enter Foreign National Info
                                </button>

                                <div *ngIf="flags.preFavorNotice" class="round-corner">
                                    <docs-needed></docs-needed>
                                    <br><br>
                                    If you don't have the required documents that are listed above in hand and ready to upload, 
                                    then click the 'save and return later' button and resume filling out the form 
                                    when you have your documents with you.
                                    <br>
                                        <button (click)="goToFAVOR(); "
                                                id="visitAccessRequest"
                                                class="btn btn-primary btn-md mr20">
                                            VISIT ACCESS REQUEST FORM
                                        </button>
                                        <button (click)=" flags.preFavorNotice=false; saveForLater();"
                                                class="btn btn-primary btn-md">
                                            SAVE AND RETURN LATER
                                        </button>
                                </div>
                                <span *ngIf="flags.loading">
                                        <br><font color=green><b>
                                        Loading Foreign National Entry Form. Please wait ...</b></font>
                                        <img src="/frontend/loading.gif">
                                        <span id="extUrl" *ngIf="flags.extUrl"><br>{{flags.extUrl}}</span>
                                </span>
                        </span>
                    </span>
                    <span *ngIf="flags.favorComplete" id="fdComplete">
                            <b>Foreign National Documentation Completed</b>
                    </span>

                    <h3 class="cryptoKey">Crypto Key</h3>
                    <table class="inputCheckbox">
                        <tr>
                            <td colspan=3 class="description">
                                We offer two ways to access our systems.
                            </td>
                        </tr>
                        <tr>
                            <td> Which would you prefer?  </td>
                            <td>
                                <div>
                                    <input type="radio" 
                                        name="cryptoKeyOption" 
                                        id="mobile" 
                                        [(ngModel)]="form.cryptoKeyOption" 
                                        value="Mobile" 
                                        (change)="validateNow()">
                                    <label for="mobile">mobile</label> *
                                </div>
                                <div><input type="radio" 
                                            name="cryptoKeyOption" 
                                            id="physicalToken" 
                                            [(ngModel)]="form.cryptoKeyOption" 
                                            value="Physical"
                                            (change)="validateNow()">
                                    <label for="physicalToken">physical token</label>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="form.cryptoKeyOption == 'Mobile'">
                            <td colspan=3 class="description">
                                *
                                <a href="{{ALCFPasscodeTokensURL}}"
                                target="_alcf_mob_token">
                                Requires downloading an app on your phone
                                </a>
                            </td>
                        </tr>
                    </table>
                    <div class="terms">
                        <h3>Argonne Computer User Terms</h3>
                        <policy-agreement-option [form]="form" [onCheckBoxChange]="policyAgreementChanged">
                        </policy-agreement-option>
                    </div>
                    <br>
                    <br>
                    <div class="alert alert-danger" *ngIf="flags.missing" id="err-miss">
                        {{flags.missing}}
                    </div>

                    <div class="alert alert-danger" *ngIf="flags.readError" id="err-read">
                        {{flags.readError}}
                    </div>

                    <div class="alert alert-danger" *ngIf="flags.submitError" id="err-sub">
                        {{flags.submitError}}
                    </div>

                    <table>
                        <tr>
                            <td>
                                <button class="btn btn-success btn-md"
                                        (click)="flags.okToSubmit=false; submit()"
                                        id="requestAnAccountBtn"
                                        [disabled]="!flags.okToSubmit"
                                        ngbTooltip="Submit request for new account"
                                        placement="top"
                                >
                                    REQUEST ACCOUNT
                                </button>

                                <span *ngIf="flags.saving"> Saving ...  </span>
                            </td>
                            <td nowrap>
                                <a *ngIf="!flags.accountRequestDone" id="cancel" (click)="cancelAccountRequest()" >
                                    Cancel account request
                                </a>
                            </td>

                        </tr>
                    </table>

            </div>
        </div>

        <br>

        <span   *ngIf="flags.newAccountRequestResult" 
                ng-bind-html="flags.newAccountRequestResult">
        </span>
        </td></tr>
    </table>

    <ng-container *ngIf="(flags.level == 1) && flags.accountRequestDone">
        <div class="reversed-white">
            <h1>Account {{flags.needNewAccount === true?'Request':'Reactivation'}} Submitted</h1>
        </div>
        <div class="round-corner request-complete">
            <strong *ngIf="flags.needNewAccount !== true">
                Thank you for your request to reactivate your account.</strong>
            <strong *ngIf="flags.needNewAccount === true">
                Thanks for your account submission.</strong>
            <br><br>
            <span>
                Your ALCF account {{flags.needNewAccount === true?'request':'reactivation'}} 
                has been received. We will notify you once 
                we've {{flags.needNewAccount === true?'processed':'obtained approval from 
                the project PI and process'}} your application.</span>
            <br><br>
            If you have any questions or concerns, please contact the accounts team at  
            <a href="mailto:accounts@alcf.anl.gov"> accounts@alcf.anl.gov. </a> 

            <br><br>
            Thanks!
            <div class="sign-out">
                <button class="btn btn-success btn-lg" id="btn"
                        (click)="signOut()">
                    HOME
                </button>
            </div>

        </div>
        <br>
    </ng-container>

</div>

<br>
<br>

