
import { Component, OnInit } from '@angular/core';
import { CommonService } from '@common/services';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import { GlobalConstants } from '@common/constants/global-constants';

var t = null;
var ub3consoleLog = null;
var appFunctions = null;

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
  //styleUrls: ['../../../app.component.scss']
})
export class HomeComponent implements OnInit {

  domainGlobals = null;
  lists = {alcfNews3:[]};
  state = null;
  user = null;
  form = {};
  menuItems = null;
  mobile: null;
  CareersURL = GlobalConstants.CareersURL;
  AllocationRequestURL = GlobalConstants.AllocationRequestURL;
  TrainingEventsURL = GlobalConstants.TrainingEventsURL;
  publicPage(b) { };

  constructor(
        private commonService: CommonService,
        private cookies: CookieService,
        private router: Router
  ) { 
    t = this;
    ub3consoleLog = t.commonService.ub3consoleLog;
    t.domainGlobals = t.commonService.getSharedObject('app', 'domainGlobals');
    t.state         = t.commonService.getSharedObject('app', 'state');
    t.menuItems     = t.commonService.getSharedObject('app', 'menuItems');
    appFunctions    = t.commonService.getSharedObject('app', 'functions');
    t.user = t.state.user;
  }

  ngOnInit(): void {

    var href = window.location.href;
    var p = window.location.hash.replace('#','');
    var x =  {p:p, href: href, state: t.state};
    if (p == '/home'){
        t.state.page = p;
    }

    document.body.classList.add('public-page');
    t.initHomePage();
    appFunctions.setDispFlags();
  }
  continueRequest(){
    var purpose, path;
    var co = t.commonService.getLocalStorage('anl-UB3-LLF');
    var r  = localStorage.getItem('limLoginReason');
    
    if (r && (r != '')){
            purpose = r;
            path = null;
            if (purpose == 'accountReactivate') path = '/accountReactivate';
            if (purpose == 'dataSurvey'       ) path = '/dataSurvey';
            if (path){
                window.location.assign('#'+path);
            } 
            return;
    }
    if (co && (co != '')){
            purpose = co.LLState.purpose;
            path = null;
            if (purpose == 'REACTIVATE') path = '/accountReactivate';
            if (purpose == 'REQUEST'   ) path = '/accountRequest';
            if (purpose == 'DDALLOC_REQUEST') path = '/allocationRequests';
            if (path){
                window.location.assign('#'+path);
            } 
            return;
    }
    window.history.forward();
  }
  cancelRequest(){
    localStorage.removeItem('limLoginReason');
    appFunctions.logout();
  }
  goto(hashVal) {
    window.location.hash = '/'+hashVal;
  }
  onClick(item){
      appFunctions.processClick(item.f);
      appFunctions.isAdmPage(item);
  }
  initHomePage = function(){
    t.commonService.getSetContext(t, 'home', function(){
        t.lists = {};
        t.flags = {};
        t.form = {};
        t.flags.displayedPageName = '';
        t.flags.fieldErrors = {};
        t.commonService.getALCFNews(t.lists);
    });
  };

}
